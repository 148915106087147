import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { withStyles, Box, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { authService } from '../../services/authentication';
import { formatDateHu, changeObjectNullValuesToEmptyString } from '../../utils/Utils';

import { styles } from '../../theme/styles';
// 2021-11-26 pjung TNRUKAYCM-1278 Fejléc logó csere
//import Logo from '../../assets/images/AYCM21.png';
//import Multisport from '../../assets/images/multi_logo1_szurke.png';
import Logo from '../../assets/images/AYCM22.png';
import MobileMenu from './MobileMenu';
import { setCurrentUser } from '../../redux/user/user.actions';

class LoggedInHeader extends Component {
  async componentDidMount() {
    /**
     * A getHeadData szerviz az aktuálisan bejelentkezett user adatait adja vissza,
     * ezt jelenítjük meg a Header-ben
     */
    try {
      const response = await authService.getHeadData();
      if (response.data.status === 'OK') {
        const user = changeObjectNullValuesToEmptyString(response.data);
        this.props.setCurrentUser(user);
        this.props.setHeader(user);
      }

      if (response.data.pack === '3XL') {
        this.props.setIsPackageXXL(true);
      }
    } catch (e) {
      this.props.enqueueSnackbar(this.props.t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
  }

  render() {
    const { classes, t, currentUser, disabled } = this.props;

    return (
      <Grid container justify="center" alignItems="center">
        <Box display={{ xs: 'block', md: 'none' }}>
          <MobileMenu disabled={disabled} currentUser={currentUser} />
        </Box>
        <Grid item xs={12} md={3} className={classes.headerLogoHasUser} style={{ paddingTop: 0 }}>
          <img src={Logo} alt="" style={{ width: '130px', padding: '8px 0 0' }} />
          {/* 2021-11-26 pjung TNRUKAYCM-1278 Fejléc logó csere
          <br /><img src={Multisport} alt="" />*/}
        </Grid>
        <Grid item xs={12} md={9} className={classes.headerUserData}>
          <Grid container justify="center" alignItems="center">
            <Grid item sm={12} className={classes.headerUser}>
              <Typography variant="h5" component="h2">
                {authService.currentUserName()}
              </Typography>
            </Grid>
            <Grid container justify="space-between" alignItems="center" style={{ marginTop: '8px' }}>
              <Grid item xs={12} md="auto" className={classes.headerParams}>
                {t('header.kr')}: <strong style={{ color: 'black' }}>{currentUser.kr}</strong>
              </Grid>
              <Grid item xs={12} md="auto" className={classes.headerParams}>
                {t('header.package')}: <strong style={{ color: 'black' }}>{currentUser.pack}</strong>
              </Grid>
              <Grid item xs={12} md="auto" className={classes.headerParams}>
                {t('header.contractStart')}: <strong style={{ color: 'black' }}>{formatDateHu(currentUser.from)}</strong>
              </Grid>
              <Grid item xs={12} md="auto" className={classes.headerParams}>
                {t('header.contractEnd')}: <strong style={{ color: 'black' }}>{formatDateHu(currentUser.to)}</strong>
              </Grid>
              <Grid item xs={12} md="auto" className={classes.headerParams}>
                {t('header.cardStatus')}:{' '}
                <strong style={{ color: 'black' }}>{currentUser.cardNumber && t(`card.status${currentUser.cardStatus}`)}</strong>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default withTranslation()(withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(withSnackbar(LoggedInHeader)))));
