import React, { useState, useEffect } from 'react';
import { Grid, Button, withStyles, Link, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import InputWrapper from '../components/ui/InputWrapper';
import { styles } from '../theme/styles';
import useValidator from '../utils/useValidator';
import { authService } from '../services/authentication';

const ResetPassword = props => {
  const [state, setState] = useState({
    newPassword: '',
    newPasswordAgain: ''
  });

  const { setErrors, removeErrors, resetErrors, hasErrors, setFields, didFillEverything } = useValidator();

  const { classes } = props;
  const { lang, token } = useParams();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    setFields(['newPassword', 'newPasswordAgain']);
    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const { newPassword } = state;
    if (didFillEverything(state).length === 0) {
      try {
        const response = await authService.resetPassword(token, newPassword);
        if (response.data.status === 'OK') {
          sessionStorage.setItem('currentUser', JSON.stringify(response.data.user));
          sessionStorage.setItem('token', response.data.token);
          history.push('/');
        } else {
          response.data.messages.map(message =>
            enqueueSnackbar(t(message), {
              variant: 'error'
            })
          );
        }
      } catch (error) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
    }
  };

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleBlur = (e, rules, password) => {
    setErrors(e, rules, password);
  };

  const handleFocus = e => {
    removeErrors(e);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <p className={classes.loginHeading} style={{ textAlign: 'center' }}>
            {t('changePassword')}
          </p>
        </Grid>
        <Grid item xs={12} className={classes.registerPageContainer}>
          <form onSubmit={handleSubmit} onError={errors => console.error(errors)}>
            <Grid container style={{ maxWidth: '550px', margin: '0 auto' }}>
              <Grid item xs={12}>
                <InputWrapper inputStyle={hasErrors('newPassword') ? 'Error' : 'Main'} label={t('newPassword')}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={state.newPassword}
                    onChange={handleChange}
                    onBlur={e => handleBlur(e, ['required', 'min5', 'isPasswordsMatch'], state.newPasswordAgain)}
                    onFocus={handleFocus}
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper inputStyle={hasErrors('newPasswordAgain') ? 'Error' : 'Main'} label={t('newPasswordAgain')}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    type="password"
                    id="newPasswordAgain"
                    name="newPasswordAgain"
                    value={state.newPasswordAgain}
                    onChange={handleChange}
                    onBlur={e => handleBlur(e, ['required', 'min5', 'isPasswordsMatch'], state.newPassword)}
                    onFocus={handleFocus}
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center', marginTop: '200px' }}>
                <Grid container justify="center">
                  <Grid item xs={12} sm={4}>
                    <Link href="/">
                      <Button variant="outlined" color="secondary">
                        {t('back')}
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button variant="outlined" color="primary" type="submit">
                      {t('saveButton')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

ResetPassword.propTypes = {
  classes: PropTypes.shape()
};

ResetPassword.defaultProps = {
  classes: {}
};

export default withStyles(styles)(ResetPassword);
