import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';

import { Provider } from 'react-redux';
import { withStyles } from '@material-ui/core';
import DefaultLayout from '../layouts/DefaultLayout';
import {
  API_URL,
  OTP_CALLBACK_URL,
  ONLINE_PAYMENT_CALLBACK_URL,
  TRACKING_ID
} from '../utils/Consts';
import store from '../redux/store';
import { styles } from '../theme/styles';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null
    };
  }

  render() {
    console.log(`VERSION: ${process.env.REACT_APP_VERSION}`);
    console.log(`DEPLOY: ${process.env.REACT_APP_BUILD_TIME}`);
    console.log(`API: ${API_URL}`);
    console.log(`OTP1: ${OTP_CALLBACK_URL}`);
    console.log(`OTP2: ${ONLINE_PAYMENT_CALLBACK_URL}`);

    const { t } = this.props;

    document.title = t('title');

    ReactGA.initialize(`${TRACKING_ID}`);

    return (
      <Provider store={store}>
        <DefaultLayout />
      </Provider>
    );
  }
}
export default withTranslation()(withStyles(styles)(App));
