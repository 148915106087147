import { checkBankAccountCdv } from './Utils';

const required = (value) => !value;

const isIdent = (value) => !(
// eslint-disable-next-line no-restricted-globals
  (!isNaN(value) && (value.length === 6 || value.length === 5))
    || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    || value.length === 0
);

const isEmail = (value) => value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
const isRegKod = (value) => value && !/^[6-9]\d[A-M]{2}[N-Z]{2}[1-5]\d$/.test(value);
const isEmailOrRegKod = (value) => isEmail(value) && isRegKod(value);

const isNumber = (value) => { if (value) return !/[0-9]/g.test(value); };

const min = (value, rule) => {
  const num = rule.replace(/\D/g, '');
  return value.trim().length < num;
};

const max = (value, rule) => {
  const num = rule.replace(/\D/g, '');
  return value.trim().length > num;
};

const isPasswordsMatch = (value, rule, password) => password && value !== password;

const bankAccount = (value) => {
  const result = checkBankAccountCdv(value);
  return result === false;
};

const validatorRules = {
  required,
  isIdent,
  isEmail,
  isRegKod,
  isEmailOrRegKod,
  min,
  max,
  isPasswordsMatch,
  bankAccount,
  isNumber,
};

export default validatorRules;
