import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Warning from '../../assets/images/figyelmezteto_tabla_zold.png';
import CardPackage from '../../components/ui/CardPackage';
import ValidFrom from '../../components/ui/ValidFrom';
import { getYearEnd } from '../../components/utils/Tools';
import { contractService } from '../../services/contracting';
import { styles } from '../../theme/styles';
import useValidator from '../../utils/useValidator';
import ErrorDialog from '../../components/ui/ErrorDialog';

const ContractData = (props) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [dates, setDates] = useState([]);
  const [data, setData] = useState({
    validFrom: '',
    validTo: '',
    cardPackage: '',
    receipt: '',
    addressee: '',
  });

  const [offerDate, setOfferDate] = useState(new Date().getFullYear());

  const [errorDialog, setErrorDialog] = useState({
    icon: 'warning',
    popupDisabled: false,
    dialogOpen: false,
    dialogContent: '',
  });

  const {
    setErrors,
    removeErrors,
    resetErrors,
    setFields,
    didFillEverything,
    giveErrors,
    errorList,
  } = useValidator();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    classes, prevStep, targetStep, currentStep, jumpToStep, resetTargetStep, nextStep,
  } = props;

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: '/step3'});
    const fetchData = async () => {
      if (sessionStorage.contractKey) {
        try {
          const response = await contractService.get(3, sessionStorage.contractKey);
          if (response.data.status === 'OK') {
            const { contract } = response.data;
            const dataCopy = { ...data };
            // dataCopy.validFrom = contract.validFrom ? contract.validFrom : '';
            dataCopy.validTo = contract.validTo ? contract.validTo : '';
            dataCopy.cardPackage = contract.cardPackage
              ? contract.cardPackage
              : '';
            dataCopy.receipt = contract.receipt ? contract.receipt : '';
            dataCopy.addressee = contract.addressee ? contract.addressee : '';

            setData(dataCopy);
          } else {
            response.data.messages.map((message) => enqueueSnackbar(t(message), {
              variant: 'error',
            }));
          }

          const datesResponse = await contractService.getDates(sessionStorage.contractKey);
          if (datesResponse.data.status === 'OK') {
            const { list } = datesResponse.data;
            if (list) {
              setDates([...list]);
              if (list.length < 1) {
                enqueueSnackbar(t('hasValidContract'), {
                  variant: 'error',
                });
              }
            }
          } else {
            response.data.messages.map((message) => enqueueSnackbar(t(message), {
              variant: 'error',
            }));
          }
        } catch (e) {
          enqueueSnackbar(t('TECHNICAL_ERROR'), {
            variant: 'error',
          });
        }
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFields(['validFrom', 'cardPackage']);
    return () => resetErrors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.validFrom) {
      const year = new Date(data.validFrom).getFullYear();
      setOfferDate(year);
    }
  }, [data.validFrom]);

  const handleOnContractChange = (event) => {
    removeErrors(event);
    setData({ ...data, [event.target.name]: event.target.value });
    if (event.target.name === 'newValidFrom') {
      setData({ ...data, validTo: getYearEnd(event.target.value) });
    }
  };

  const closeErrorDialog = () => {
    setErrorDialog({
      icon: 'warning',
      popupDisabled: false,
      dialogOpen: false,
      dialogContent: '',
    });
    resetTargetStep();
  };

  const submitData = async () => {
    const response = await contractService.submit(3,
      {
        key: sessionStorage.contractKey,
        contract: {
          validFrom: data.validFrom,
          validTo: data.validTo,
          cardPackage: data.cardPackage,
          receipt: data.receipt,
        },
      });

    if (response) return response;
  };

  useEffect(() => {
    const submit = async () => {
      try {
        const response = await submitData();
        if (response.data.status === 'OK') {
          jumpToStep();
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    };

    if (targetStep !== 0 && targetStep < currentStep) {
      if (didFillEverything(data).length === 0) {
        submit();
      } else {
        const printDialogContent = (
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={12}>
              <p style={{ textAlign: 'left', fontSize: '14px', fontWeight: '700' }}>{t('changeAccordionWarning')}</p>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right', marginTop: '16px' }}>
              <Button variant="outlined" color="primary" onClick={() => jumpToStep()}>{t('next')}</Button>
              <Button variant="outlined" color="primary" onClick={() => closeErrorDialog()}>{t('button.cancel')}</Button>
              <Grid item xs={12}>
                <Box className={classes.popupBottom} />
              </Grid>
            </Grid>
          </Grid>
        );
        setErrorDialog({
          ...errorDialog,
          dialogOpen: true,
          dialogContent: printDialogContent,
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetStep, currentStep]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (didFillEverything(data).length === 0) {
      try {
        const response = await submitData();
        if (response.data.status === 'OK') {
          nextStep();
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (e) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    } else {
      const errors = didFillEverything(data);
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error',
      });
      errors.map((field) => giveErrors(field, key));
    }
  };

  const getNeededLink = () => {
    switch (offerDate) {
      case 2019:
        return 'https://allyoucanmove.hu/elfogadohelyek#teljeslista';
      case 2020:
        return 'https://allyoucanmove.hu/elfogadohelyek';
      default:
        return 'https://allyoucanmove.hu/elfogadohelyek';
    }
  };

  const handleCheckbox = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
  };

  const handleFocus = (e) => {
    removeErrors(e);
  };


  return (
    <>
      <ErrorDialog
        showError={errorDialog.dialogOpen}
        content={errorDialog.dialogContent}
        handleClose={closeErrorDialog}
        title=""
        icon={errorDialog.icon}
      />
      <form
        onSubmit={handleSubmit}
        onError={(errors) => console.error(errors)}
      >
        <Typography component="section">
          <Grid container component="article">
            <Grid item xs={12}>
              <ValidFrom
                fullWidth
                dates={dates}
                validFrom={data.validFrom}
                handleOnContractChange={handleOnContractChange}
                handleBlur={handleBlur}
                handleFocus={handleFocus}
                errorList={errorList}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                className={classes.warningField}
                style={{ marginBottom: '15px' }}
              >
                <Grid item xs={10} container style={{ paddingLeft: '14px' }}>
                  <Grid item xs={12} style={{ marginTop: '8px', marginLeft: '12px' }}>
                    <Typography variant="subtitle2" component="h3">
                      {t('acceptTerm')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '8px' }}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={termsAccepted}
                          name="termsAccepted"
                          onChange={handleCheckbox}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                )}
                      label={t('paymentSumCheckBox1')}
                      style={{ marginLeft: '8px', marginBottom: '16px' }}
                    />
                  </Grid>
                  {/* 2021-04-26 pjung TNRUKAYCM-1081 Piros szöveg eltávolítása
                  <Grid item xs={12} style={{ marginLeft: '8px', marginBottom: '30px', color:'red', fontWeight: 'bold' }}>
                    {t('january11')}
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm="auto" style={{ textAlign: 'center', marginTop: '20px' }}>
              <img
                src={Warning}
                style={{ width: '75px', height: 'auto' }}
                alt=""
              />
            </Grid>
            <Grid item xs={12} sm={10} style={{ paddingLeft: '20px' }}>
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                className={classes.warningField}
                style={{ marginBottom: '15px' }}
              >
                <Grid item xs={12} sm={10}>
                  <p style={{ fontWeight: 'bold', color: 'rgba(49, 139, 129)' }}>
                    {t('newOffersWarning1')}
                    <br />
                    {t('newOffersWarning2')}
                    {' '}
                    {t('newOffersWarning3')}
                  </p>
                </Grid>
                <Grid item xs={12} sm={2} style={{ textAlign: 'right' }}>
                  <Link
                    href={getNeededLink()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button variant="outlined" color="secondary">
                      {t('readIt')}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CardPackage
              id="cardPackge"
              name="cardPackage"
              cardPackage={data.cardPackage}
              handleOnContractChange={handleOnContractChange}
              handleBlur={handleBlur}
              handleFocus={handleFocus}
              errorList={errorList}
              startDate={data.validFrom}
            />
          </Grid>
        </Typography>
        <Grid container justify="flex-end" style={{ marginTop: '30px' }}>
          <Button variant="outlined" color="secondary" onClick={prevStep}>
            {t('back')}
          </Button>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={!termsAccepted || dates.length < 1}
            style={{ marginLeft: '10px' }}
          >
            {t('next')}
          </Button>
        </Grid>
      </form>
    </>
  );
};

ContractData.propTypes = {
  classes: PropTypes.shape(),
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  targetStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  jumpToStep: PropTypes.func.isRequired,
  resetTargetStep: PropTypes.func.isRequired,
};

ContractData.defaultProps = {
  classes: {},
};

export default withStyles(styles)(ContractData);
