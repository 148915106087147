import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import ReactGA from 'react-ga4';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Address from '../../components/ui/Address';
import InputWrapper from '../../components/ui/InputWrapper';
import InvoiceDir from '../../components/ui/InvoiceDir';
import { contractService } from '../../services/contracting';
import { checkTaxNumber, isBlank } from '../../utils/Utils';
import useValidator from '../../utils/useValidator';
import ErrorDialog from '../../components/ui/ErrorDialog';

const InvoicingData = (props) => {
  const initialAddress = {
    zip: '',
    city: '',
    street: '',
    streetType: '',
    house: '',
    staircase: '',
    floor: '',
    door: '',
    poBox: '',
  };

  const [cityList1, setCityList1] = useState([]);
  const [cityList2, setCityList2] = useState([]);
  const [direction, setDirection] = useState(0);
  const [data, setData] = useState({
    name: '',
    tax: '',
    // 2022-04-27 pjung TNRUKAYCM-1389 Céges számla tiltása
    nocszla: false,
  });
  const [employerData, setEmployerData] = useState({
    employerKey: '',
    employerName: '',
    employerTax: '',
    officeEmail: '',
  });
  const [userAddress, setUserAddress] = useState(initialAddress);
  const [employerAddress, setEmployerAddress] = useState(initialAddress);
  const [errorDialog, setErrorDialog] = useState({
    icon: 'warning',
    popupDisabled: false,
    dialogOpen: false,
    dialogContent: '',
  });

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { classes, nextStep, prevStep, targetStep, currentStep, jumpToStep, resetTargetStep } = props;

  const { setErrors, removeErrors, resetErrors, setFields, didFillEverything, giveErrors, hasErrors, errorList, requiredGotFilled } = useValidator();

  useEffect(() => {
    if (direction === 1) {
      setFields(['name', 'tax', 'zip', 'city', 'street', 'streetType', 'house']);
    } else if (direction === 2) {
      setFields(['name', 'zip', 'city', 'street', 'streetType', 'house']);
    } else if (direction === 0) {
      setFields([]);
    }

    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: '/step5'});
    const fetchData = async () => {
      if (sessionStorage.contractKey) {
        try {
          const response = await contractService.get(5, sessionStorage.contractKey);
          if (response?.data?.status === 'OK') {
            const { invoice, employer } = response.data;
            const { address } = invoice;
            const changeIncomingNullValues = {};
            Object.keys(address).forEach((i) => (changeIncomingNullValues[i] = address[i] === null ? '' : address[i]));
            const addressCopy = { ...userAddress, ...changeIncomingNullValues };
            const dataCopy = {
              name: invoice.name ? invoice.name : '',
              tax: invoice.tax ? invoice.tax : '',
              // 2022-04-27 pjung TNRUKAYCM-1389 Céges számla tiltása
              nocszla: employer.invoice.nocszla || false,
            };
            console.log('data', dataCopy);
            //setData(dataCopy);
            const directionCopy = invoice.direction ? invoice.direction : 0;
            console.log('dir', directionCopy);
            //setDirection(directionCopy);
            console.log('address', addressCopy);
            setUserAddress(addressCopy);
            setCityList1([addressCopy.city]);
            if (employer) {
              const employerDataCopy = {
                employerKey: employer.key ? employer.key : '',
                officeEmail: employer.email ? employer.email : '',
              };
              if (employer.invoice) {
                employerDataCopy.employerName = employer.invoice.name;
                employerDataCopy.employerTax = employer.invoice.tax;
                if (employer.invoice.address?.zip) {
                  setCityList2([address.city]);
                  setEmployerAddress(address);
                }
              }
              setEmployerData(employerDataCopy);
            }
            // 2022-04-27 pjung TNRUKAYCM-1389 Céges számla tiltása miatti helyesbítés
            setData(dataCopy);
            setDirection(directionCopy);
          } else {
            response?.data?.messages &&
              response.data.messages.map((message) =>
                enqueueSnackbar(t(message), {
                  variant: 'error',
                }),
              );
          }
        } catch (e) {
          console.error(e);
          enqueueSnackbar(t('TECHNICAL_ERROR'), {
            variant: 'error',
          });
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userAddress.city) {
      const request = {
        target: {
          name: 'city',
          value: userAddress.city,
        },
      };
      removeErrors(request);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddress.city]);

  const listCitiesByZip = async (zip) => {
    let result = '';
    try {
      const response = await contractService.listCitiesByZip(zip);
      if (response?.data?.status === 'OK') {
        result = response.data.list.map((city) => city.name);
      } else {
        response?.data?.messages &&
          response.data.messages.map((message) =>
            enqueueSnackbar(t(message), {
              variant: 'error',
            }),
          );
      }
    } catch (e) {
      enqueueSnackbar(t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
    return result;
  };

  const handleOnAddressChange = async (event, address) => {
    requiredGotFilled(event);
    const { name, value } = event.target;
    if (address === 'invoice') {
      const addressCopy = JSON.parse(JSON.stringify(userAddress));
      addressCopy[name] = value;
      if (name === 'zip' && value.length === 4) {
        const cityListCopy = await listCitiesByZip(value);
        addressCopy.city = addressCopy.city ? addressCopy.city : cityListCopy[0];
        setCityList1(cityListCopy);
      } else if (name === 'zip' && value.length < 4) {
        addressCopy.city = '';
        setCityList1([]);
      }

      setUserAddress(addressCopy);
    }
  };

  const closeErrorDialog = () => {
    setErrorDialog({
      icon: 'warning',
      popupDisabled: false,
      dialogOpen: false,
      dialogContent: '',
    });
    resetTargetStep();
  };

  const getFieldsToCheck = () => {
    let toCheck = {};
    if (direction === 1) {
      toCheck = {
        ...data,
        ...userAddress,
      };
    } else if (direction === 2) {
      toCheck = {
        name: data.name,
        ...userAddress,
      };
    }

    return toCheck;
  };

  const submitData = async () => {
    const response = await contractService.submit(5, {
      key: sessionStorage.contractKey,
      invoice: {
        direction, // 1=cég, szervezet, 2=magánszemély, 3=munkáltatóm, 0=saját
        name: data.name,
        tax: data.tax,
        address: {
          zip: userAddress.zip,
          city: userAddress.city,
          street: userAddress.street,
          streetType: userAddress.streetType,
          house: userAddress.house,
          floor: userAddress.floor,
          staircase: userAddress.staircase,
          door: userAddress.door,
        },
      },
    });

    if (response) return response;
  };

  useEffect(() => {
    const submit = async () => {
      try {
        const response = await submitData();
        if (response.data.status === 'OK') {
          jumpToStep();
        } else {
          response.data.messages.map((message) =>
            enqueueSnackbar(t(message), {
              variant: 'error',
            }),
          );
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    };

    if (targetStep !== 0 && targetStep < currentStep) {
      if (didFillEverything(data).length === 0) {
        submit();
      } else {
        const printDialogContent = (
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={12}>
              <p style={{ textAlign: 'left', fontSize: '14px', fontWeight: '700' }}>{t('changeAccordionWarning')}</p>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right', marginTop: '16px' }}>
              <Button variant="outlined" color="primary" onClick={() => jumpToStep()}>
                {t('next')}
              </Button>
              <Button variant="outlined" color="primary" onClick={() => closeErrorDialog()}>
                {t('button.cancel')}
              </Button>
              <Grid item xs={12}>
                <Box className={classes.popupBottom} />
              </Grid>
            </Grid>
          </Grid>
        );
        setErrorDialog({
          ...errorDialog,
          dialogOpen: true,
          dialogContent: printDialogContent,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetStep, currentStep]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const toCheck = getFieldsToCheck();
    if (didFillEverything(toCheck).length === 0) {
      try {
        const response = await submitData();
        if (response.data.status === 'OK') {
          nextStep();
        } else {
          response.data.messages.map((message) =>
            enqueueSnackbar(t(message), {
              variant: 'error',
            }),
          );
        }
      } catch (e) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    } else {
      const errors = didFillEverything(toCheck);
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error',
      });
      errors.map((field) => giveErrors(field, key));
    }
  };

  const handleCheckbox = (event) => {
    if (event.target.checked) {
      setDirection(0);
      setData({
        name: '',
        tax: '',
        // 2022-04-27 pjung TNRUKAYCM-1389 Céges számla tiltása
        nocszla: data.nocszla,
      });
      setUserAddress(initialAddress);
    } else {
      const addressCopy = { ...employerAddress, city: cityList2[0] };
      setDirection(3);
      setData({
        name: employerData.employerName,
        tax: employerData.employerTax,
        // 2022-04-27 pjung TNRUKAYCM-1389 Céges számla tiltása
        nocszla: data.nocszla,
      });
      setUserAddress(addressCopy);
    }
  };

  const handleOnInvoiceChange = (event) => {
    const { name, value } = event.target;
    // A számlázási irány módosításakor a cím adatok üresre állítódnak.
    if (name === 'direction') {
      console.log('handleOnInvoiceChange', 'dir', value);
      setDirection(value);
      if (value === 3) {
        setData({
          name: employerData.employerName,
          tax: employerData.employerTax,
          // 2022-04-27 pjung TNRUKAYCM-1389 Céges számla tiltása
          nocszla: data.nocszla,
        });
        setUserAddress(employerAddress);
      } else {
        setData({
          name: '',
          tax: '',
          // 2022-04-27 pjung TNRUKAYCM-1389 Céges számla tiltása
          nocszla: data.nocszla,
        });
        setUserAddress(initialAddress);
      }
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleBlur = (e, rules) => {
    if (e.target.value && e.target.name === 'tax') {
      const result = checkTaxNumber(e.target.value);
      if (result === false) {
        const key = enqueueSnackbar(t('validate_taxNum'), {
          variant: 'error',
        });
        giveErrors('tax', key);
      }
    } else {
      setErrors(e, rules);
    }
  };

  const handleFocus = (e) => {
    removeErrors(e);
  };

  return (
    <>
      <ErrorDialog
        showError={errorDialog.dialogOpen}
        content={errorDialog.dialogContent}
        handleClose={closeErrorDialog}
        title=""
        icon={errorDialog.icon}
      />
      <form onSubmit={handleSubmit} onError={(errors) => console.error(errors)}>
        <Typography component="section">
          <Grid container component="article">
            <Grid item xs={12} md={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={direction === 0}
                    onChange={handleCheckbox}
                    // value={ownAddress}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                }
                label={t('invoiceCB')}
                style={{ marginBottom: '16px' }}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Box ms="auto" display={direction !== 0 ? 'block' : 'none'}>
                <InvoiceDir
                  employer={!isBlank(employerData?.employerKey) && !isBlank(employerAddress?.zip)}
                  company={!data.nocszla}
                  fullWidth
                  direction={direction}
                  handleOnInvoiceChange={handleOnInvoiceChange}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box ms="auto" display={direction > 0 ? 'block' : 'none'}>
                <InputWrapper inputStyle={hasErrors('name') ? 'Error' : 'Main'} label={t('invoiceName')}>
                  <TextField
                    fullWidth
                    disabled={direction === 3}
                    id="name"
                    name="name"
                    variant="outlined"
                    margin="dense"
                    value={data.name}
                    onChange={handleOnInvoiceChange}
                    onBlur={(e) => handleBlur(e, ['required'])}
                    onFocus={handleFocus}
                  />
                </InputWrapper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box ms="auto" display={direction === 1 || direction === 3 ? 'block' : 'none'}>
                <InputWrapper inputStyle={hasErrors('tax') ? 'Error' : 'Main'} label={t('taxNumber')}>
                  <InputMask
                    mask="99999999-9-99"
                    disabled={direction === 3}
                    maskChar={null}
                    value={data.tax}
                    onChange={handleOnInvoiceChange}
                    onBlur={(e) => handleBlur(e, ['required', 'isTaxValid'])}
                    onFocus={handleFocus}
                    name="tax">
                    {() => (
                      <TextField
                        fullWidth
                        disabled={direction === 3}
                        id="tax"
                        variant="outlined"
                        margin="dense"
                        name="tax"
                        type="text"
                        validators={direction === 1 || direction === 3 ? ['required', 'isTaxNumValid'] : []}
                      />
                    )}
                  </InputMask>
                </InputWrapper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box ms="auto" display={direction > 0 ? 'block' : 'none'}>
                <Address
                  disabled={direction === 3}
                  required={direction === 1 || direction === 2}
                  addressType="invoice"
                  address={userAddress}
                  handleOnAddressChange={handleOnAddressChange}
                  namePrefix=""
                  cityList={direction === 3 ? cityList2 : cityList1}
                  errorList={errorList}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                />
              </Box>
            </Grid>
          </Grid>
        </Typography>
        <Grid container justify="flex-end" style={{ marginTop: '30px' }}>
          <Button variant="outlined" color="secondary" onClick={prevStep}>
            {t('back')}
          </Button>
          <Button type="submit" variant="outlined" color="primary" style={{ marginLeft: '10px' }}>
            {t('next')}
          </Button>
        </Grid>
      </form>
    </>
  );
};

InvoicingData.propTypes = {
  classes: PropTypes.shape(),
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  targetStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  jumpToStep: PropTypes.func.isRequired,
  resetTargetStep: PropTypes.func.isRequired,
};

InvoicingData.defaultProps = {
  classes: {},
};

export default InvoicingData;
