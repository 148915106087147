import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import OtpCallBack from '../components/OtpCallBack';
import Header from '../components/ui/Header';
import Menu from '../components/ui/Menu';
import ContractingPage from './ContractingPage';
import PaymentPage from './PaymentPage';
import { styles } from '../theme/styles';
import ChPass from './ChPass';
import ContractingInitPage from './ContractingInitPage';
import ContractingInitUpgradePage from './ContractingInitUpgradePage';
import ContractingInitUpgradeMultiPage from './ContractingInitUpgradeMultiPage';
import ContractingInitUpgradeTarsPage from './ContractingInitUpgradeTarsPage';
import ContractingSumPage from './ContractingSumPage';
import DocumentsPage from './DocumentsPage';
import InvoicesPage from './InvoicesPage';
import AppDataPage from './AppDataPage';
import ProfileDataPage from './ProfileDataPage';
import RegularCreditPage from './RegularCreditPage';
import DirectDebitPage from './DirectDebitPage';
import { contractService } from '../services/contracting';
import InvoiceDataPage from './InvoiceDataPage';
import ContractOptions from './ContractOptions';
import ErrorDialog from '../components/ui/ErrorDialog';

class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      userHasCoCard: false,
      isPackageXXL: false,
      header: {},
    };
  }

  async componentDidMount() {
    try {
      const response = await contractService.initUpgrade();
      if (response.data.messages[0] === 'CONTR_CO_CARD') {
        this.setState({
          userHasCoCard: true,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  setIsPackageXXL = (value) => {
    this.setState({
      isPackageXXL: value,
    });
  };

  setHeader = (value) => {
    this.setState({
      ...this.state,
      header: value,
    });
  };

  render() {
    const { classes, t } = this.props;
    const checkdate = () => {
      const ma = new Date();
      return ma > new Date('2023-06-17T00:45:00+02:00') && ma < new Date('2023-06-17T01:45:00+02:00');
    };

    return (
      <Grid container spacing={2} alignItems="center" justify="center">

        <Grid item xs={12} style={{ position: 'relative', padding: '4px' }}>
          <Header disabled={this.state.userHasCoCard} setIsPackageXXL={this.setIsPackageXXL} setHeader={this.setHeader} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={1} md={3} className={classes.menu}>
              <Menu disabled={this.state.userHasCoCard} currentUser={this.state.header} />
            </Grid>
            <Grid item xs={12} md style={{ backgroundColor: 'white', borderRadius: '5px' }}>
              <Box className={classes.loggedInMainBox}>
                <ErrorDialog
                  showError={checkdate() && sessionStorage.getItem('szep') !== 'true'}
                  icon="warning"
                  disabled={false}
                  content={(
                    <>
                      <Typography
                        variant="subtitle1"
                        component="h6"
                        style={{
                          color: 'black', fontWeight: '700', fontSize: '14px', marginTop: '1rem',
                        }}
                      >
                        {t('msg.szep.1')}
                      </Typography>
                      <Typography component="p" style={{ textAlign: 'right', margin: '1rem' }}>
                        {t('msg.szep.2')}
                      </Typography>
                    </>
                  )}
                  handleClose={() => { sessionStorage.setItem('szep', 'true'); this.setState({ ...this.state }); }}
                />
                <Switch>
                  <Route path="/payment/callback/:transaction?" component={OtpCallBack} />
                  <Route path="/payment/regularcredit" component={RegularCreditPage} />
                  <Route path="/payment/directdebit" component={DirectDebitPage} />
                  <Route path="/payment/:transaction?" component={PaymentPage} />
                  <Route path="/profile/data/:success?" component={ProfileDataPage} />
                  <Route path="/profile/chpass" component={ChPass} />

                  <Route path="/contracting/init" component={ContractingInitPage} />
                  <Route
                    path="/contracting/upgrade"
                    render={(props) => <ContractingInitUpgradePage {...props} isPackageXXL={this.state.isPackageXXL} />}
                  />
                  <Route
                    path="/contracting/upgrade_multi"
                    render={(props) => <ContractingInitUpgradeMultiPage {...props} />}
                  />
                  <Route
                    path="/contracting/upgrade_tars/:kr/:officeEmail"
                    render={(props) => <ContractingInitUpgradeTarsPage {...props} />}
                  />
                  <Route path="/contracting/sum/:uuid?/:status?" component={ContractingSumPage} />
                  <Route path="/contracting/options/:token" component={ContractOptions} />
                  <Route path="/contracting" component={ContractingPage} />
                  <Route path="/invoices" component={InvoicesPage} />
                  <Route path="/documents" component={DocumentsPage} />
                  <Route path="/invoicedata" component={InvoiceDataPage} />
                  <Route path="/app" component={AppDataPage} />
                </Switch>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(withStyles(styles)(HomePage));
