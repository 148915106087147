import DateFnsUtils from '@date-io/date-fns';
import {
  Checkbox, FormControlLabel, Link, TextField, withStyles,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import enLocale from 'date-fns/locale/en-US';
import huLocale from 'date-fns/locale/hu';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import ReactGA from 'react-ga4';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Warning from '../../assets/images/figyelmezteto_tabla_zold.png';
import Address from '../../components/ui/Address';
import InputWrapper from '../../components/ui/InputWrapper';
import { authService } from '../../services/authentication';
import { contractService } from '../../services/contracting';
import { styles } from '../../theme/styles';
import {
  checkBankAccountCdv, isBlank, isObjectNotEmpty, formatDate, isMonthLater,
} from '../../utils/Utils';
import useValidator from '../../utils/useValidator';
import ErrorDialog from '../../components/ui/ErrorDialog';

const localeMap = {
  en: enLocale,
  hu: huLocale,
};

// 6. Page

const LedgerData = (props) => {
  const [bankOwner, setBankOwner] = useState(true);
  const [contract, setContract] = useState({
    validFrom: '',
    validTo: '',
    cardPackage: '',
    receipt: '',
  });
  const [data, setData] = useState({
    currentUserName: authService.currentUserName(),
    type: '',
    docInfo: '',
    feeSum: 0,
    addressee: '',
    method: '',
    transferFrom: null,
    startDate: null,
  });
  const U = data.type === 'U' || isMonthLater(contract.validFrom, 9);

  const [contrAddress, setContrAddress] = useState({
    zip: '',
    city: '',
    street: '',
    streetType: '',
    house: '',
    staircase: '',
    floor: '',
    door: '',
  });
  const [cityList, setCityList] = useState([]);
  const [fees, setFees] = useState({
    hdij: 0,
    dij1: 0,
    dij2: 0,
    dij3: 0,
    dij4: 0,
    fdij: 0,
    fdij1: 0,
    fdij2: 0,
    fdij3: 0,
    fdij4: 0,
    fhonap: 0,
    fhdijo: 0,
    fosszeg: 0,
  });
  const [bank, setBank] = useState({
    account: '',
    name: '',
    owner: authService.currentUserName(),
    birthPlace: '',
    birthDate: null,
    mothersName: '',
  });
  const [bankAddress, setBankAddress] = useState({
    zip: '',
    city: '',
    street: '',
    streetType: '',
    house: '',
    staircase: '',
    floor: '',
    door: '',
  });
  const [cityListBank, setCityListBank] = useState([]);
  const [payment, setPayment] = useState({
    key: 0,
    reg: 0,
    start: '',
    amount: 0,
    transaction: '',
    method: '',
  });

  const [errorDialog, setErrorDialog] = useState({
    icon: 'warning',
    popupDisabled: false,
    dialogOpen: false,
    dialogContent: '',
  });

  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    classes, nextStep, prevStep, targetStep, currentStep, jumpToStep, resetTargetStep,
  } = props;
  const {
    setFields, resetErrors, giveErrors, errorList, didFillEverything, setErrors, removeErrors, hasErrors,
  } = useValidator();

  /**
   * A backend oldalról a visszatérő adatok között ha valami nem létezik, attól még state-ben jelen kell lennie,
   * így egyesével megnézzük a kapott értékeket, és ha nincs, akkor üres stringre állítjuk.
   */

  const responseToLedgerData = (response) => {
    const dataCopy = { ...data };
    const contractCopy = { ...contract };
    let contrAddressCopy = { ...contrAddress };
    let cityListCopy = cityList;
    let feesCopy = { ...fees };
    const bankCopy = { ...bank };
    let bankAddressCopy = { ...bankAddress };
    let cityListBankCopy = cityListBank;
    const paymentCopy = { ...payment };

    dataCopy.type = response.type || '';
    dataCopy.docInfo = response.docInfo || '';
    dataCopy.transferFrom = response.transferFrom ?? response.contract?.validFrom;
    if (response.contract) {
      dataCopy.startDate = response.contract.validFrom || '';
      contractCopy.validFrom = response.contract.validFrom || '';
      contractCopy.validTo = response.contract.validTo || '';
      contractCopy.cardPackage = response.contract.cardPackage || '';
      contractCopy.receipt = response.contract.receipt || '';
      dataCopy.addressee = response.contract.addressee || '';

      const { address } = contract;
      if (address) {
        contrAddressCopy = { ...contrAddress, ...address };
        cityListCopy = [address.city];
      }
    }

    if (response.ledger) {
      dataCopy.method = response.ledger.method ? response.ledger.method : '4';
      const { feesResp, bankResp, paymentResp } = response.ledger;
      if (feesResp) {
        feesCopy = { ...fees, ...feesResp };
      }
      if (bankResp) {
        bankCopy.account = bank.account || '';
        bankCopy.name = bank.name || '';
        bankCopy.owner = bank.owner || '';
        bankCopy.birthPlace = bank.birthPlace || '';
        bankCopy.birthDate = bank.birthDate || null;
        bankCopy.mothersName = bank.mothersName || '';

        if (bank.address) {
          bankAddressCopy = { ...bankAddress, ...bank.address };
          cityListBankCopy = [bank.address.city];
        }
      }

      if (paymentResp) {
        paymentCopy.key = payment.key || 0; // payment
        paymentCopy.reg = payment.reg || 0;
        paymentCopy.start = payment.start || 0;
        paymentCopy.amount = payment.amount || 0;
        paymentCopy.method = payment.method || '';
        paymentCopy.transaction = payment.transaction || '';
      }
    }

    // A számlafizető megegyezik a szerződő féllel ha a következő adatok üresek:
    //  "bank": {"birthPlace": "", "birthDate": "", "mothersName": "", "address": {}}
    const empty = isBlank(bank.birthPlace) && isBlank(bank.birthDate) && isBlank(bank.mothersName) && !isObjectNotEmpty(bankAddress);
    bankCopy.owner = empty ? authService.currentUserName() : '';
    return {
      dataCopy,
      contractCopy,
      contrAddressCopy,
      cityListCopy,
      cityListBankCopy,
      feesCopy,
      bankCopy,
      bankAddressCopy,
      paymentCopy,
    };
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: '/step6'});
    const fetchData = async () => {
      if (sessionStorage.contractKey) {
        try {
          const response = await contractService.get(6, sessionStorage.contractKey);
          if (response.data.status === 'OK') {
            const {
              dataCopy, contractCopy, contrAddressCopy, cityListCopy, cityListBankCopy, feesCopy, bankCopy, bankAddressCopy, paymentCopy,
            } = responseToLedgerData(response.data);

            setData(dataCopy);
            setContract(contractCopy);
            setContrAddress(contrAddressCopy);
            setCityList(cityListCopy);
            setCityListBank(cityListBankCopy);
            setFees(feesCopy);
            setBank(bankCopy);
            setBankAddress(bankAddressCopy);
            setPayment(paymentCopy);
          } else {
            response.data.messages.map((message) => enqueueSnackbar(t(message), {
              variant: 'error',
            }));
          }
        } catch (e) {
          console.error(e);
          enqueueSnackbar(t('TECHNICAL_ERROR'), {
            variant: 'error',
          });
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMonthLater(contract.validFrom, 9) && data.type !== 'U') {
      setData({
        ...data,
        method: '4',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract.validFrom, data.type]);

  useEffect(() => {
    if (data.method === '3' && !bankOwner) {
      setFields(['bankName', 'owner', 'birthDate', 'mothersName', 'account', 'zip', 'city', 'street', 'streetType', 'house']);
    } else if (data.method === '3' && bankOwner) {
      setFields(['bankName', 'account']);
    } else {
      setFields([]);
    }

    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.method, bankOwner]);

  const handleBlur = (e, rules) => {
    if (e.target.name === 'account') {
      const result = checkBankAccountCdv(e.target.value);
      if (result === false) {
        const key = enqueueSnackbar(t('validate_bankAccount'), {
          variant: 'error',
        });
        giveErrors('account', key);
      }
    }

    setErrors(e, rules);
  };

  const handleFocus = (e) => {
    if (e.target.name === 'account') {
      removeErrors(e);
      const event = {
        target: {
          name: 'bankName',
        },
      };
      removeErrors(event);
    }
    removeErrors(e);
  };

  const getBankNameByAccount = async (accountNumber) => {
    let result = '';
    try {
      const response = await contractService.getBankNameByAccount(accountNumber);
      if (response.data.status === 'OK') {
        result = response.data.name;
      } else {
        response.data.messages.map((message) => enqueueSnackbar(t(message), {
          variant: 'error',
        }));
      }
    } catch (e) {
      enqueueSnackbar(t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
    return result;
  };

  const listCitiesByZip = async (zip) => {
    let result = '';
    try {
      const response = await contractService.listCitiesByZip(zip);
      if (response.data.status === 'OK') {
        if (response.data.list.length > 0) {
          result = response.data.list.map((city) => city.name);
        }
      } else {
        response.data.messages.map((message) => enqueueSnackbar(t(message), {
          variant: 'error',
        }));
      }
    } catch (e) {
      enqueueSnackbar(t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
    return result;
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    const bankCopy = { ...bank };

    if (name === 'method') {
      setData({ ...data, [name]: value });
    } else {
      bankCopy[name] = value;
    }

    /**
     * Ha a bankszámla első 3 száma beütésre került, akkor meghívunk egy
     * szervízt ami egye adatbázisból visszaadja a számlavezető bank nevét.
     */

    if (name === 'account') {
      bankCopy.account = value.replace(' ', '').replace('-', '');
    }
    if (name === 'account' && value !== null && value.split('-')[0].replace(/\s/g, '').length > 2 && value.slice(0, 3) !== bank.account.slice(0, 3)) {
      bankCopy.name = await getBankNameByAccount(value);
    } else if (name === 'account' && value.split('-')[0].replace(/\s/g, '').length < 3) {
      bankCopy.name = '';
    }
    setBank({ ...bank, ...bankCopy });
  };

  const handleDateChange = (date) => {
    setBank({ ...bank, birthDate: date });
  };

  const handleBankOwnerChange = () => {
    const nextBankOwnerState = !bankOwner;
    setBankOwner(nextBankOwnerState);
    if (nextBankOwnerState === true) {
      setBank({
        ...bank,
        owner: authService.currentUserName(),
        birthPlace: '',
        birthDate: null,
        mothersName: '',
      });
      setBankAddress({
        zip: '',
        city: '',
        street: '',
        streetType: '',
        house: '',
        staircase: '',
        floor: '',
        door: '',
      });
    } else {
      setBank({ ...bank, owner: '' });
    }
  };

  const handleBankAddressChange = async (event, address) => {
    if (address === 'bank') {
      const bankAddressCopy = JSON.parse(JSON.stringify(bankAddress));
      bankAddressCopy[event.target.name] = event.target.value;

      if (event.target.name === 'zip' && event.target.value.length === 4) {
        const cityListCopy = await listCitiesByZip(event.target.value);
        // eslint-disable-next-line prefer-destructuring
        bankAddressCopy.city = cityListCopy[0];
        setCityListBank(cityListCopy);
      }
      setBankAddress(bankAddressCopy);
    }
  };

  const handleTransferFromChange = (date) => {
    setData({ ...data, transferFrom: date });
  };

  const getFieldsToCheck = () => {
    let toCheck = {};
    if (data.method === '3' && bankOwner) {
      toCheck = {
        bankName: bank.name,
        owner: bank.owner,
      };
    }
    if (data.method === '3' && !bankOwner) {
      toCheck = {
        account: bank.account,
        bankName: bank.name,
        owner: bank.owner,
        mothersName: bank.mothersName,
        birthDate: bank.birthDate,
        zip: bankAddress.zip,
        city: bankAddress.city,
        street: bankAddress.street,
        streetType: bankAddress.streetType,
        house: bankAddress.house,
      };
    }
    return toCheck;
  };

  const submitData = async () => {
    const response = await contractService.submit(6, {
      key: sessionStorage.contractKey,
      contract: {
        ...contract,
        address: { ...contrAddress },
      },
      ledger: {
        fees: { ...fees },
        method: data.method,
        bank: {
          ...bank,
          address: bankOwner ? null : { ...bankAddress },
        },
        payment: { ...payment },
      },
      transferFrom: formatDate(data.transferFrom),
    });

    if (response) return response;
  };

  const closeErrorDialog = () => {
    setErrorDialog({
      icon: 'warning',
      popupDisabled: false,
      dialogOpen: false,
      dialogContent: '',
    });
    resetTargetStep();
  };

  useEffect(() => {
    const submit = async () => {
      try {
        const response = await submitData();
        if (response.data.status === 'OK') {
          jumpToStep();
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    };

    if (targetStep !== 0 && targetStep < currentStep) {
      const toCheck = getFieldsToCheck();
      if (didFillEverything(toCheck).length === 0) {
        submit();
      } else {
        const printDialogContent = (
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={12}>
              <p
                style={{
                  textAlign: 'left',
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              >
                {t('changeAccordionWarning')}
              </p>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right', marginTop: '16px' }}>
              <Button variant="outlined" color="primary" onClick={() => jumpToStep()}>
                {t('next')}
              </Button>
              <Button variant="outlined" color="primary" onClick={() => closeErrorDialog()}>
                {t('button.cancel')}
              </Button>
              <Grid item xs={12}>
                <Box className={classes.popupBottom} />
              </Grid>
            </Grid>
          </Grid>
        );
        setErrorDialog({
          ...errorDialog,
          dialogOpen: true,
          dialogContent: printDialogContent,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetStep, currentStep]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Ha nem rendszeres bankkártya, akkor OK vagy ha rendszeres, és accept
    const toCheck = getFieldsToCheck();
    if (didFillEverything(toCheck).length === 0) {
      try {
        const response = await submitData();
        if (response.data.status === 'OK') {
          nextStep();
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    } else {
      const errors = didFillEverything(toCheck);
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error',
      });
      errors.map((field) => giveErrors(field, key));
    }
  };

  return (
    <>
      <ErrorDialog
        showError={errorDialog.dialogOpen}
        content={errorDialog.dialogContent}
        handleClose={closeErrorDialog}
        title=""
        icon={errorDialog.icon}
      />
      <form align="middle" onSubmit={handleSubmit} onError={(errors) => console.error(errors)}>
        <Typography component="section">
          <Grid container component="article">
            <Grid item xs={12} stlye={{ marginRight: '8px' }}>
              <FormControl fullWidth>
                {U && !['4', '3', '31'].find((q) => q === data.method) ? (
                  <InputWrapper inputStyle="Main" label={t('choose.method.next')} />
                ) : (
                  <InputWrapper inputStyle="Main" label={t('paymentType')}>
                    <Select
                      id="method"
                      name="method"
                      variant="outlined"
                      margin="dense"
                      value={data.method}
                      onChange={handleInputChange}
                      style={{ textAlign: 'left' }}
                      disabled={data.type === 'U' || isMonthLater(contract.validFrom, 8)}
                    >
                      <MenuItem value="4" key={4}>
                        {t('paymentYearByCard')}
                      </MenuItem>
                      <MenuItem value="3" key={5}>
                        {t('paymentMonthlyFaciliated')}
                      </MenuItem>
                      <MenuItem value="31" key={31}>
                        {t('paymentMonthly')}
                      </MenuItem>
                    </Select>
                  </InputWrapper>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginLeft: '8px' }}>
              <Box style={{ textAlign: 'left' }} display={data.method === '3' ? 'block' : 'none'}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={bankOwner}
                      onChange={handleBankOwnerChange}
                      value={bankOwner}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                  )}
                  label={t('label.bankOwner')}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={data.method?.startsWith('3') ? 'block' : 'none'}>
                <Grid container className={classes.warningField} alignItems="center" justify="flex-start">
                  <Grid item xs={12} sm="auto" className="warningSign">
                    <img src={Warning} alt="!" />
                  </Grid>
                  <Grid item xs={12} sm className="warningText">
                    <p>
                      {data.method === '3' ? (
                        <>
                          <strong>{t('label.paymentFaciliated1')}</strong>
                          <br />
                          {t('label.paymentFaciliated2-1')}
                          <strong>{t('label.paymentFaciliated2-2')}</strong>
                          {t('label.paymentFaciliated2-3')}
                        </>
                      ) : (
                        <strong>{t('label.paymentFaciliated3')}</strong>
                      )}
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={data.method === '3' ? 'block' : 'none'}>
                <Box display={bankOwner ? 'block' : 'none'}>
                  <Grid container justify="center">
                    <Grid item xs={12}>
                      <InputWrapper inputStyle={hasErrors('account') ? 'Error' : 'Main'} label={t('label.bankAccountNumber')}>
                        <InputMask
                          mask="99999999-99999999-99999999"
                          maskChar=""
                          value={bank.account}
                          onChange={handleInputChange}
                          onBlur={(e) => handleBlur(e, ['bankAccount'])}
                          onFocus={handleFocus}
                          name="account"
                        >
                          {() => <TextField fullWidth variant="outlined" margin="dense" id="account" name="account" type="text" />}
                        </InputMask>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper inputStyle={hasErrors('bankName') ? 'Error' : 'Main'} label={t('label.bankName')}>
                        <TextField
                          fullWidth
                          disabled
                          id="bankName"
                          name="bankName"
                          variant="outlined"
                          margin="dense"
                          value={bank.name}
                          onBlur={(e) => handleBlur(e, ['required'])}
                          onFocus={handleFocus}
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper inputStyle={hasErrors('owner') ? 'Error' : 'Main'} label={t('label.bankAccountOwner')}>
                        <TextField
                          fullWidth
                          disabled
                          id="owner"
                          name="owner"
                          variant="outlined"
                          margin="dense"
                          value={bank.owner}
                          onBlur={(e) => handleBlur(e, ['required', 'bankAccount'])}
                          onFocus={handleFocus}
                        />
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Box>
                <Box display={!bankOwner ? 'block' : 'none'}>
                  <Grid container justify="center">
                    <Grid item xs={12}>
                      <InputWrapper inputStyle={hasErrors('account') ? 'Error' : 'Main'} label={t('label.bankAccountNumber')}>
                        <InputMask
                          mask="99999999-99999999-99999999"
                          maskChar=""
                          value={bank.account}
                          onChange={handleInputChange}
                          onBlur={(e) => handleBlur(e, ['bankAccount'])}
                          onFocus={handleFocus}
                          name="account"
                        >
                          {() => <TextField fullWidth variant="outlined" margin="dense" id="account" name="account" type="text" />}
                        </InputMask>
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper inputStyle={hasErrors('bankName') ? 'Error' : 'Main'} label={t('label.bankName')}>
                        <TextField
                          fullWidth
                          disabled
                          id="bankName"
                          name="bankName"
                          variant="outlined"
                          margin="dense"
                          value={bank.name}
                          onBlur={(e) => handleBlur(e, ['required'])}
                          onFocus={handleFocus}
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper inputStyle={hasErrors('owner') ? 'Error' : 'Main'} label={t('label.bankAccountOwner')}>
                        <TextField
                          fullWidth
                          id="owner"
                          name="owner"
                          variant="outlined"
                          margin="dense"
                          value={bank.owner}
                          onChange={handleInputChange}
                          onBlur={(e) => handleBlur(e, ['required'])}
                          onFocus={handleFocus}
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6} style={{ marginRight: '8px' }}>
                      <InputWrapper inputStyle={hasErrors('birthPlace') ? 'Error' : 'Main'} label={t('birthPlace')}>
                        <TextField
                          fullWidth
                          id="birthPlace"
                          variant="outlined"
                          name="birthPlace"
                          margin="dense"
                          value={bank.birthPlace}
                          onChange={handleInputChange}
                          onBlur={(e) => handleBlur(e, ['required'])}
                          onFocus={handleFocus}
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
                        <InputWrapper inputStyle={hasErrors('birthDate') ? 'Error' : 'Main'} label={t('birthDate')}>
                          <DatePicker
                            autoOk
                            fullWidth
                            inputVariant="outlined"
                            disableFuture
                            id="birthDate"
                            name="birthDate"
                            margin="dense"
                            value={bank.birthDate}
                            initialFocusedDate={new Date('1980-01-01')}
                            format="yyyy.MM.dd"
                            onChange={handleDateChange}
                            views={['year', 'month', 'date']}
                            openTo="year"
                            variant="inline"
                            invalidDateMessage={t('validate_date')}
                            onBlur={(e) => handleBlur(e, ['required'])}
                            onFocus={handleFocus}
                          />
                        </InputWrapper>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <InputWrapper inputStyle={hasErrors('mothersName') ? 'Error' : 'Main'} label={t('mothersName')}>
                        <TextField
                          fullWidth
                          id="mothersName"
                          name="mothersName"
                          variant="outlined"
                          margin="dense"
                          value={bank.mothersName}
                          onChange={handleInputChange}
                          onBlur={(e) => handleBlur(e, ['required'])}
                          onFocus={handleFocus}
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontWeight: '700',
                          textAlign: 'left',
                          marginBottom: '16px',
                          marginTop: '32px',
                        }}
                      >
                        {t('label.bankAccountOwnerAddress')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Address
                        required={bankOwner === false}
                        addressType="bank"
                        address={bankAddress}
                        handleOnAddressChange={handleBankAddressChange}
                        namePrefix=""
                        cityList={cityListBank}
                        errorList={errorList}
                        handleBlur={handleBlur}
                        handleFocus={handleFocus}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box display={data.method === '31' ? 'block' : 'none'}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
                      <InputWrapper inputStyle={hasErrors('transferFrom') ? 'Error' : 'Main'} label={t('label.monthlyCardAccept')}>
                        <DatePicker
                          autoOk
                          fullWidth
                          disablePast
                          inputVariant="outlined"
                          id="transferFrom"
                          name="transferFrom"
                          minDate={new Date(contract.validFrom)}
                          minDateMessage={t('validate_min_date')}
                          value={data.transferFrom}
                          initialFocusedDate={contract.validFrom}
                          format="yyyy.MM.dd"
                          onChange={handleTransferFromChange}
                          views={['year', 'month', 'date']}
                          openTo="year"
                          invalidDateMessage={t('validate_date')}
                          variant="inline"
                          onBlur={(e) => handleBlur(e, ['required'])}
                          onFocus={handleFocus}
                        />
                      </InputWrapper>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.downloadWrapper}>
                    <Grid container justify="space-between">
                      <Grid item xs={12} sm style={{ textAlign: 'center' }}>
                        <p className={classes.downloadWrapperText} style={{ color: 'black' }}>
                          {t('label.monthlyCardInfo1')}
                          <span style={{ fontWeight: '400' }}>{t('label.monthlyCardInfo2')}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://allyoucanmove.hu/content_files/rendszeres_bankkartya_tajekoztato.pdf"
                          underline="none"
                        >
                          <Button>{t('download')}</Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Typography>
        <Grid container justify="flex-end" style={{ marginTop: '30px' }}>
          <Button variant="outlined" color="secondary" onClick={prevStep}>
            {t('back')}
          </Button>
          <Button type="submit" variant="outlined" color="primary" style={{ marginLeft: '10px' }}>
            {t('next')}
          </Button>
        </Grid>
      </form>
    </>
  );
};

LedgerData.propTypes = {
  classes: PropTypes.shape(),
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  targetStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  jumpToStep: PropTypes.func.isRequired,
  resetTargetStep: PropTypes.func.isRequired,
};

LedgerData.defaultProps = {
  classes: {},
};

//
export default withStyles(styles)(LedgerData);
