import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Grid, Button, Box, TextField, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { authService } from '../services/authentication';
import { styles } from '../theme/styles';
import { setCurrentUser } from '../redux/user/user.actions';

import InputWrapper from '../components/ui/InputWrapper';
import useValidator from '../utils/useValidator';
import { changeObjectNullValuesToEmptyString } from '../utils/Utils';

const LoginPage = ({ app, ...props }) => {
  console.log('app', app);

  const [state, setState] = useState({ loginName: '', password: '' });

  const { setErrors, removeErrors, resetErrors, hasErrors, setFields, didFillEverything, giveErrors } = useValidator();

  const { code, lang } = useParams();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const { t, i18n } = useTranslation();

  const { classes } = props;

  useEffect(() => {
    const activate = sessionStorage.getItem('activate');
    if (activate === 'success') {
      enqueueSnackbar(t('activateSuccess'), {
        variant: 'success'
      });
    }
    sessionStorage.removeItem('activate');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (code !== undefined) {
      if (lang) i18n.changeLanguage(lang);
      if (code.length > 10) {
        const remoteLogin = async () => {
          try {
            const response = await authService.loginAs(code);
            if (response.data.status === 'OK') {
              sessionStorage.setItem('currentUser', JSON.stringify(response.data.user));
              sessionStorage.setItem('token', response.data.token);

              const res = await authService.getHeadData();
              if (res.data.status === 'OK') {
                const user = changeObjectNullValuesToEmptyString(res.data);
                props.setCurrentUser(user);
              }
              history.push(app ? '/app' : '/');
            } else {
              response.data.messages.map(message =>
                enqueueSnackbar(t(message), {
                  variant: 'error'
                })
              );
            }
          } catch (e) {
            enqueueSnackbar(t('TECHNICAL_ERROR'), {
              variant: 'error'
            });
          }
        };

        remoteLogin();
      } else {
        setState({
          loginName: code,
          password: ''
        });
        const passwordField = document.getElementById('password');
        if (passwordField) passwordField.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFields(['loginName', 'password']);
    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async event => {
    event.preventDefault();
    if (didFillEverything(state).length === 0) {
      try {
        const response = await authService.login(state.loginName, state.password);

        if (response.data.status === 'OK') {
          sessionStorage.setItem('currentUser', JSON.stringify(response.data.user));
          sessionStorage.setItem('token', response.data.token);
          if (response.data.user.kr) {
            history.push('/payment');
          } else {
            history.push('/contracting/init');
          }
        } else {
          response.data.messages.map(message =>
            enqueueSnackbar(t(message), {
              variant: message === 'ACC_INACTIVE_USER' ? 'success' : 'error'
            })
          );
        }
      } catch (e) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
    } else {
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error'
        // persist: true,
      });
      didFillEverything(state).map(input => giveErrors(input, key));
    }
  };

  const handleOnChange = event => {
    setState({
      ...state,
      [[event.target.name]]: event.target.value
    });
  };

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
  };

  const handleFocus = e => {
    removeErrors(e);
  };

  if (sessionStorage.token) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Grid container direction="row" alignItems="center" justify="space-between">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <p className={classes.loginHeading}>{t('loginTitle')}</p>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit} style={{ maxWidth: '550px', margin: '0 auto' }}>
            <InputWrapper inputStyle={hasErrors('loginName') ? 'Error' : 'Main'}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                id="loginName"
                name="loginName"
                placeholder={t('loginId')}
                value={state.loginName}
                onChange={handleOnChange}
                InputProps={{
                  style: { fontWeight: '400' }
                }}
                onBlur={e => handleBlur(e, ['required', 'isIdent'])}
                onFocus={handleFocus}
              />
            </InputWrapper>
            <InputWrapper inputStyle={hasErrors('password') ? 'Error' : 'Main'}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                type="password"
                id="password"
                name="password"
                placeholder={t('password')}
                value={state.password}
                onChange={handleOnChange}
                validators={['required', 'matchRegexp:^.{5,}$']}
                InputProps={{
                  style: { fontWeight: '400' }
                }}
                onBlur={e => handleBlur(e, ['required'])}
                onFocus={handleFocus}
              />
            </InputWrapper>
            <span
              style={{
                color: 'rgba(0,191,243,1)',
                fontSize: '14px',
                paddingLeft: '16px',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
              onClick={() => history.push('/forgottendetails')}
              onKeyPress={() => history.push('/forgottendetails')}
              role="link"
              tabIndex={0}>
              {t('forgottenPassword')}
            </span>
            <Box className={classes.loginButton} style={{ textAlign: 'center' }}>
              <Button variant="outlined" color="primary" type="submit">
                {t('loginSubmit')}
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <p className={classes.registerHeading}>{t('welcome')}</p>
        </Grid>
        <Grid className={classes.registerButton} container justify="center">
          <Button variant="outlined" color="secondary" type="button" onClick={() => history.push('/register')}>
            {t('registerSubmit')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

LoginPage.propTypes = {
  classes: PropTypes.shape(),
  setCurrentUser: PropTypes.func.isRequired
};

LoginPage.defaultProps = {
  classes: {}
};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser
});

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginPage));

export const q = props => LoginPage({ app: true, ...props });

export const LoginAppPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(q));
