import React from 'react';
import { Grid } from '@material-ui/core';
// 2022-04-01 pjung TNRUKAY-1381 SimplePay logó csere
import SimplePay from '../../assets/images/paymentlogos/uj_logok.png';
import OTP from '../../assets/images/paymentlogos/Otp_bank_Logo.svg.png';
// 2021-11-29 pjung TNRUKAYCM-1281 OTP Cafeteria logó
//import Cafeteria from '../../assets/images/paymentlogos/OTP Cafeteria matrica.jpg';

// import VisaPos from '../../assets/images/paymentlogos/visa_pos_fc.png';
// import VisaElectron from '../../assets/images/paymentlogos/1200px-Visa_Electron_svg.png';
// import VbyVisa from '../../assets/images/paymentlogos/vbyvisa_blu.png';
// import MasterCard from '../../assets/images/paymentlogos/mc_vrt_opt_pos_73_3x.png';
// import Maestro from '../../assets/images/paymentlogos/ms_vrt_opt_pos_73_3x.png';
// import MasterCardId from '../../assets/images/paymentlogos/mc_idcheck_vrt_pos.png';

const PaymentLogos = () => {
  const logos = [SimplePay, OTP];

  return (
    // 2022-04-01 pjung TNRUKAY-1381 Balra igazítás
    <Grid container alignItems="flex-start">
      {logos.map((logo, id) => (
        <Grid item xs="auto" key={id}>
          <img src={logo} alt="payment method" style={{ height: '20px', width: 'auto', marginRight: '12px' }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default PaymentLogos;
