import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

const AppDataPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container justify="center" alignItems="center" style={{ marginTop: '10rem', textAlign: 'center' }}>
        <Grid
          item
          style={{ fontSize: '24px', fontWeight: 'bold', color: 'rgb(49, 139, 129)' }}
        >
          {t('info.title')}
        </Grid>
        <Grid
          item
          style={{ fontSize: '18px', color: 'black', marginTop: '1rem' }}
        >
          {t('info.text')}
        </Grid>
      </Grid>
    </>
  );
};

export default AppDataPage;
