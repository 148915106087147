import axios from 'axios';

// https://aycmrefaktdev.parola.hu:1446/api/common-services/api/document/v1/list
const baseUrl = `${
  process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:8080'
}/common-services/api/document/v1`;
const newsletterUrl = `${
  process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:8080'
}/cportal-user-account-services/api/newsletter/v1`;

const config = {
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
};

export const commonService = {
  documentList,
  unsubscribe
};

function addJWT() {
  config.withCredentials = true;
  config.headers.Authorization = `Bearer ${sessionStorage.token}`;
}

async function documentList(kr, year) {
  addJWT();
  return axios.post(
    '/list',
    {
      kr,
      year
    },
    config
  );
}

async function unsubscribe(request) {
  return axios.post(`${newsletterUrl}/unsubscribe`, request);
}
