import React from 'react';
import { Box, Grid, withStyles } from '@material-ui/core';
import { formatDateHu, priceMask } from '../../utils/Utils';
import { styles } from '../../theme/styles';
import { withTranslation } from 'react-i18next';

const DirectDebitHistory = (props) => {
  const { classes, list, t } = props;

  const history = list.map((item, idx) => {
    const pattern = /[a-zíáéíóöőüűú]/gi

    return ( 
      <Box className={classes.downloadWrapper} style={{border: 'none'}} key={idx}>        
        <Grid container justify='space-between' style={{fontSize: '13px', color: '#A1A1A1', fontWeight: '700'}}>
          <Grid item xs={5} style={{ textAlign: 'left', paddingLeft: '24px' }}>
            <p style={{fontWeight: '400'}}>
              {formatDateHu(item.date)}
            </p>
          </Grid>
          <Grid item xs={3}>
            <p style={{color: 'black', paddingLeft: '14px'}}>{`${priceMask(item.amount)} ${t('msg.forint')}`}</p>
          </Grid>
          <Grid item xs={4}>
            <p  style={{ color: 'black', textAlign: 'right', paddingRight: '24px' }}>{item.state.match(pattern)}</p>
          </Grid>
        </Grid>
      </Box>
    );
  })  
  return history
   
}
 
export default withTranslation()(withStyles(styles)(DirectDebitHistory));