import React from 'react';
import { Box, Button, withStyles } from '@material-ui/core';

import { styles } from '../../theme/styles';
import Provider, { Context } from '../../providers/Provider';

const LanguageToggler = props => {
  const { classes } = props;

  return (
    <Box display='inline' className={classes.languageTogglerWrap}>
      <Provider>
        <Context.Consumer>
          {context => {
            const isLanguageActive = lang => {
              return context.language === lang
                ? classes.languageTogglerButtonActive
                : classes.languageTogglerButtonNotActive;
            };

            return (
              <>
                <Button
                  disableRipple
                  className={isLanguageActive('hu')}
                  onClick={e => context.changeLanguageToHu()}
                  style={{
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0'
                  }}
                >
                  HUN
                </Button>
                <Button
                  disableRipple
                  className={isLanguageActive('en')}
                  onClick={e => context.changeLanguageToEn()}
                  style={{
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0'
                  }}
                >
                  ENG
                </Button>
              </>
            );
          }}
        </Context.Consumer>
      </Provider>
    </Box>
  );
};

export default withStyles(styles)(LanguageToggler);
