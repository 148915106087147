import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';

import { Container, MuiThemeProvider, withStyles } from '@material-ui/core';
import { PrivateRoute } from '../router/PrivateRouter';
import { theme } from '../theme/theme';
import { styles } from '../theme/styles';

import LoginPage, { LoginAppPage } from '../pages/LoginPage';
import HomePage from '../pages/HomePage';
import RegisterPage from '../pages/RegisterPage';
import ActivatePage from '../pages/ActivatePage';
import ForgottenDetails from '../pages/ForgottenDetails';
import ResetPassword from '../pages/ResetPassword';
import InvoiceFromEmail from '../pages/InvoiceFromEmail';
import ContractActivate from '../components/ContractActivate';
import LanguageToggler from '../components/ui/LanguageToggler';
import LayoutWrapper from './LayoutWrapper';

import { authService } from '../services/authentication';
import Unsubscribe from '../pages/Unsubscribe';
import Subscribe from '../pages/Subscribe';
import ContractingIntraSumPage from '../pages/ContractingSumIntraPage';
import ValidateEmailPage from '../pages/ValidateEmailPage';

const DefaultLayout = (props) => {
  const { classes } = props;

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            variantSuccess: classes.snackSuccess,
            variantError: classes.snackError,
          }}
          preventDuplicate
        >
          <CssBaseline />
          <Container className={classes.root}>
            <BrowserRouter>
              <LayoutWrapper isUserLoggedIn={authService.isUserLoggedIn}>
                <LanguageToggler />
                <Switch>
                  <Route path="/register" component={RegisterPage} />
                  <Route path="/login/app/:code/:lang?" component={LoginAppPage} />
                  <Route path="/login/:code?/:lang?" component={LoginPage} />
                  <Route path="/subscribe/:status/:lang?" component={Subscribe} />
                  <Route path="/activate/:lang/:code?" component={ActivatePage} />
                  <Route path="/contract/:lang/:token" component={ContractActivate} />
                  <Route path="/contracting/intra/:uuid/:status/:fromdate?/:todate?" component={ContractingIntraSumPage} />
                  <Route
                    path="/forgottendetails"
                    component={ForgottenDetails}
                  />
                  <Route path="/password/:lang/:token" component={ResetPassword} />
                  <Route
                    path="/invoice/:invoice/:token/:lang"
                    component={InvoiceFromEmail}
                  />
                  <Route path="/unsubscribe" component={Unsubscribe} />
                  <Route path="/validate-email/:lang/:token" component={ValidateEmailPage} />
                  <PrivateRoute path="/" component={HomePage} />
                </Switch>
              </LayoutWrapper>
            </BrowserRouter>
          </Container>
        </SnackbarProvider>
      </MuiThemeProvider>
    </>
  );
};

export default withStyles(styles)(DefaultLayout);
