import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import { contractPublicService } from '../services/contracting';
import Warning from '../assets/images/figyelmezteto_tabla.png';
import LoaderIcon from './ui/LoaderIcon';
import { styles } from '../theme/styles';
// import CssBaseline from '@material-ui/core/CssBaseline';

class ContractActivate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessages: [],
      key: 0, //    Number	Szerződéskötés azonosítója	REG_KEY
      type: '', //	String	Szerződéskötés típusa	R, H, (h) vagy U
      step: 0, //	Number	Melyik lépésnél járunk
      user: {},
    };
  }

  async componentDidMount() {
    const { i18n } = this.props;
    const {
      match: { params },
    } = this.props;
    if (params.lang) {
      i18n.changeLanguage(params.lang);
    }
    if (params.token !== undefined) {
      // Az email tokennel meghívjuk a confirm szervizt
      try {
        const response = await contractPublicService.confirm(params.token);

        if (response.data.status === 'OK') {
          const { user, token, key, type, step } = response.data;
          // currentUsert és JWT tokent beállítjuk a sessionStorage-ben -> user beléptetve
          sessionStorage.setItem('currentUser', JSON.stringify(user));
          sessionStorage.setItem('token', token);
          if (type === 'U') {
            sessionStorage.setItem('type', 1);
          } else {
            sessionStorage.setItem('type', 0);
          }

          // HA van contractKey, type, és step (TNR-es szerz.) azokat is beállítjuk sessionStoragebe
          if (key && step && type) {
            sessionStorage.setItem('contractKey', key);
            sessionStorage.setItem('type', type);
            sessionStorage.setItem('step', step);
          }

          // HA van step, és step === 1 -> friss szerződés, megmutatjuk a csomagokat
          // VAGY HA nincs step -> intrás, megmutatjuk a csomagokat
          // HA van step, és step > 1 -> félbehagyott szerződés, onnan folytatjuk ahol abbahagyta a user
          // HA step === 8 -> fizetés utáni, utolsó oldalra irányítjuk, endTransactiont beállítjuk
          if ((step && step === 1) || !step) {
            this.props.history.push(`/contracting/options/${params.token}`);
          } else if (step && step === 8) {
            sessionStorage.setItem('endTransaction', true);
            this.props.history.push('/contracting/sum');
          } else if (step && step > 1) {
            this.props.history.push('/contracting');
          }
        } else {
          this.setState({
            error: true,
            errorMessages: response.data.messages,
          });
        }
      } catch (e) {
        this.props.enqueueSnackbar(this.props.t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    } else {
      this.props.enqueueSnackbar(this.props.t('INVALID_TOKEN'), {
        variant: 'error',
      });
    }
  }

  render() {
    const { t, classes } = this.props;

    return (
      <>
        {this.state.error ? (
          <Grid
            container
            className={classes.warningField}
            alignItems="center"
            justify="center"
            style={{
              maxWidth: '650px',
              marginLeft: 'auto',
              marginRight: 'auto',
              height: '65%',
            }}>
            <Grid item xs={12} sm="auto" className="warningSign">
              <img src={Warning} alt="" />
            </Grid>
            <Grid item xs={12} sm className="warningText">
              <p
                style={{
                  textTransform: 'uppercase',
                  fontWeight: '700',
                  fontSize: '14px',
                }}>
                {t('tokenInvalid')}
              </p>
            </Grid>
          </Grid>
        ) : (
          <Container component="main" style={{ height: '100%' }}>
            <Grid container justify="center" alignItems="center" style={{ height: '75%' }}>
              <Grid item xs={1}>
                <LoaderIcon />
              </Grid>
            </Grid>
          </Container>
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withSnackbar(ContractActivate)));
