import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import LoaderIcon from '../components/ui/LoaderIcon';
import Warning from '../assets/images/figyelmezteto_tabla_zold.png';
import { authService } from '../services/authentication';
import { styles } from '../theme/styles';

// import Typography from '@material-ui/core/Typography';

const ActivateForm = (props) => {
  const [error, setError] = useState(false);
  const [, setErrorMessages] = useState([]);

  const location = useLocation();
  const history = useHistory();
  const { lang, code } = useParams();

  const { t, i18n } = useTranslation();

  const { classes } = props;

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
    const fetch = async () => {
      try {
        const response = await authService.activate(code);
        if (response.data.status === 'OK') {
          // TODO: Ezt Szabi kérte, hogy kommentezzük ki
          // Automatikusan belépteti
          // sessionStorage.setItem('currentUser', JSON.stringify(response.data.user));
          // sessionStorage.setItem('token', response.data.token)
          sessionStorage.setItem('activate', 'success');
          history.push('/');
        } else {
          setErrorMessages(response.data.messages);
          setError(true);
        }
      } catch (e) {
        setErrorMessages([t('TECHNICAL_ERROR')]);
        setError(true);
      }
    };

    if (code !== undefined) {
      fetch();
    } else {
      setErrorMessages([t('INVALID_TOKEN')]);
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, location.pathname]);

  return (
    <>
      {error ? (
        <>
          <Grid
            container
            className={classes.warningField}
            alignItems="center"
            justify="center"
            style={{
              maxWidth: '650px',
              marginLeft: 'auto',
              marginRight: 'auto',
              height: '65%',
            }}>
            <Grid item xs={12} sm="auto" className="warningSign">
              <img src={Warning} alt="" />
            </Grid>
            <Grid item xs={12} sm className="warningText">
              <p
                style={{
                  textTransform: 'uppercase',
                  fontWeight: '700',
                  fontSize: '14px',
                }}>
                {t('tokenInvalid')}
              </p>
            </Grid>
          </Grid>
        </>
      ) : (
        <Container component="main" maxWidth="xs">
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid item xs={12}>
              <LoaderIcon />
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

ActivateForm.propTypes = {
  classes: PropTypes.shape(),
};

ActivateForm.defaultProps = {
  classes: {},
};

export default withStyles(styles)(ActivateForm);
