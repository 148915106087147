import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './en.json';
import translationHU from './hu.json';

// the translations
const resources = {
  hu: {
    translation: translationHU
  },
  en: {
    translation: translationEN
  }
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'hu',
  fallbackLng: 'hu', // use hu if detected lng is not available

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safe from xss
  }
});

export default i18n;
