import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { contractService } from '../../services/contracting';
import InputWrapper from './InputWrapper';
import { priceMask } from '../../utils/Utils';

const CardPackage = (props) => {
  const [list, setList] = useState([]);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    id, name, cardPackage, handleOnContractChange, handleBlur, handleFocus, errorList, startDate,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await contractService.getPackages(
          sessionStorage.contractKey,
          startDate,
        );
        if (response.data.status === 'OK') {
          /**
           * A csomagokat áruk szerint növekvő sorrendbe rendezzük
           */
          const orderedList = response.data.list.sort((a, b) => (Number(a.key) > Number(b.key) ? 1 : -1));
          setList(orderedList);
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
          enqueueSnackbar(t('TECHNICAL_ERROR'), {
            variant: 'error',
          });
        }
      } catch (e) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  return (
    <FormControl fullWidth>
      <InputWrapper
        inputStyle={errorList && errorList.cardPackage ? 'Error' : 'Main'}
        label={t('cardPackage')}
      >
        <Select
          id={id}
          name={name}
          variant="outlined"
          margin="dense"
          value={cardPackage}
          onChange={handleOnContractChange}
          onBlur={(e) => handleBlur(e, ['required'])}
          onFocus={handleFocus}
        >
          {list.map((card) => (
            <MenuItem value={card.name} key={card.key}>
              {`${card.name} - ${priceMask(card.key)} ${t('pricePerMonth')}`}
            </MenuItem>
          ))}
        </Select>
      </InputWrapper>
    </FormControl>
  );
};

CardPackage.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cardPackage: PropTypes.string.isRequired,
  handleOnContractChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  errorList: PropTypes.shape().isRequired,
  startDate: PropTypes.string.isRequired,
};

export default CardPackage;
