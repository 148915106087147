import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ChangeEmail from '../components/ui/ChangeEmail';
import ChangePhone from '../components/ui/ChangePhone';
import ChangeAddress from '../components/ui/ChangeAddress';
import { authService } from '../services/authentication';

const ProfileDataPage = () => {
  const [state, setState] = useState({
    email: '',
    phone: '',
    address: {},
  });

  const { success } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    if (success && success === 'success') {
      enqueueSnackbar(t('emailChanged'), {
        variant: 'success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    setState({
      ...state,
      email: user.email,
    });

    const fetchData = async () => {
      const response = await authService.getProfile();

      if (response) {
        setState({
          ...state,
          phone: response.data.phone,
          address: {
            ...response.data.address,
          },
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container justify="center" style={{ padding: '24px 32px 16px' }}>
        <ChangeEmail currentEmail={state.email} />
        {state.phone ? <ChangePhone currentPhone={state.phone} /> : null}
        {Object.values(state.address).length ? (
          <ChangeAddress currentAddress={state.address} />
        ) : null}
      </Grid>
    </>
  );
};

export default ProfileDataPage;
