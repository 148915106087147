import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import LoaderIcon from './LoaderIcon';
import { withStyles } from '@material-ui/core';
import { styles } from '../../theme/styles';
import SuccessIcon from '../../assets/images/haromszog_pipa_zold.png';
import WarningIcon from '../../assets/images/figyelmezteto_tabla_zold.png';
// import { useTranslation } from 'react-i18next';

const ErrorDialog = (props) => {
  // const { t } = useTranslation()

  const iconType = () => {
    switch (props.icon) {
      case 'loading':
        return <LoaderIcon style={{ paddingTop: '20px' }} />;
      case 'success':
        return <img src={SuccessIcon} style={{ width: '65px', height: 'auto', paddingTop: '20px' }} alt=""></img>;
      case 'warning':
        return <img src={WarningIcon} style={{ width: '65px', height: 'auto', paddingTop: '20px' }} alt=""></img>;
      default:
        return null;
    }
  };

  return (
    <Dialog onClose={props.handleClose} open={props.showError} disableBackdropClick disableEscapeKeyDown maxWidth={props.size ?? 'sm'}>
      <DialogActions justify="space-between">
        {iconType()}
        <IconButton aria-label="close" onClick={props.handleClose} disabled={props.disabled}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogContent className={props.classes['popup' + (props.size ?? '').toUpperCase()]}>{props.content}</DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(ErrorDialog);
