import React, { Component } from 'react';
import { paymentService } from '../services/payment';
import { isBlank } from '../utils/Utils';
import { withSnackbar } from 'notistack';
import LoaderIcon from './ui/LoaderIcon';
import { withTranslation } from 'react-i18next';

class OtpCallBack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessages: [],
      key: 0, //    Number	Szerződéskötés azonosítója	REG_KEY
      type: '', //	String	Szerződéskötés típusa	R, H, (h) vagy U
      step: 0, //	Number	Melyik lépésnél járunk
      user: {}
    };
  }

  async componentDidMount() {
    const {
      match: { params }
    } = this.props;

    if (params.transaction === sessionStorage.transaction) {
      try {
        const response = await paymentService.endTransaction(
          params.transaction
        );

        if (response.data.status === 'OK') {
          sessionStorage.setItem('otpres', JSON.stringify(response.data));
          sessionStorage.removeItem('transaction');
          if (isBlank(response.data.auth)) {
            // Sikertelen fizetés
            sessionStorage.setItem('step', 7);
            this.props.history.push('/contracting');
          } else {
            // Sikeres fizetés
            this.props.history.push('/contracting/sum');
          }
        } else {
          response.data.messages.map(message => {
            return this.props.enqueueSnackbar(this.props.t(message), {
              variant: 'error'
            })
          })
        }
      } catch (e) {
        this.props.enqueueSnackbar(this.props.t('TECHNICAL_ERROR'), {
          variant: 'error'
        })
      }
    } else {
      this.props.enqueueSnackbar(this.props.t('noTransaction'), {
        variant: 'error'
      })
    }
  }

  render() {
    //const { t, classes } = this.props;
    //TODO Ide kell valamit írni
    return <div style={{marginLeft: '30px'}}><LoaderIcon /></div>;
  }
}

export default withTranslation()(withSnackbar(OtpCallBack));
