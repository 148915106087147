import { createMuiTheme, createStyles } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgba(49, 139, 129, 1)',
    },
    secondary: {
      main: '#000',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
  overrides: {
    MuiOutlinedInput: createStyles({
      root: {
        width: '100%',
        height: '100%',
        border: 'none',
        backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: '5px',
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          border: 'none',
        },
      },
      notchedOutline: {
        border: 'none',
      },
    }),
    MuiInputBase: createStyles({
      root: {
        fontSize: '13px',
        fontWeight: '700',
      },
    }),
    MuiTextField: createStyles({
      root: {
        margin: '0',
        height: '40px',
        fontWeight: '700',
      },
    }),
    MuiFormHelperText: createStyles({
      root: {
        position: 'absolute',
        bottom: '-16px',
        zIndex: '100',
        width: '150%',
      },
    }),
    MuiButton: createStyles({
      root: {
        textTransform: 'capitalize',
        fontSize: '14px',
        width: '140px',
      },
      label: {
        fontWeight: '700',
      },
      outlinedPrimary: {
        '&$disabled': {
          border: '2px solid rgba(161, 161, 161, 1)',
        },
        disabled: {},
        border: '2px solid rgba(49, 139, 129, 1)',
        color: 'black',
        '&:hover': {
          border: '2px solid #000',
          backgroundColor: 'rgba(0,0,0,0)',
          textDecoration: 'underline',
          color: 'rgba(0, 0, 0, 1)',
        },
      },
      outlinedSecondary: {
        '&$disabled': {
          border: '2px solid rgba(161, 161, 161, 1)',
        },
        disabled: {},
        border: '2px solid',
        '&:hover': {
          border: '2px solid rgba(49, 139, 129, 1)',
          backgroundColor: 'rgba(0,0,0,0)',
          textDecoration: 'underline',
        },
      },
      contained: {
        boxShadow: 'none',
      },
    }),
    MuiIconButton: createStyles({
      root: {
        padding: '0',
      },
    }),
    PrivateSwitchBase: createStyles({
      root: {
        padding: '0',
        marginRight: '16px',
      },
    }),
    MuiStepLabel: createStyles({
      root: {
        fontWeight: '700',
        fontSize: '16px',
      },
      label: {
        fontWeight: '700',
        '&$active': {
          fontWeight: '700',
        },
        '&$completed': {
          fontWeight: '700',
        },
      },
    }),
    MuiStepIcon: createStyles({
      text: {
        fill: 'white',
      },
    }),
    MuiStepContent: createStyles({
      root: {
        paddingTop: '20px',

      },
    }),
    MuiTypography: createStyles({
      body2: {
        fontSize: '14px',
      },
      body1: {
        fontSize: '13px',
      },
    }),
    MuiListItemText: createStyles({
      primary: {
        fontSize: '14px',
      },
    }),
    MuiListItemIcon: createStyles({
      root: {
        color: 'black',
        minWidth: '0',
        marginLeft: '-4px',
        marginRight: '4px',
      },
    }),
    MuiFormControlLabel: createStyles({
      root: {
        marginTop: '4px',
        marginRight: '0',
      },
    }),
    MuiExpansionPanelSummary: createStyles({
      root: {
        '&$expanded': {
          '& .MuiTypography-body1': {
            fontWeight: '700',
          },
        },
      },
      content: {
        marginLeft: '8px',
        '& .MuiTypography-body1': {
          fontSize: '14px',
        },
        '&$expanded': {
          marginLeft: '8px',
        },
      },
      expandIcon: {
        position: 'absolute',
        left: '0px',
      },
    }),
    MuiExpansionPanelDetails: createStyles({
      root: {
        padding: '16px 32px',
      },
    }),
    MuiPaper: createStyles({
      elevation1: {
        boxShadow: 'none',
      },
    }),
    MuiPickersToolbarText: createStyles({
      toolbarTxt: {
        fontSize: '24px',
      },
    }),
    MuiDialogActions: createStyles({
      root: {
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingLeft: '24px',
      },
    }),
    MuiDialogContent: createStyles({
      root: {
        padding: '0px 24px',
      },
    }),
    MuiSelect: createStyles({
      selectMenu: {
        '&#paymentMonths': {
          whiteSpace: 'normal',
        },
      },
    }),
  },
});
