import {
  Button, Checkbox, FormControlLabel, Grid, withStyles, Typography, TextField, Box,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import ReactGA from 'react-ga4';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Warning from '../../assets/images/figyelmezteto_tabla_zold.png';
import Address from '../../components/ui/Address';
import { contractService } from '../../services/contracting';
import { styles } from '../../theme/styles';
import { isObjectNotEmpty, removeAddressPrefix } from '../../utils/Utils';
import InputWrapper from '../../components/ui/InputWrapper';
import useValidator from '../../utils/useValidator';
import ErrorDialog from '../../components/ui/ErrorDialog';
// import { authService } from '../../services/authentication';

const AddressData = (props) => {
  const user = JSON.parse(sessionStorage.getItem('currentUser'));
  const [state, setState] = useState({
    direction: 1,
    tab: 0,
    type: '',
    mail: user.email,
    tel: '',
  });

  const [homeAddress, setHomeAddress] = useState({
    zip: '',
    city: '',
    street: '',
    streetType: '',
    house: '',
    staircase: '',
    floor: '',
    door: '',
    poBox: '',
  });

  const [mailAddress, setMailAddress] = useState({
    zip: '',
    city: '',
    street: '',
    streetType: '',
    house: '',
    staircase: '',
    floor: '',
    door: '',
    poBox: '',
  });

  const [equalAddress, setEqualAddress] = useState(true);
  const [cityList1, setCityList1] = useState([]);
  const [cityList2, setCityList2] = useState([]);

  const [errorDialog, setErrorDialog] = useState({
    icon: 'warning',
    popupDisabled: false,
    dialogOpen: false,
    dialogContent: '',
  });

  const {
    classes, targetStep, currentStep, jumpToStep, resetTargetStep,
  } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    setErrors, removeErrors, resetErrors, setFields, didFillEverything, errorList, hasErrors, requiredGotFilled, giveErrors,
  } = useValidator();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: '/step2'});

    const fetchData = async () => {
      try {
        const response = await contractService.get(2, sessionStorage.contractKey);
        if (response.data.status === 'OK') {
          const privateMail = response.data.mail && response.data.mail !== '' ? response.data.mail : user.email;
          setState({
            ...state,
            mail: privateMail,
            // eslint-disable-next-line no-nested-ternary
            tel: response.data.tel ? (response.data.tel.replace(/null/g, '') === '+36//' ? '' : response.data.tel.replace(/null/g, '')) : null,
          });

          if (isObjectNotEmpty(response.data.home)) {
            const homeAddressCopy = { ...homeAddress, ...response.data.home };
            setHomeAddress(homeAddressCopy);
            setCityList1([homeAddressCopy.city]);
          }

          if (response.data.mailing) {
            if (isObjectNotEmpty(response.data.mailing)) {
              const mailAddressCopy = {
                ...mailAddress,
                ...response.data.mailing,
              };
              setMailAddress(mailAddressCopy);
              setCityList2([mailAddressCopy.city]);
              setEqualAddress(false);
            }
          }
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (error) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFields(['zip', 'city', 'street', 'streetType', 'house', 'tel']);
    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (equalAddress) {
      setFields(['zip', 'city', 'street', 'streetType', 'house', 'tel']);

      const mailInputs = ['mail_zip', 'mail_city', 'mail_street', 'mail_streetType', 'mail_house'];

      mailInputs.map((input) => {
        const fakeEvent = {
          target: {
            name: input,
          },
        };

        return removeErrors(fakeEvent);
      });

      setMailAddress({
        zip: '',
        city: '',
        street: '',
        streetType: '',
        house: '',
        staircase: '',
        floor: '',
        door: '',
        poBox: '',
      });
    } else {
      setFields(['zip', 'city', 'street', 'streetType', 'house', 'tel', 'mail_zip', 'mail_city', 'mail_street', 'mail_streetType', 'mail_house']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equalAddress]);

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
  };

  const handleFocus = (e) => {
    removeErrors(e);
  };

  const listCitiesByZip = async (zip) => {
    let result = '';
    try {
      const response = await contractService.listCitiesByZip(zip);
      if (response.data.status === 'OK') {
        result = response.data.list.map((city) => city.name);
      } else {
        response.data.messages.map((message) => enqueueSnackbar(t(message), {
          variant: 'error',
        }));
      }
    } catch (e) {
      enqueueSnackbar(t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
    return result;
  };

  const handleOnPartnerChange = (e) => {
    requiredGotFilled(e);
    const { value, name } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleOnAddressChange = async (event, address) => {
    requiredGotFilled(event);
    const { name, value } = event.target;
    if (address === 'home') {
      const homeAddressCopy = JSON.parse(JSON.stringify(homeAddress));
      homeAddressCopy[name] = value;

      if (name === 'zip' && value.length === 4) {
        const cityListCopy = await listCitiesByZip(value);
        homeAddressCopy.city = homeAddressCopy.city ? homeAddressCopy.city : cityListCopy[0];
        setCityList1(cityListCopy);
      } else if (name === 'zip' && value.length < 4) {
        homeAddressCopy.city = '';
        setCityList1([]);
      }
      setHomeAddress(homeAddressCopy);
    } else {
      const mailAddressCopy = JSON.parse(JSON.stringify(mailAddress));
      const removedPrefix = removeAddressPrefix(name);
      mailAddressCopy[removedPrefix] = value;

      if (name === 'mail_zip' && value.length === 4) {
        const cityListCopy = await listCitiesByZip(value);
        mailAddressCopy.city = mailAddressCopy.city ? mailAddressCopy.city : cityListCopy[0];
        setCityList2(cityListCopy);
      } else if (name === 'mail_zip' && value.length < 4) {
        mailAddressCopy.city = '';
        setCityList2([]);
      }
      setMailAddress(mailAddressCopy);
    }
  };

  const prevStep = () => {
    setState({
      ...state,
      direction: -1,
    });
    props.prevStep();
  };

  const closeErrorDialog = () => {
    setErrorDialog({
      icon: 'warning',
      popupDisabled: false,
      dialogOpen: false,
      dialogContent: '',
    });
    resetTargetStep();
  };

  const getFieldsToCheck = () => {
    let toCheck = { tel: state.tel, ...homeAddress };
    if (!equalAddress) {
      toCheck = {
        ...toCheck,
        mail_zip: mailAddress.zip,
        mail_city: mailAddress.city,
        mail_street: mailAddress.street,
        mail_streetType: mailAddress.streetType,
        mail_house: mailAddress.house,
      };
    }

    return toCheck;
  };

  const submitData = async () => {
    const request = {
      key: sessionStorage.contractKey,
      home: {
        zip: homeAddress.zip,
        city: homeAddress.city,
        street: homeAddress.street,
        streetType: homeAddress.streetType,
        house: homeAddress.house,
        staircase: homeAddress.staircase,
        floor: homeAddress.floor,
        door: homeAddress.door,
      },
      mail: state.mail,
      tel: state.tel,
    };
    if (!equalAddress) {
      request.mailing = {
        zip: mailAddress.zip,
        city: mailAddress.city,
        street: mailAddress.street,
        streetType: mailAddress.streetType,
        house: mailAddress.house,
        staircase: mailAddress.staircase,
        floor: mailAddress.floor,
        door: mailAddress.door,
      };
    } else {
      request.mailing = {};
    }

    const response = await contractService.submit(2, request);
    if (response) return response;
  };

  useEffect(() => {
    const submit = async () => {
      try {
        const response = await submitData();
        if (response.data.status === 'OK') {
          jumpToStep();
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    };

    if (targetStep !== 0 && targetStep < currentStep) {
      const toCheck = getFieldsToCheck();
      if (didFillEverything(toCheck).length === 0) {
        submit();
      } else {
        const printDialogContent = (
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={12}>
              <p
                style={{
                  textAlign: 'left',
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              >
                {t('changeAccordionWarning')}
              </p>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right', marginTop: '16px' }}>
              <Button variant="outlined" color="primary" onClick={() => jumpToStep()}>
                {t('next')}
              </Button>
              <Button variant="outlined" color="primary" onClick={() => closeErrorDialog()}>
                {t('button.cancel')}
              </Button>
              <Grid item xs={12}>
                <Box className={classes.popupBottom} />
              </Grid>
            </Grid>
          </Grid>
        );
        setErrorDialog({
          ...errorDialog,
          dialogOpen: true,
          dialogContent: printDialogContent,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetStep, currentStep]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const toCheck = getFieldsToCheck();

    console.log('toCheck: ', toCheck);
    console.log(didFillEverything(toCheck));
    if (didFillEverything(toCheck).length === 0) {
      try {
        const response = await submitData();
        if (response.data.status === 'OK') {
          props.nextStep();
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    } else {
      const errors = didFillEverything(toCheck);
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error',
      });
      errors.map((field) => giveErrors(field, key));
    }
  };

  const handleCheckbox = () => {
    setEqualAddress(!equalAddress);
    if (equalAddress === false) {
      setState({
        ...state,
        tab: 0,
      });
    }
  };

  return (
    <>
      <ErrorDialog
        showError={errorDialog.dialogOpen}
        content={errorDialog.dialogContent}
        handleClose={closeErrorDialog}
        title=""
        icon={errorDialog.icon}
      />
      <form onSubmit={handleSubmit} onError={(errors) => console.error(errors)}>
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: '20px', marginTop: '-8px' }}>
            <Typography variant="subtitle2" component="h3">
              {t('regularAddress')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Address
              required
              addressType="home"
              address={homeAddress}
              cityList={cityList1}
              handleOnAddressChange={handleOnAddressChange}
              namePrefix=""
              handleBlur={handleBlur}
              handleFocus={handleFocus}
              errorList={errorList}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={equalAddress}
                  onChange={handleCheckbox}
                  value={equalAddress}
                  color="primary"
                  inputProps={{
                    'aria-label': 'secondary checkbox',
                  }}
                />
              )}
              label={t('equalAddresses')}
              style={{
                marginLeft: '8px',
                marginBottom: '16px',
                marginTop: '16px',
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: '20px',
              marginTop: '16px',
              display: `${equalAddress ? 'none' : 'block'}`,
            }}
          >
            <Typography variant="subtitle2" component="h3">
              {t('mailAddress')}
            </Typography>
          </Grid>
          <Address
            hidden={equalAddress}
            addressType="mail"
            address={mailAddress}
            cityList={cityList2}
            handleOnAddressChange={handleOnAddressChange}
            namePrefix="mail_"
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            required={!equalAddress}
            errorList={errorList}
          />
          <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center" className={classes.warningField}>
              <Grid item xs={12} sm="auto" style={{ textAlign: 'center' }}>
                <img src={Warning} style={{ width: '75px ', height: 'auto' }} alt="" />
              </Grid>
              <Grid item xs={12} sm className="warningText">
                <p style={{ marginTop: '0', marginBottom: '8px' }}>
                  <strong>{t('changePrivateEmail')}</strong>
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper inputStyle={hasErrors('tel') ? 'Error' : 'Main'} label={t('mobilePhone')}>
              <InputMask
                disabled={state.type === 'U' || state.type === 'H'}
                mask="+36/99/9999999"
                maskChar={null}
                value={state.tel}
                onChange={handleOnPartnerChange}
                onBlur={(e) => handleBlur(e, ['required', 'min14'])}
                onFocus={handleFocus}
                name="tel"
              >
                {() => <TextField fullWidth id="tel" name="tel" variant="outlined" margin="dense" type="text" />}
              </InputMask>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper inputStyle="Main" label={t('privateEmail')}>
              <TextField
                fullWidth
                disabled
                variant="outlined"
                margin="dense"
                id="mail"
                name="mail"
                value={state.mail}
                onChange={handleOnPartnerChange}
              />
            </InputWrapper>
          </Grid>
          <Grid container justify="flex-end" style={{ marginTop: '30px' }}>
            <Button variant="outlined" color="secondary" onClick={prevStep}>
              {t('back')}
            </Button>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ marginLeft: '10px' }}
              disabled={equalAddress ? cityList1.length < 1 : cityList1.length < 1 || cityList2.length < 1}
            >
              {t('next')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

AddressData.propTypes = {
  classes: PropTypes.shape(),
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  targetStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  jumpToStep: PropTypes.func.isRequired,
  resetTargetStep: PropTypes.func.isRequired,
};

AddressData.defaultProps = {
  classes: {},
};

export default withStyles(styles)(AddressData);
