import { Select, MenuItem, FormControl, FormHelperText, Box } from '@material-ui/core';

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { invoiceDirTypes } from '../../utils/Consts';
import InputWrapper from './InputWrapper';

const InvoiceDir = (props) => {
  const { t } = useTranslation();
  const { employer, company, required, direction, fullWidth, handleOnInvoiceChange } = props;
  const filtered = invoiceDirTypes.filter(
    // 2022-04-27 pjung TNRUKAYCM-1389 Céges számla tiltása
    (dirType) => (dirType.key < 3 || employer) && (dirType.key > 1 || company),
  );
  console.log('company', company, 'szla irányok', filtered);
  /// console.trace();

  // 2022-04-27 pjung TNRUKAYCM-1389 Érvénytelen szla.irány javítása...
  const dir = filtered.find((d) => d.key === direction) || filtered[0];
  if (direction !== 0 && dir.key !== direction) handleOnInvoiceChange({ target: { name: 'direction', value: dir.key } });

  const result = filtered.map((invoiceDirType) => (
    <MenuItem value={invoiceDirType.key} key={invoiceDirType.key}>
      {t(invoiceDirType.label)}
    </MenuItem>
  ));

  const error = required && direction === 0;

  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <InputWrapper inputStyle="Main" label={t('invoiceDir')}>
        <Select id="direction" name="direction" variant="outlined" margin="dense" value={direction} onChange={handleOnInvoiceChange}>
          {result}
        </Select>
      </InputWrapper>
      <Box display={error ? 'block' : 'none'}>
        <FormHelperText>{t('validate_require')}</FormHelperText>
      </Box>
    </FormControl>
  );
};

InvoiceDir.propTypes = {
  employer: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  direction: PropTypes.number.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  handleOnInvoiceChange: PropTypes.func.isRequired,
};

InvoiceDir.defaultProps = {
  required: false,
};

export default InvoiceDir;
