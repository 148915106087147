import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Grid,
  TextField,
  withStyles,
  Button,
  Box,
  Link
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import InputWrapper from '../components/ui/InputWrapper';
import { checkBankAccountCdv, priceMask } from '../utils/Utils';
import { contractService } from '../services/contracting';
import { paymentService } from '../services/payment';
import { styles } from '../theme/styles';
import Warning from '../assets/images/figyelmezteto_tabla_zold.png';
import { API_URL } from '../utils/Consts';
import DirectDebitHistory from '../components/ui/DirectDebitHistory';
import ErrorDialog from '../components/ui/ErrorDialog';
import useValidator from '../utils/useValidator';

const DirectDebitPage = props => {
  const defaultState = {
    account: '',
    fee: 0,
    from: '',
    limit: -1,
    list: [],
    state: false,
    status: 'OK',
    to: ''
  };

  const [expanded, setExpanded] = useState('panel1');
  const [data, setData] = useState(defaultState);
  const [tnrData, setTnrData] = useState(defaultState);
  const [bankName, setBankName] = useState('');
  const [isBankInvalid, setIsBankInvalid] = useState(true);
  const [dialog, setDialog] = useState({ open: false, content: '' });
  const [popupIcon, setpPopupIcon] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    setFields,
    resetErrors,
    giveErrors,
    // errorList,
    didFillEverything,
    setErrors,
    removeErrors,
    hasErrors
  } = useValidator();

  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFields(['account', 'limit']);

    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBankNameByAccount = useCallback(
    async accountNumber => {
      let result = '';
      try {
        const response = await contractService.getBankNameByAccount(
          accountNumber
        );
        if (response.data.status === 'OK') {
          result = response.data.name;
        } else {
          response.data.messages.map(message =>
            enqueueSnackbar(props.t(message), {
              variant: 'error'
            })
          );
        }
      } catch (error) {
        enqueueSnackbar(props.t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
      setBankName(result);
      setIsBankInvalid(false);
    },
    [enqueueSnackbar, props]
  );

  useEffect(() => {
    const getData = async () => {
      const directDebit = await paymentService.getDirectDebitData();
      if (directDebit.data.status === 'OK') {
        setData({
          ...directDebit.data,
          limit:
            directDebit.data.limit > 0
              ? directDebit.data.limit
              : directDebit.data.fee,
          from: directDebit.data.from ? directDebit.data.from : '',
          to: directDebit.data.to ? directDebit.data.to : '',
          account: directDebit.data.account
            ? directDebit.data.account.match(/.{1,8}/g).join('-')
            : ''
        });
        setTnrData({
          ...directDebit.data,
          limit: directDebit.data.limit > 0 ? directDebit.data.limit : 0,
          from: directDebit.data.from ? directDebit.data.from : '',
          to: directDebit.data.to ? directDebit.data.to : '',
          account: directDebit.data.account
            ? directDebit.data.account.match(/.{1,8}/g).join('-')
            : ''
        });
        if (directDebit.data.account) {
          getBankNameByAccount(directDebit.data.account.split('-')[0]);
        }
        if (directDebit.data.state === true) {
          setExpanded('panel2');
        }
      } else {
        console.log('error');
      }
    };

    getData();
  }, [getBankNameByAccount]);

  const handleInputChange = event => {
    const { name, value } = event.target;

    if (
      name === 'account' &&
      value !== null &&
      value.split('-')[0].replace(/\s/g, '').length > 2 &&
      value.slice(0, 3) !== data.account.slice(0, 3)
    ) {
      getBankNameByAccount(value);
      setData({
        ...data,
        [name]: value
      });
    } else if (name === 'limit') {
      const re = /[0-9]/g;
      if (value === '' || !re.test(value)) {
        setData({ ...data, [name]: '' });
      } else {
        const removeMask = value.match(re).join('');
        setData({
          ...data,
          [name]: removeMask
        });
      }
    } else if (
      name === 'account' &&
      value.split('-')[0].replace(/\s/g, '').length <= 3
    ) {
      setData({
        ...data,
        [name]: value
      });
      setBankName('');
    } else {
      setData({
        ...data,
        [name]: value
      });
    }
  };

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleBlur = (e, rules) => {
    if (e.target.name === 'account') {
      const result = checkBankAccountCdv(e.target.value);
      if (result === false) {
        const key = enqueueSnackbar(t('validate_bankAccount'), {
          variant: 'error'
        });
        giveErrors('account', key);
      }
    }

    setErrors(e, rules);
  };

  const handleFocus = e => {
    removeErrors(e);
  };

  const onSubmit = async () => {
    const toCheck = { account: data.account, limit: data.limit };
    if (didFillEverything(toCheck).length === 0) {
      setpPopupIcon('loading');
      setDialog({
        open: true,
        content: printLoadingDialogContent()
      });
      setIsLoading(true);
      try {
        const response = await paymentService.setDirectDebitAuth({
          account: data.account.match(/[0-9]/g).join(''),
          limit: data.limit
        });
        if (response.data.status === 'OK') {
          setpPopupIcon('success');
          setDialog({
            open: true,
            content: printDownloadDialogContent(
              response.data.key,
              props.currentUser.kr
            )
          });
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const errors = didFillEverything(toCheck);
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error'
      });
      errors.map(field => giveErrors(field, key));
    }
  };

  const closeErrorDialog = () => {
    setDialog({
      open: false,
      Content: ''
    });
  };

  const printDownloadDialogContent = (key, kr) => {
    const { t } = props;
    return (
      <>
        <Grid container alignItems="center" style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: 'black',
                    fontWeight: '700',
                    fontSize: '14px',
                    marginBottom: '32px'
                  }}
                >
                  {t('docsReady')}
                </Typography>
                <Link
                  href={`${API_URL}/common-services/document?id=${key}&kr=${kr}`}
                  underline="none"
                >
                  <Box style={{ textAlign: 'right', marginTop: '60px' }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setDialog({ ...dialog, open: false })}
                    >
                      {t('download')}
                    </Button>
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.popupBottom} />
          </Grid>
        </Grid>
      </>
    );
  };

  const printLoadingDialogContent = () => {
    const { t, classes } = props;
    return (
      <>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: '700', fontSize: '14px' }}
            >
              {t('docsLoading')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.popupBottom} />
          </Grid>
        </Grid>
      </>
    );
  };

  const { t, classes } = props;
  return (
    <>
      <ErrorDialog
        icon={popupIcon}
        showError={dialog.open}
        content={dialog.content}
        handleClose={closeErrorDialog}
        disabled={isLoading}
        title=""
      />
      <Box style={{ padding: '8px' }}>
        <ExpansionPanel
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>{t('directDebitAuth')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ValidatorForm
              style={{ width: '100%' }}
              onSubmit={onSubmit}
              ref={form}
            >
              <Grid container justify="center">
                <Grid item xs={12}>
                  <InputWrapper
                    inputStyle={hasErrors('account') ? 'Error' : 'Main'}
                    label={t('label.bankAccountNumber')}
                  >
                    <InputMask
                      mask="99999999-99999999-99999999"
                      maskChar=" "
                      disabled={data.state}
                      value={data.account}
                      onChange={handleInputChange}
                      onBlur={e => handleBlur(e, ['bankAccount'])}
                      onFocus={handleFocus}
                      name="account"
                    >
                      {() => (
                        <TextValidator
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          id="account"
                          name="account"
                          type="text"
                        />
                      )}
                    </InputMask>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper inputStyle="Main" label={t('label.bankName')}>
                    <TextField
                      fullWidth
                      disabled
                      id="bankName"
                      name="bankName"
                      variant="outlined"
                      margin="dense"
                      value={bankName}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper
                    inputStyle={hasErrors('limit') ? 'Error' : 'Main'}
                    label={t('limit')}
                  >
                    <TextValidator
                      fullWidth
                      id="limit"
                      name="limit"
                      variant="outlined"
                      margin="dense"
                      disabled={data.state}
                      value={data.limit ? `${priceMask(data.limit)}` : ''}
                      onChange={handleInputChange}
                      onBlur={e => handleBlur(e, ['required'])}
                      onFocus={handleFocus}
                      InputProps={{
                        endAdornment: t('priceAdornment')
                      }}
                      inputProps={{
                        maxLength: 7,
                        style: {
                          width: '60px'
                        }
                      }}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    className={classes.warningField}
                    alignItems="center"
                    justify="flex-start"
                  >
                    <Grid item xs={12} sm="auto" className="warningSign">
                      <img src={Warning} alt="" />
                    </Grid>
                    <Grid item xs={12} sm className="warningText">
                      <p>
                        <span
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: '700'
                          }}
                        >
                          {t('directDebitWarningHeading')}
                        </span>
                        <br />
                        {t('directDebitWarningText')}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  disabled={isBankInvalid || data.state}
                >
                  {t('download')}
                </Button>
              </Grid>
            </ValidatorForm>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>{t('directDebitData')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>
              <Grid item xs={12}>
                <InputWrapper label={t('label.bankAccountNumber')}>
                  <InputMask
                    mask="99999999-99999999-99999999"
                    maskChar=" "
                    disabled
                    value={tnrData.account}
                  >
                    {() => (
                      <TextField
                        id="accountTnr"
                        name="accountTnr"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        inputProps={{
                          style: { textAlign: 'left', color: 'black' }
                        }}
                      />
                    )}
                  </InputMask>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper label={t('limit')}>
                  <TextField
                    id="limitTnr"
                    name="limitTnr"
                    variant="outlined"
                    margin="dense"
                    disabled
                    fullWidth
                    value={tnrData.limit ? `${tnrData.limit} Ft` : ''}
                    inputProps={{
                      style: { textAlign: 'left', color: 'black' }
                    }}
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper label={t('validFrom')}>
                  <TextField
                    id="from"
                    name="from"
                    variant="outlined"
                    margin="dense"
                    disabled
                    fullWidth
                    value={tnrData.from}
                    inputProps={{
                      style: { textAlign: 'left', color: 'black' }
                    }}
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper label={t('validTo')}>
                  <TextField
                    id="to"
                    name="to"
                    variant="outlined"
                    margin="dense"
                    disabled
                    fullWidth
                    value={tnrData.to}
                    inputProps={{
                      style: { textAlign: 'left', color: 'black' }
                    }}
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper label={t('currentState')}>
                  <TextField
                    id="state"
                    name="state"
                    variant="outlined"
                    margin="dense"
                    disabled
                    fullWidth
                    value={tnrData.state ? t('active') : t('inactive')}
                    inputProps={{
                      style: { textAlign: 'left', color: 'black' }
                    }}
                  />
                </InputWrapper>
              </Grid>
              {tnrData.list.length ? (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      marginTop: '32px',
                      marginBottom: '32px',
                      fontSize: '14px',
                      fontWeight: '700'
                    }}
                  >
                    {t('directDebitHistory')}
                  </Typography>
                  <DirectDebitHistory list={data.list} />
                </Grid>
              ) : null}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Box>
    </>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser
});

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(DirectDebitPage))
);
