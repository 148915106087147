import axios from 'axios';

const baseUrl = `${
  process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:8080'
}/cportal-payment-services/api/payment/v1`;
const publicBaseUrl = `${
  process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:8080'
}/cportal-payment-services/api/public/payment/v1`;

const config = {
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
};

export const paymentService = {
  getAmounts,
  startTransaction,
  endTransaction,
  getInvState,
  listInvoices,
  checkDownloadInvoice,
  getDownloadInvoice,
  getRegularCreditData,
  setRegularCreditAuth,
  getDirectDebitData,
  setDirectDebitAuth
};

function addJWT() {
  config.withCredentials = true;
  config.headers.Authorization = `Bearer ${sessionStorage.token}`;
}

async function getAmounts(request) {
  addJWT();
  return axios.post('/getAmounts', request, config);
}

async function startTransaction(request) {
  addJWT();
  return axios.post('/startTransaction', request, config);
}

async function endTransaction(transaction) {
  addJWT();
  return axios.post('/endTransaction', { transaction }, config);
}

async function getInvState(kr) {
  addJWT();
  return axios.post('/getInvState', { kr }, config);
}

async function listInvoices(request) {
  addJWT();
  return axios.post('/listInvoices', request, config);
}

async function checkDownloadInvoice(request) {
  return axios.post(`${publicBaseUrl}/checkDownloadInvoice`, request);
}

async function getDownloadInvoice(request) {
  return axios.post(`${publicBaseUrl}/getDownloadInvoice`, request);
}

async function getRegularCreditData(request) {
  addJWT();
  return axios.post('/getRegularCreditData', request, config);
}

async function setRegularCreditAuth(request) {
  addJWT();
  return axios.post('/setRegularCreditAuth', request, config);
}

async function getDirectDebitData(request) {
  addJWT();
  return axios.post('/getDirectDebitData', request, config);
}

async function setDirectDebitAuth(request) {
  addJWT();
  return axios.post('/setDirectDebitAuth', request, config);
}
