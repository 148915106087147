import React from 'react';
import { Paper, withStyles, Box } from '@material-ui/core';
import { styles } from '../theme/styles';
import LoggedOutHeader from '../components/ui/LoggedOutHeader';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const LayoutWrapper = props => {
  const { isUserLoggedIn, classes, t } = props;
  return (
    <>
      <Paper
        className={
          isUserLoggedIn() ? classes.mainViewHasUser : classes.mainViewNoUser
        }
      >
        {isUserLoggedIn() ? null : <LoggedOutHeader />}
        {props.children}
        <Box
          display={props.location.pathname === '/payment' ? 'block' : 'none'}
          style={{ fontSize: '8.75px', fontWeight: '700' }}
        >
          <p style={{ textAlign: 'right', marginTop: '3px', color: 'rgba(161, 161, 161, 1)' }}>
            {/* 2021-11-29 pjung TNRUKAYCM-1282 Lábléc módosítás: fizetési tájékoztató link */}
            <a
              href='https://allyoucanmove.hu/media/fizetesi_tajekoztato.pdf'
              rel='noopener noreferrer'
              target='_blank'
              style={{ color: 'rgba(0,191,243)', textDecoration: 'none' }}>
              {t('purchasingInfo')}
            </a>
              Kártyaelfogadó kereskedő:
            <a
              href='http://www.klubrekreacio.hu'
              rel='noopener noreferrer'
              target='_blank'
              style={{ color: 'rgba(0,191,243)', textDecoration: 'none' }}
            >
              KLUB Rekreáció Kft.
            </a>{' '}
              Internetes kártyaelfogadás:{' '}
            <a
              href='https://www.otpmobil.hu/'
              rel='noopener noreferrer'
              target='_blank'
              style={{ color: 'rgba(0,191,243)', textDecoration: 'none' }}
            >
              {' '}
              OTP Mobil Kft.
            </a>
          </p>
        </Box>
      </Paper>
    </>
  );
};

export default withTranslation()(withRouter(withStyles(styles)(LayoutWrapper)));
