import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import LoaderIcon from '../components/ui/LoaderIcon';
import { contractService } from '../services/contracting';

const ContractingIntraSumPage = () => {
  /**
   * Ezek az intranettől URL-ben kapott paraméterek
   * @uuid: subscription_uuid
   * @status: szerződéskötés státusza
   * @fromdate: szerződés kezdete
   * @todate: szerződés vége
   */
  const { uuid, status, fromdate, todate } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const response = await contractService.intraStatus({
        subscription_uuid: uuid,
        status,
        fromdate,
        todate
      });
      console.log("response.data", response?.data);

      if (response.data.status === 'OK') {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem(
          'currentUser',
          JSON.stringify(response.data.user)
        );

        // 2021-01-06 pjung TNRUKAYCM-937 R helyett U-ra vizsgáljuk...
        if (response.data.type === 'U') {
          history.push('/contracting/upgrade');
        } else {
          history.push('/contracting/init');
        }
      } else {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      style={{ padding: '16px 30px', height: '100%' }}
      justify="center"
    >
      <LoaderIcon />
    </Grid>
  );
};

export default ContractingIntraSumPage;
