import { getMonth } from 'date-fns';

const sulyBank = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7, 3, 1, 9, 7, 3];

function calcCDV(szla) {
  let ctemp;
  let ncdv = 0;
  let a = 1;

  ctemp = szla.substr(0, 8);

  for (a = 0; a < 8; a++) {
    ncdv += sulyBank[a] * ctemp.substr(a, 1);
  }

  if (ncdv % 10 !== 0) {
    return false;
  }
  return true;
}

export function isBlank(pString) {
  return !!(!pString || pString.length === 0 || pString === ' ');
}

export function isObjectNotEmpty(obj) {
  let res = false;
  for (const key in obj) {
    res = obj[key] !== '' && obj[key] !== null && obj[key] !== undefined;
    if (res) {
      break;
    }
  }
  return res;
}

export function isArrayNotEmpty(array) {
  return !!(Array.isArray(array) && array.length);
}

export function formatDate(timestamp) {
  const date = new Date(timestamp);
  date.setHours(12);
  const [year, month, day] = date
    .toISOString()
    .slice(0, 10)
    .split('-');

  return `${year}-${month}-${day}`;
}

export function formatDateHu(timestamp) {
  if (timestamp === '') {
    return '';
  }
  const date = new Date(timestamp);
  date.setHours(12);
  const [year, month, day] = date
    .toISOString()
    .slice(0, 10)
    .split('-');

  return `${year}.${month}.${day}`;
}

export function isMonthLater(dateString, month) {
  const dateMonth = getMonth(new Date(dateString));
  return dateMonth > month;
}

export function isObjectEquivalent(a, b) {
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  return true;
}

/**
 * Adószám ellenőrzése:
 * Rossz a kapott érték hossza (pontosan 13 karakter lehet)
 * A kapott érték nem csak számjegyet tartalmaz
 * A 9. helyen nem 1, 2 vagy 3 szerepel (adómentes, adóköteles, EVA)
 * Az utolsó két számjegy nem a következők egyike: 02-20, 22-44, 51
 * A kapott érték CDV hibás
 */

export function checkTaxNumber(taxNum) {
  let a = taxNum;
  if (isBlank(a) || a.length !== 13) {
    return false;
  }
  if (a.charAt(8) !== '-' || a.charAt(10) !== '-') {
    return false;
  }

  a = a.substring(0, 8) + a.substring(9, 10) + a.substring(11);
  const c = a.charAt(8);
  if (c < '1' || c > '5') {
    return false;
  }

  const i = parseInt(a.substring(9, 11));
  if (i < 2 || i === 21 || (i > 44 && i !== 51)) {
    return false;
  }

  return calcCDV(a.substring(0, 8));
}

export function checkBankCdv(num) {
  if (isBlank(num) || num.length !== 8) {
    return false;
  }
  return calcCDV(num.substring(0, 8));
}

export function checkBankAccountCdv(szla) {
  const splitValue = szla.trim().split('-');

  const checkIfAllTrue = (bool) => bool === true;

  const noSpaces = splitValue
    .map((part) => (part.replace(/\s/g, '').length
      ? part.replace(/\s/g, '').length === 8
      : true))
    .every(checkIfAllTrue);
  if (!noSpaces) {
    return false;
  }

  const num = szla.replace(/-/g, '').trim();
  if (num.length !== 16 && num.length !== 24) {
    return false;
  }
  return calcCDV(num.substr(0.16));
}

// 2020-12-08 jpung TNRUKAYCM-923 zöld... számformázás
export function priceMask2(num, sep) {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
  }
  if (num === 0) {
    return 0;
  }
}

export function priceMask(num) {
  return priceMask2(num, ' ');
}

export function reversePriceMask(str) {}

export function removeAddressPrefix(addressWithPrefix) {
  return addressWithPrefix.split('_')[1];
}

export function changeObjectNullValuesToEmptyString(object) {
  const newObject = {};
  Object.keys(object).map((key) => (newObject[key] = object[key] === null ? '' : object[key]));
  return newObject;
}
