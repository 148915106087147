import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid, Step, StepContent, StepLabel,
} from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import {
  CardDataLabel, ContractDataLabel, InvoicingDataLabel, PersonalDataLabel,
} from '../components/utils/StepLabels';
import { getAddress } from '../components/utils/Tools';
import AddressData from './contract/AddressData';
import CardData from './contract/CardData';
import ContractData from './contract/ContractData';
import InvoicingData from './contract/InvoicingData';
import LedgerData from './contract/LedgerData';
import PartnerData from './contract/PartnerData';
import PaymentData from './contract/PaymentData';


class ContractingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finished: false,
      activeStep: 0,
      targetStep: 0,
      partner: {},
      homeAddress: {},
      mailAddress: {},
      contract: {},
      ledger: {},
      payment: {},
    };
  }

  componentDidMount() {
    if (sessionStorage.step) {
      this.setState({ activeStep: parseInt(sessionStorage.step, 10) });
      if (sessionStorage.step === '8') {
        this.props.history.push('/contracting/sum');
      }
    }
  }

  setPartner = (partner) => {
    this.setState({ partner });
  }

  setHomeAddress = (homeAddress) => {
    this.setState({ homeAddress });
  }

  setMailAddress = (mailAddress) => {
    this.setState({ mailAddress });
  }

  setInvoiceAddress = (invoiceAddress) => {
    this.setState({ invoiceAddress });
  }

  setContract = (contract) => {
    const newContract = { ...this.state.contract, ...contract };
    this.setState({ contract: newContract });
  }

  setLedger = (ledger) => {
    const newLedger = { ...this.state.ledger, ...ledger };
    this.setState({ ledger: newLedger });
  }

  setPayment = (payment) => {
    const newPayment = { ...this.state.payment, ...payment };
    this.setState({ payment: newPayment });
  }

  nextStep = () => {
    const step = this.state.activeStep + 1;
    const element = document.getElementById(`Step-${this.state.activeStep}`);
    this.setState({ activeStep: step });
    sessionStorage.setItem('step', step);
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  prevStep = () => {
    const step = this.state.activeStep - 1;
    const element = document.getElementById(`Step-${step}`);
    this.setState({ activeStep: step });
    sessionStorage.setItem('step', step);
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  resetTargetStep = () => {
    this.setState({ targetStep: 0 });
  }

  jumpToStep = () => {
    const { targetStep } = this.state;
    this.setState({
      activeStep: targetStep,
    });
    sessionStorage.setItem('step', targetStep);
    this.resetTargetStep();
  }

  handleClick = (event) => {
    const element = event.target;
    let step;
    if (element.parentElement.parentElement.id) {
      step = Number(element.parentElement.parentElement.id.split('-')[1]);
    } else if (element.parentElement.parentElement.parentElement.id) {
      step = Number(element.parentElement.parentElement.parentElement.id.split('-')[1]);
    }
    if (this.state.activeStep === 7 && step < this.state.activeStep) {
      this.setState({ activeStep: step });
    } else if (step < this.state.activeStep) {
      this.setState({ targetStep: step });
    }
  }

  handleAgreement = (event) => this.setState({ agreement: event.target.value });

  render() {
    const { t } = this.props;
    return (
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
        <Grid item xs={12}>
          <Stepper activeStep={this.state.activeStep - 1} orientation="vertical">
            <Step key="1">
              <StepLabel icon="1" variant="h6" id="Step-1" style={{ cursor: 'pointer' }} onClick={this.handleClick}>
                <PersonalDataLabel partner={this.state.partner} update={this.state.update} />
              </StepLabel>
              <StepContent icon="1">
                <PartnerData
                  nextStep={this.nextStep}
                  partner={this.state.partner}
                  setPartner={this.setPartner}
                  targetStep={this.state.targetStep}
                  currentStep={this.state.activeStep}
                  jumpToStep={this.jumpToStep}
                  resetTargetStep={this.resetTargetStep}
                />
              </StepContent>
            </Step>

            <Step key="2">
              <StepLabel icon="2" variant="h6" id="Step-2" style={{ cursor: 'pointer' }} onClick={this.handleClick}>
                {t('addressAndPhone')}
                {' '}
                {getAddress(this.state.homeAddress)}
              </StepLabel>
              <StepContent icon="2">
                <AddressData
                  prevStep={this.prevStep}
                  nextStep={this.nextStep}
                  homeAddress={this.state.homeAddress}
                  setHomeAddress={this.setHomeAddress}
                  mailAddress={this.state.mailAddress}
                  setMailAddress={this.setMailAddress}
                  targetStep={this.state.targetStep}
                  currentStep={this.state.activeStep}
                  jumpToStep={this.jumpToStep}
                  resetTargetStep={this.resetTargetStep}
                />
              </StepContent>
            </Step>

            <Step key="3">
              <StepLabel icon="3" id="Step-3" style={{ cursor: 'pointer' }} onClick={this.handleClick}>
                <ContractDataLabel contract={this.state.contract} update={this.props.update} />
              </StepLabel>
              <StepContent icon="3">
                <ContractData
                  prevStep={this.prevStep}
                  nextStep={this.nextStep}
                  contract={this.state.contract}
                  setContract={this.setContract}
                  targetStep={this.state.targetStep}
                  currentStep={this.state.activeStep}
                  jumpToStep={this.jumpToStep}
                  resetTargetStep={this.resetTargetStep}
                />
              </StepContent>
            </Step>

            <Step key="4">
              <StepLabel icon="4" id="Step-4" style={{ cursor: 'pointer' }} onClick={this.handleClick}>
                <CardDataLabel cardType={this.state.contract?.cardType} update={this.state.update} kr={this.state.partner?.kr} />
              </StepLabel>
              <StepContent icon="4">
                <CardData
                  prevStep={this.prevStep}
                  nextStep={this.nextStep}
                  contract={this.state.contract}
                  setContract={this.setContract}
                  targetStep={this.state.targetStep}
                  currentStep={this.state.activeStep}
                  jumpToStep={this.jumpToStep}
                  resetTargetStep={this.resetTargetStep}
                />
              </StepContent>
            </Step>

            <Step key="5">
              <StepLabel icon="5" id="Step-5" style={{ cursor: 'pointer' }} onClick={this.handleClick}>
                <InvoicingDataLabel ownAddress={this.state.ledger?.ownAddress} update={this.state.update} />
              </StepLabel>
              <StepContent icon="5">
                <InvoicingData
                  prevStep={this.prevStep}
                  nextStep={this.nextStep}
                  ledger={this.state.ledger}
                  setLedger={this.setLedger}
                  targetStep={this.state.targetStep}
                  currentStep={this.state.activeStep}
                  jumpToStep={this.jumpToStep}
                  resetTargetStep={this.resetTargetStep}
                />
              </StepContent>
            </Step>

            <Step key="6">
              <StepLabel icon="6" id="Step-6" style={{ cursor: 'pointer' }} onClick={this.handleClick}>{t('paymentData')}</StepLabel>
              <StepContent icon="6">
                <LedgerData
                  prevStep={this.prevStep}
                  nextStep={this.nextStep}
                  ledger={this.state.ledger}
                  setLedger={this.setLedger}
                  targetStep={this.state.targetStep}
                  currentStep={this.state.activeStep}
                  jumpToStep={this.jumpToStep}
                  resetTargetStep={this.resetTargetStep}
                />
              </StepContent>
            </Step>

            <Step key="7">
              <StepLabel icon="7" id="7" style={{ cursor: 'pointer' }} onClick={this.handleClick}>{t('paymentSum')}</StepLabel>
              <StepContent icon="7">
                <PaymentData
                  prevStep={this.prevStep}
                  finish={this.finish}
                  payment={this.state.payment}
                  setPayment={this.setPayment}
                  targetStep={this.state.targetStep}
                  currentStep={this.state.activeStep}
                  jumpToStep={this.jumpToStep}
                  resetTargetStep={this.resetTargetStep}
                />
              </StepContent>
            </Step>
          </Stepper>
        </Grid>
      </Grid>
    );
  }
}
export default withTranslation()(ContractingPage);
