import { UserActionTypes } from './user.types'

const INITIAL_STATE = {
  currentUser: {kr: '', from: '', to: '', pack: '', cardStatus: ''}
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      }
    case UserActionTypes.LOGOUT_USER:
      return {
        currentUser: INITIAL_STATE.currentUser
      }
    default: 
      return state;
  }
}

export default userReducer;