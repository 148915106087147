import { Box, MenuItem, withStyles, Link, RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/icons/CheckBox';
import CheckboxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import ReactGA from 'react-ga4';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Warning from '../../assets/images/figyelmezteto_tabla_zold.png';
import Address from '../../components/ui/Address';
import InputWrapper from '../../components/ui/InputWrapper';
import { contractService } from '../../services/contracting';
import { styles } from '../../theme/styles';
import { deliveryAddressTypes } from '../../utils/Consts';
import { isBlank } from '../../utils/Utils';
import useValidator from '../../utils/useValidator';
import ErrorDialog from '../../components/ui/ErrorDialog';
// import CardType from '../../components/ui/CardType';

const CardData = (props) => {
  const initialAddress = {
    zip: '',
    city: '',
    street: '',
    streetType: '',
    house: '',
    staircase: '',
    floor: '',
    door: '',
  };

  const [cityList, setCityList] = useState([]);
  const [cardState, setCardState] = useState({
    type: '',
    delivery: '0',
    receipt: 'c',
    addressee: '',
    validFrom: '',
    validTo: '',
    cardPackage: '',
    cardNum: '',
  });
  const [, setIsCardDisabled] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(1);
  const [homeAddress, setHomeAddress] = useState(initialAddress);
  const [mailAddress, setMailAddress] = useState(initialAddress);
  const [address, setAddress] = useState(initialAddress);
  const [feesData, setFeesData] = useState({
    hdij: 0,
    dij1: 0,
    dij2: 0,
    dij3: 0,
    dij4: 0,
    fdij: 0,
    fdij1: 0,
    fdij2: 0,
    fdij3: 0,
    fdij4: 0,
    fhonap: 0,
    fhdijo: 0,
    fosszeg: 0,
  });

  const [errorDialog, setErrorDialog] = useState({
    icon: 'warning',
    popupDisabled: false,
    dialogOpen: false,
    dialogContent: '',
  });

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { classes, nextStep, prevStep, targetStep, currentStep, jumpToStep, resetTargetStep } = props;

  const { setErrors, removeErrors, resetErrors, setFields, didFillEverything, errorList, requiredGotFilled } = useValidator();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: '/step4'});
    const fetchData = async () => {
      if (sessionStorage.contractKey) {
        try {
          const response = await contractService.get(4, sessionStorage.contractKey);
          if (response.data.status === 'OK') {
            const { type, contract, fees, home, mailing } = response.data;
            const stateCopy = { ...cardState };
            stateCopy.type = type || '';
            if (contract) {
              stateCopy.validFrom = contract.validFrom ? contract.validFrom : '';
              stateCopy.validTo = contract.validTo ? contract.validTo : '';
              stateCopy.receipt = contract.receipt ? contract.receipt : '';
              stateCopy.addressee = contract.addressee ? contract.addressee : '';
              stateCopy.cardPackage = contract.cardPackage ? contract.cardPackage : '';
              stateCopy.cardNum = contract.cardNum ? contract.cardNum : '';
            }

            if (home) {
              const homeAddressCopy = { ...homeAddress, ...home };
              setHomeAddress(homeAddressCopy);
            }
            if (mailing) {
              const mailAddressCopy = { ...mailAddress, ...mailing };
              setMailAddress(mailAddressCopy);
            }

            setCardState(stateCopy);

            if (contract.address) {
              const addressCopy = { ...address, ...contract.address };
              setAddress(addressCopy);
              setCityList([addressCopy.city]);
            }

            if (fees) {
              const feesCopy = { ...feesData, ...fees };
              setFeesData(feesCopy);
              if (contract.receipt !== 'c') {
                // Posta ha fdij2>0, GLS ha fdij4>0
                if (fees.fdij2 > 0) {
                  setCardState({ ...cardState, delivery: 1 });
                } else if (fees.fdij4 > 0) {
                  setCardState({ ...cardState, delivery: 2 });
                }
              }
            }
            /**
             * TODO
             * get-4 adatok alapján megjelenítés: ha az addressee nincs kitöltve,
             * akkor az állnadó lakcím kerül kijelölésre, különben az alábbi címre
             */
            if (isBlank(contract.addressee)) {
              setDeliveryAddress(1);
              setAddress(home);
            } else {
              setDeliveryAddress(3);
            }
          } else {
            response.data.messages.map((message) =>
              enqueueSnackbar(t(message), {
                variant: 'error',
              }),
            );
          }
        } catch (e) {
          enqueueSnackbar(t('TECHNICAL_ERROR'), {
            variant: 'error',
          });
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cardState.delivery > 0) {
      setFields(['zip', 'city', 'street', 'streetType', 'house']);
    }
    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardState.delivery]);

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
  };

  const handleFocus = (e) => {
    removeErrors(e);
  };

  const handleOnContractChange = (event) => {
    requiredGotFilled(event);
    const { name, value } = event.target;
    if (event.target.name === 'receipt' && event.target.value === 'c') {
      setCardState({
        ...cardState,
        addressee: '',
      });

      setDeliveryAddress(1);

      setAddress({
        zip: '',
        city: '',
        street: '',
        streetType: '',
        house: '',
        staircase: '',
        floor: '',
        door: '',
      });
    }

    setCardState({ ...cardState, [name]: value });
  };

  const handleOnPostAddressChange = (event) => {
    requiredGotFilled(event);
    const { value } = event.target;
    setDeliveryAddress(value);

    switch (value) {
      case 1:
        // Állandó
        setCardState({ ...cardState, addressee: '' });
        setAddress(homeAddress);
        setCityList([homeAddress.city]);
        break;
      case 2:
        // Levelezése
        setCardState({ ...cardState, addressee: '' });
        setAddress(mailAddress);
        setCityList([mailAddress.city]);
        break;
      default:
        setCardState({ ...cardState, addressee: '' });
        setAddress({
          zip: '',
          city: '',
          street: '',
          streetType: '',
          house: '',
          staircase: '',
          floor: '',
          door: '',
        });
    }
  };

  const closeErrorDialog = () => {
    setErrorDialog({
      icon: 'warning',
      popupDisabled: false,
      dialogOpen: false,
      dialogContent: '',
    });
    resetTargetStep();
  };

  const submitData = async () => {
    const feesCopy = JSON.parse(JSON.stringify(feesData));

    if (cardState.receipt === 'c') {
      feesCopy.fdij2 = 0;
      feesCopy.fdij4 = 0;
    } else {
      switch (cardState.delivery) {
        case '1':
          // Posta
          feesCopy.fdij2 = feesCopy.dij2;
          feesCopy.fdij4 = 0;
          break;
        case '2':
          // GLS
          feesCopy.fdij4 = feesCopy.dij4;
          feesCopy.fdij2 = 0;
          break;
        default:
          feesCopy.fdij2 = 0;
          feesCopy.fdij4 = 0;
      }
    }
    setFeesData(feesCopy);

    const response = await contractService.submit(4, {
      key: sessionStorage.contractKey,
      contract: {
        addressee: cardState.addressee,
        // FIXME Ezeket ideiglenesen állítjuk, mert a backend nem csinálja
        validFrom: cardState.validFrom,
        validTo: cardState.validTo,
        cardPackage: cardState.cardPackage,
        // --
        receipt: cardState.receipt,
        address,
      },
      fees: feesData,
    });

    if (response) return response;
  };

  const getFieldsToCheck = () => {
    let toCheck = {};
    if (cardState.receipt === 'b' && cardState.delivery > 0) {
      toCheck = { ...address };
    }

    return toCheck;
  };

  useEffect(() => {
    const submit = async () => {
      try {
        const response = await submitData();
        if (response.data.status === 'OK') {
          jumpToStep();
        } else {
          response.data.messages.map((message) =>
            enqueueSnackbar(t(message), {
              variant: 'error',
            }),
          );
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    };

    if (targetStep !== 0 && targetStep < currentStep) {
      const toCheck = getFieldsToCheck();
      if (didFillEverything(toCheck).length === 0) {
        submit();
      } else {
        const printDialogContent = (
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={12}>
              <p style={{ textAlign: 'left', fontSize: '14px', fontWeight: '700' }}>{t('changeAccordionWarning')}</p>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right', marginTop: '16px' }}>
              <Button variant="outlined" color="primary" onClick={() => jumpToStep()}>
                {t('next')}
              </Button>
              <Button variant="outlined" color="primary" onClick={() => closeErrorDialog()}>
                {t('button.cancel')}
              </Button>
              <Grid item xs={12}>
                <Box className={classes.popupBottom} />
              </Grid>
            </Grid>
          </Grid>
        );
        setErrorDialog({
          ...errorDialog,
          dialogOpen: true,
          dialogContent: printDialogContent,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetStep, currentStep]);

  useEffect(() => {
    const year = new Date(cardState.validFrom).getFullYear();
    if (cardState.type === 'U' && year === 2019) {
      setIsCardDisabled(true);
      setCardState({
        ...cardState,
        receipt: 'a',
      });
    } else if (cardState.cardNum.slice(0, 2) === 'EP') {
      setCardState({
        ...cardState,
        receipt: 'a',
      });
    } else {
      setCardState({
        ...cardState,
        receipt: 'c',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardState.type, cardState.validFrom]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const toCheck = getFieldsToCheck();
    if (didFillEverything(toCheck).length === 0) {
      try {
        const response = await submitData();

        if (response.data.status === 'OK') {
          nextStep();
        } else {
          response.data.messages.map((message) =>
            enqueueSnackbar(t(message), {
              variant: 'error',
            }),
          );
        }
      } catch (e) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    }
  };

  const listCitiesByZip = async (zip) => {
    let result = '';
    try {
      const response = await contractService.listCitiesByZip(zip);
      if (response.data.status === 'OK') {
        result = response.data.list.map((city) => city.name);
      } else {
        response.data.messages.map((message) =>
          enqueueSnackbar(t(message), {
            variant: 'error',
          }),
        );
      }
    } catch (e) {
      enqueueSnackbar(t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
    return result;
  };

  const handleOnAddressChange = async (event, addressType) => {
    requiredGotFilled(event);
    const { name, value } = event.target;
    if (addressType === 'delivery') {
      const addressCopy = JSON.parse(JSON.stringify(address));
      addressCopy[name] = value;
      if (name === 'zip' && value.length === 4) {
        const cityListCopy = await listCitiesByZip(value);
        addressCopy.city = addressCopy.city ? addressCopy.city : cityListCopy[0];

        setCityList(cityListCopy);
      } else if (name === 'zip' && value.length < 4) {
        addressCopy.city = '';
        setCityList([]);
      }
      setAddress(addressCopy);
    }
  };

  let key = 0;
  if (!mailAddress || isBlank(mailAddress.zip)) {
    key = 2;
  }
  const filtered = deliveryAddressTypes.filter((addressType) => addressType.key !== key);
  const deliveryAddressMenuItems = filtered.map((addressType) => (
    <MenuItem value={addressType.key} key={addressType.key}>
      {t(addressType.label)}
    </MenuItem>
  ));

  return (
    <>
      <ErrorDialog
        showError={errorDialog.dialogOpen}
        content={errorDialog.dialogContent}
        handleClose={closeErrorDialog}
        title=""
        icon={errorDialog.icon}
      />
      <form onSubmit={handleSubmit} onError={(errors) => console.error(errors)}>
        <Typography component="section">
          <Grid container component="article">
            {/* <Grid item xs={12}>
              <CardType
                id="receipt"
                name="receipt"
                value={cardState.receipt}
                onChange={handleOnContractChange}
                disabled={cardState.type === 'U' || isCardDisabled}
                type={cardState.type}
                cardNum={cardState.cardNum}
                contractYear={cardState.validFrom}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Grid container justify="flex-start" alignItems="center" className={classes.warningField}>
                <Grid item xs={12} sm="auto" style={{ textAlign: 'center' }}>
                  <img src={Warning} style={{ width: '75px ', height: 'auto' }} alt="" />
                </Grid>
                <Grid item xs={12} sm className="warningText">
                  <p style={{ marginTop: '0', marginBottom: '8px' }}>{t('infoAboutEpass')}</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.downloadWrapper} style={{ marginBottom: '30px' }}>
                <Grid container justify="space-between">
                  <Grid item xs={12} sm style={{ textAlign: 'center' }}>
                    <p className={classes.downloadWrapperText} style={{ color: 'black' }}>
                      {t('epassInfo')}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
                    <Link href="https://allyoucanmove.hu/content_files/ePASS_tajekoztato.pdf" target="_blank" underline="none">
                      <Button>{t('download')}</Button>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={cardState.receipt === 'b' ? 'inline' : 'none'}>
                <FormControl fullWidth style={{ marginLeft: '12px', marginBottom: '16px' }}>
                  <RadioGroup
                    aria-label={t('cardDelivery')}
                    id="delivery"
                    name="delivery"
                    onChange={handleOnContractChange}
                    value={cardState.delivery}>
                    <FormControlLabel
                      value="0"
                      key={1}
                      control={<Radio color="primary" icon={<CheckboxOutlineBlank />} checkedIcon={<Checkbox />} />}
                      label={t('cardDeliveryPersonal')}
                    />
                    <FormControlLabel
                      value="1"
                      key={2}
                      control={<Radio color="primary" icon={<CheckboxOutlineBlank />} checkedIcon={<Checkbox />} />}
                      label={t('cardDeliveryPost')}
                    />
                    <FormControlLabel
                      value="2"
                      key={3}
                      control={<Radio color="primary" icon={<CheckboxOutlineBlank />} checkedIcon={<Checkbox />} />}
                      label={t('cardDeliveryGLS')}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
            {/* A levelezési cím gomb csak akkor aktív ha levelezési cím meg van adva.
              Alapértelmezett választás az állandó lakcím, ekkor az addressee-ben üres
              érték az address-ben a lakcím adatok került letárolásra.
              Levelezési cím választásakor szintén üres az addresse és az adress a levelezési címet tartalmazza.
              Az alábbi cím választásakor kötelező címzett és cím rögzítés, ekkor az addresse a megadott címzett,
              az adress-ben a megadott cím kerül letárolásra.
              get-4 adatok alapján megjelenítés: ha az addresse nincs kitöltve,
              akkor az állandó lakcím kerül kijelölésre,
              különben az alábbi címre. */}
            <Grid item xs={12}>
              <Box display={cardState.receipt !== 'c' && (cardState.delivery === '1' || cardState.delivery === '2') ? 'inline' : 'none'}>
                <FormControl fullWidth>
                  <InputWrapper inputStyle="Main" label={t('label.deliveryAddress')}>
                    <Select
                      id="deliveryAddress"
                      name="deliveryAddress"
                      variant="outlined"
                      margin="dense"
                      value={deliveryAddress}
                      onChange={handleOnPostAddressChange}>
                      {deliveryAddressMenuItems}
                    </Select>
                  </InputWrapper>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={deliveryAddress === 3 && cardState.delivery > 0 ? 'inline' : 'none'}>
                <InputWrapper inputStyle="Main" label={t('addressee')}>
                  <TextField
                    fullWidth
                    id="addressee"
                    name="addressee"
                    variant="outlined"
                    margin="dense"
                    value={cardState.addressee}
                    onChange={handleOnContractChange}
                    validators={deliveryAddress === 3 ? ['required'] : []}
                  />
                </InputWrapper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={deliveryAddress === 3 && cardState.delivery > 0 ? 'inline' : 'none'}>
                <Address
                  required={deliveryAddress === 3 && cardState.delivery > 0}
                  addressType="delivery"
                  address={address}
                  cityList={cityList}
                  handleOnAddressChange={handleOnAddressChange}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  errorList={errorList}
                  namePrefix=""
                />
              </Box>
            </Grid>
            <Grid container justify="flex-end" style={{ marginTop: '30px' }}>
              <Button variant="outlined" color="secondary" onClick={prevStep}>
                {t('back')}
              </Button>
              <Button type="submit" variant="outlined" color="primary" style={{ marginLeft: '10px' }}>
                {t('next')}
              </Button>
            </Grid>
          </Grid>
        </Typography>
      </form>
    </>
  );
};

CardData.propTypes = {
  classes: PropTypes.shape(),
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  targetStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  jumpToStep: PropTypes.func.isRequired,
  resetTargetStep: PropTypes.func.isRequired,
};

CardData.defaultProps = {
  classes: {},
};

export default withStyles(styles)(CardData);
