import { createStyles } from '@material-ui/core';
import Image from '../assets/images/boxolos_csaj_hatter.jpg';
import UpTriangle from '../assets/images/fel_csomag.png';
import DownTriangle from '../assets/images/le_csomag.png';
import FB from '../assets/images/socialicons/face.jpg';
import FB_gold from '../assets/images/socialicons/face_zold.jpg';
import Insta from '../assets/images/socialicons/insta.jpg';
import Insta_gold from '../assets/images/socialicons/insta_zold.jpg';
import Youtube from '../assets/images/socialicons/youtube.jpg';
import Youtube_gold from '../assets/images/socialicons/youtube_zold.jpg';
import LinkedIn from '../assets/images/socialicons/linkedin.jpg';
import LinkedIn_gold from '../assets/images/socialicons/linkedin_zold.jpg';
import Contact from '../assets/images/socialicons/aycm.jpg';
import Contact_gold from '../assets/images/socialicons/aycm_zold.jpg';
import WarningLine from '../assets/images/figyelmezteto_csik_zold.png';
import OfferBox from '../assets/images/csomag_box_zold.png';
import { theme } from './theme';

// eslint-disable-next-line import/prefer-default-export
export const styles = () =>
  createStyles({
    '@global': {
      '*': {
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'rgba(161,161,161,0.5)',
          borderRadius: theme.spacing(1),
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.primary.main,
          borderRadius: theme.spacing(1),
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
        '&::-webkit-scrollbar-corner': {
          backgroundColor: 'transparent',
          display: 'none',
        },
      },
    },
    root: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0',
      width: '100%',
      boxSizing: 'border-box',
    },
    headerLogoHasUser: {
      textAlign: 'center',
      height: '100%',
      /* paddingTop: '12.5px', */
      '& img': {
        width: '166px',
        height: 'auto',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
          width: '145px',
        },
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    headerLogoHasNoUser: {
      textAlign: 'center',
      paddingTop: '30px',
      '& img': {
        width: '145px',
        height: 'auto',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
          position: 'absolute',
          top: '66px',
          left: '20px',
        },
      },
      '& img:first-child': {
        width: '130px',
        /* paddingLeft: '32px', */
        [theme.breakpoints.down('sm')]: {
          top: '20px',
        },
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: '32px',
      },
    },
    mainViewNoUser: {
      border: `2px solid ${theme.palette.primary.main}`,
      height: '650px',
      width: '100%',
      maxWidth: '1200px',
      background: `url(${Image})`,
      backgroundSize: 'cover',
      margin: 'auto',
      padding: '20px',
      boxShadow: '0 0 1em rgba(161,161,161.25)',
      borderRadius: '5px',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        height: '100%',
        width: '100vw',
        margin: '0',
      },
    },
    mainViewHasUser: {
      height: '650px',
      width: '100%',
      maxWidth: '1200px',
      backgroundColor: 'rgba(239,239,239,1)',
      margin: 'auto',
      padding: '20px',
      boxShadow: '0 0 1em rgba(161,161,161.25)',
      borderRadius: '5px',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        height: '99.9%',
        width: '100vw',
        margin: '0',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
    },
    loggedInMainBox: {
      overflowY: 'auto',
      height: '520px',
      backgroundColor: 'white',
      overflow: 'scroll',
      overflowX: 'hidden',
      borderRadius: '5px',
    },
    menu: {
      backgroundColor: 'white',
      marginRight: '12.5px',
      borderRadius: '5px',
      paddingTop: '16px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    inputLabel: {
      display: 'inline-block',
      width: '100%',
      color: 'rgba(161, 161, 161)',
      padding: '10.5px 0px 10.5px 24px',
      fontSize: '13px',
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: '-32px',
        left: '-16px',
        zIndex: '100',
        fontSize: '11px',
      },
    },
    inputWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      width: '100%',
      padding: '0',
      borderRadius: '5px',
      boxShadow: '0 0 10px rgba(161,161,161,0.5)',
      margin: '0 auto',
      marginBottom: '8px',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '32px',
        marginTop: '8px',
      },
    },
    inputWrapperPayment: {
      border: `2px solid ${theme.palette.primary.main}`,
      maxWidth: '185px',
      margin: '0',
      '& .MuiOutlinedInput-input': {
        fontSize: '30px',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
          fontSize: '22px',
        },
      },
      '& MuiTextField-root': {
        height: '46px',
      },
    },
    inputWrapperErrorPayment: {
      border: `2px solid ${theme.palette.primary.main}`,
      maxWidth: '185px',
      margin: '0',
      backgroundColor: '#ffd9d9',
      '& .MuiOutlinedInput-input': {
        fontSize: '30px',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
          fontSize: '22px',
        },
      },
      '& MuiTextField-root': {
        height: '46px',
      },
    },
    inputWrapperMain: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    inputWrapperMulti: {
      border: `2px solid #ea008a`,
    },
    inputWrapperError: {
      backgroundColor: '#ffd9d9',
      border: `2px solid ${theme.palette.primary.main}`,
    },
    inputWrapperSecondary: {
      border: '2px solid rgba(161,161,161,1)',
    },
    inputWrapperSelectYear: {
      border: '2px solid black',
    },
    loginButton: {
      paddingTop: theme.spacing(7),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(2),
      },
    },
    loginHeading: {
      fontSize: theme.spacing(3),
      fontWeight: '700',
      margin: '0 auto',
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
      },
    },
    headerUser: {
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '& h2': {
        fontSize: '24px',
        fontWeight: '700',
      },
    },
    headerParams: {
      fontSize: '12px',
      marginRight: '4px',
      color: 'rgba(161,161,161,1)',
    },
    registerButton: {
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '0',
      },
    },
    registerHeading: {
      fontSize: theme.spacing(3),
      margin: '0',
      fontWeight: '700',
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
      },
    },
    languageTogglerWrap: {
      position: 'absolute',
      maxWidth: '125px',
      display: 'flex',
      top: '20px',
      right: '20px',
      height: '30px',
      zIndex: '100',
      [theme.breakpoints.down('sm')]: {
        // left: '240px',
        right: '100px',
        top: '32px',
      },
      [theme.breakpoints.down('xs')]: {
        // left: '180px',
        right: '75px',
        top: '10px',
        maxWidth: '75px',
      },
    },
    languageTogglerButtonNotActive: {
      fontSize: '13px',
      padding: '0',
      border: 'none',
      backgroundColor: 'white',
      '& .MuiButton-label': {
        height: '100%',
        color: 'black',
      },
      '&:hover': {
        backgroundColor: 'white',
        '& .MuiButton-label': {
          color: theme.palette.primary.main,
        },
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '37.5px',
      },
    },
    languageTogglerButtonActive: {
      fontSize: '13px',
      padding: '0',
      border: 'none',
      backgroundColor: theme.palette.primary.main,
      '& .MuiButton-label': {
        height: '100%',
        color: 'white',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiButton-label': {
          color: '#000',
        },
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '37.5px',
      },
    },
    registerPageContainer: {
      overflow: 'scroll',
      overflowY: 'auto',
      overflowX: 'hidden',
      height: '400px',
      [theme.breakpoints.down('md')]: {
        height: '60vh',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '30px',
      },
    },
    warningField: {
      fontSize: '13px',
      fontWeight: '400',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      '& .warningSign': {
        textAlign: 'center',
      },
      '& .warningSign img': {
        width: '75px',
        height: 'auto',
      },
      '& .warningText': {
        textAlign: 'left',
        marginLeft: '16px',
        [theme.breakpoints.down('sm')]: {
          marginLeft: '0px',
        },
      },
    },
    contractTitle: {
      fontSize: '24px',
      fontWeight: '700',
    },
    subHeading: {
      fontWeight: '700',
      fontSize: '16px',
      color: theme.palette.primary.main,
      marginTop: '8px',
      marginBottom: '8px',
    },
    finalCheck: {
      borderRadius: '5px',
      boxShadow: '0 0 10px rgba(161,161,161,0.5)',
      width: '100%',
      padding: '8px 8px 8px 24px',
      marginTop: '8px',
      marginBottom: '8px',
      '& label': {
        marginRight: '0',
        marginTop: '-1px',
      },
    },
    checkAll: {
      width: '100%',
      padding: '8px 8px 8px 24px',
      marginTop: '8px',
      marginBottom: '8px',
      '& label': {
        marginRight: '0',
        marginTop: '-1px',
      },
    },
    primaryMenuItem: {
      padding: '6px 0 6px 40px',
    },
    primaryMenuItemMobile: {
      paddingLeft: '32px',
    },
    withoutIconMenuItem: {
      padding: '6px 0 6px 40px',
    },
    mobileMenuItem: {
      paddingLeft: '32px',
    },
    expandMenuItem: {
      '& .MuiTypography-body1': {
        fontWeight: '700',
      },
    },
    offersBox: {
      width: '100%',
      height: '95%',
    },
    offerTriangleBox: {
      maxWidth: '90%',
      margin: '0 auto',
    },
    offerZold: {
      background: `url(${OfferBox})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '120px',
      margin: '0 auto',
      width: 'auto',
      paddingTop: '8px',
      [theme.breakpoints.down('md')]: {
        height: '90px',
        width: 'auto',
        fontSize: '8px',
        paddingTop: '27px',
      },
      [theme.breakpoints.down('sm')]: {
        height: '80px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '20px',
        paddingTop: '14px',
      },
    },
    offerUpTriangle: {
      background: `url(${UpTriangle})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '120px',
      margin: '0 auto',
      width: 'auto',
      paddingTop: '8px',
      [theme.breakpoints.down('md')]: {
        height: '90px',
        width: 'auto',
        fontSize: '8px',
        paddingTop: '27px',
      },
      [theme.breakpoints.down('sm')]: {
        height: '80px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '20px',
        paddingTop: '14px',
      },
    },
    offerDownTriangle: {
      background: `url(${DownTriangle})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '120px',
      width: 'auto',
      paddingTop: '3.5px',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        height: '90px',
        width: 'auto',
        fontSize: '8px',
        paddingTop: '27px',
      },
      [theme.breakpoints.down('sm')]: {
        height: '80px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '46px',
        paddingTop: '0px',
      },
    },
    offerDownPrice: {
      position: 'relative',
      top: '-100px',
      fontWeight: '700',
      fontSize: '12px',
      width: '80px',
      height: '40px',
      left: '20px',
      background: 'white',
      textAlign: 'center',
      paddingTop: '10px',
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
        width: '62px',
        marginTop: '40px',
        paddingTop: '8px',
        height: '36px',
        left: '17px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '9px',
        left: '11px',
        width: '56px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '8px',
        marginTop: '56px',
        left: '0',
      },
    },
    offerUpPrice: {
      fontWeight: '700',
      background: 'white',
      margin: '0 auto',
      paddingTop: '14px',
      verticalAlign: 'center',
      textAlign: 'center',
      fontSize: '12px',
      width: '80px',
      height: '40px',
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
        width: '60px',
        height: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '9px',
        paddingTop: '6px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '8px',
        height: '10px',
        paddingTop: '0',
        margin: '16px auto',
        width: 'auto',
      },
    },
    offerPrice: {
      margin: '0 auto',
      paddingTop: '14px',
      verticalAlign: 'middle',
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '700',
      width: '80px',
      height: '40px',
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
        width: '60px',
        height: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '9px',
        paddingTop: '6px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '8px',
        height: '10px',
        paddingTop: '0',
        margin: '16px auto',
        width: 'auto',
      },
    },
    offerPack: {
      marginTop: '32px',
      fontFamily: 'Montserrat',
      fontSize: '26px',
      fontWeight: '700',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        marginTop: '8px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        marginTop: '4px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '13px',
        margin: '0 auto',
        marginTop: '16px',
        width: '30.5px',
      },
    },
    offerUpPack: {
      marginTop: '32px',
      marginBottom: '0px',
      background: 'white',
      fontWeight: '700',
      fontSize: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        marginTop: '8px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        marginTop: '4px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '13px',
        margin: '0 auto',
        marginTop: '16px',
        width: '30.5px',
      },
    },
    offerDownPack: {
      fontWeight: '700',
      zIndex: '100',
      position: 'relative',
      background: 'white',
      marginTop: '34px',
      fontSize: '26px',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        marginTop: '8px',
        marginBottom: '0',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        marginTop: '4px',
      },
    },
    offerButton: {
      position: 'absolute',
      bottom: '16px',
      right: 0,
      [theme.breakpoints.down('sm')]: {
        bottom: '0',
      },
    },
    amountInput: {
      '& #amount-helper-text': {
        width: '250%',
        marginBottom: '4px',
        bottom: '-20px',
      },
    },
    paymentBox: {
      height: '520px',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
      },
    },
    paymentBoxGold: {
      padding: '32px 32px 0px 32px',
      border: '2px solid rgba(49, 139, 129, 1)',
      minHeight: '520px',
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      [theme.breakpoints.down('sm')]: {
        borderBottomLeftRadius: '0',
        borderTopRightRadius: '5px',
      },
    },
    paymentBoxGrey: {
      padding: '32px',
      border: '2px solid rgba(161, 161, 161, 1)',
      borderLeft: 'none',
      minHeight: '520px',
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      [theme.breakpoints.down('xs')]: {
        borderTopRightRadius: '0',
        borderBottomLeftRadius: '5px',
        borderLeft: '2px solid rgba(161,161,161,1)',
        borderTop: 'none',
      },
    },
    downloadWrapper: {
      border: '2px solid rgba(161,161,161, 1)',
      margin: '11px 0',
      borderRadius: '5px',
      boxShadow: '0 0 10px rgba(161,161,161,0.5)',
      '& button': {
        height: '100%',
        padding: '0',
        fontSize: '14px',
        '& .MuiButton-label': {
          height: '36px',
        },
      },
    },
    download2Wrapper: {
      border: '2px solid rgba(49, 139, 129, 1)',
      margin: '11px 0',
      borderRadius: '5px',
      boxShadow: '0 0 10px rgba(161,161,161,0.5)',
      '& button': {
        height: '100%',
        padding: '0',
        fontSize: '14px',
        '& .MuiButton-label': {
          height: '36px',
        },
      },
    },
    downloadWrapperText: {
      marginTop: '8px',
      marginBottom: '8px',
      fontWeight: '700',
      color: 'rgba(161,161,161,1)',
      textAlign: 'left',
      paddingLeft: '24px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '8px',
        textAlign: 'center',
      },
    },
    finalStepText: {
      marginTop: '24px',
      '& h6': {
        marginBottom: '8px',
        marginTop: '16px',
        textAlign: 'left',
        fontWeight: '700',
        fontSize: '14px',
      },
      '& p': {
        fontWeight: '500',
        fontSize: '14px',
      },
    },
    socialIconFb: {
      background: `url(${FB})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '37px',
      width: '37px',
      '&:hover': {
        background: `url(${FB_gold})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      },
    },
    socialIconInsta: {
      background: `url(${Insta})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '37px',
      width: '40px',
      '&:hover': {
        background: `url(${Insta_gold})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      },
    },
    socialIconYoutube: {
      background: `url(${Youtube})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '37px',
      width: '100px',
      '&:hover': {
        background: `url(${Youtube_gold})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      },
    },
    socialIconLinkedIn: {
      background: `url(${LinkedIn})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '37px',
      width: '52px',
      '&:hover': {
        background: `url(${LinkedIn_gold})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      },
    },
    socialIconContact: {
      background: `url(${Contact})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '37px',
      width: '126px',
      '&:hover': {
        background: `url(${Contact_gold})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      },
    },
    leftButton: {
      textAlign: 'right',
      marginBottom: '8px',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        marginRight: '0',
      },
    },
    rightButton: {
      marginBottom: '8px',
      marginLeft: '15px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        marginLeft: '0',
      },
    },
    bottomButton: {
      position: 'relative',
      bottom: '-210px',
      [theme.breakpoints.down('sm')]: {
        bottom: '-140px',
      },
    },
    bottomContractButton: {
      position: 'relative',
      bottom: '-175px',
      [theme.breakpoints.down('md')]: {
        bottom: '-160px',
      },
      [theme.breakpoints.down('sm')]: {
        bottom: '-100px',
      },
      [theme.breakpoints.down('xs')]: {
        bottom: '-8px',
      },
    },
    headerUserData: {
      paddingLeft: '16px',
      [theme.breakpoints.down('sm')]: {
        padding: '16px',
      },
    },
    snackSuccess: {
      backgroundColor: 'rgb(177, 230, 141)',
      color: 'black',
    },
    snackError: {
      backgroundColor: 'rgb(239, 103, 103)',
      color: 'black',
    },
    popup: {
      width: '600px',
      height: '180px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    popupMD: {
      width: '858px',
      height: '500px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    popupBottom: {
      zIndex: '100',
      background: `url(${WarningLine}) left center white`,
      height: '15px',
      width: '100%',
      position: 'absolute',
      bottom: '0px',
      left: '-10px',
    },
    priceAdornment: {
      position: 'relative',
      left: '-65%',
      [theme.breakpoints.down('sm')]: {
        left: '-75%',
      },
      [theme.breakpoints.down('xs')]: {
        left: '-50%',
      },
    },
    cherriskPopup: {
      padding: 0,
      height: '400px',
      width: '400px',
      [theme.breakpoints.down('sm')]: {
        width: '300px',
        height: '300px',
      },
    },
    cherriskPopupButton: {
      position: 'absolute',
      width: '260px',
      height: '50px',
      zIndex: '100',
      bottom: '15px',
      left: '70px',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        width: '198px',
        height: '36px',
        bottom: '12px',
        left: '52px',
      },
    },
  });
