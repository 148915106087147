import React, { useState, useEffect } from 'react';
import { Grid, withStyles, FormControl, RadioGroup, FormControlLabel, Radio, Button } from '@material-ui/core';

import { withTranslation } from 'react-i18next';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withSnackbar } from 'notistack';
import { styles } from '../theme/styles';
import InputWrapper from '../components/ui/InputWrapper';
import ReCaptchaComponent from '../components/ui/ReCaptchaComponent';
import { commonService } from '../services/common';
import Success from '../assets/images/haromszog_pipa_zold.png';
import useParams from '../utils/useParams';
// import Warning from '../assets/images/figyelmezteto_tabla_zold.png';

const Unsubscribe = (props) => {
  const [params, setParams] = useState({ email: '', reason: 'a' });
  const [captchaNotValid, setCaptchaNotValid] = useState(true);
  const [success, setSuccess] = useState(false);

  const { email } = useParams('/unsubscribe/:email');

  const { classes, t } = props;

  useEffect(() => {
    setParams({
      email,
      reason: 'a',
    });
  }, [email]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setParams({
      ...params,
      [name]: value,
    });
  };

  const captchaValidate = (captchaToken) => {
    if (captchaToken) {
      setCaptchaNotValid(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await commonService.unsubscribe(params);
      if (response.data.status === 'OK') {
        setSuccess(true);
      } else {
        response.data.messages.map((message) =>
          props.enqueueSnackbar(t(message), {
            variant: 'error',
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return success ? (
    <>
      <Grid
        container
        className={classes.warningField}
        alignItems="center"
        justify="center"
        style={{
          maxWidth: '650px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
        <Grid item xs={12}>
          <p className={classes.loginHeading} style={{ textAlign: 'center', marginBottom: '32px' }}>
            {t('newsLetter.unsubscribe')}
          </p>
        </Grid>
        <Grid item xs={12} sm="auto" className="warningSign">
          <img src={Success} alt="" />
        </Grid>
        <Grid item xs={12} sm className="warningText">
          <p
            style={{
              textTransform: 'uppercase',
              fontWeight: '700',
              fontSize: '16px',
            }}>
            {t('newsLetter.unsubscribeSuccess')}
          </p>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <ValidatorForm onSubmit={handleSubmit} onError={(errors) => console.error(errors)}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <p className={classes.loginHeading} style={{ textAlign: 'center' }}>
              {t('newsLetter.unsubscribe')}
            </p>
          </Grid>
          <Grid item xs={12}>
            <Grid container style={{ maxWidth: '550px', margin: '0 auto' }}>
              <Grid item xs={12}>
                <InputWrapper inputStyle="Main" label={t('email')}>
                  <TextValidator
                    fullWidth
                    id="email"
                    name="email"
                    variant="outlined"
                    margin="dense"
                    value={params.email}
                    onChange={handleChange}
                    validators={['required', 'isEmail']}
                    errorMessages={[t('validate_require'), t('validate_email')]}
                    disabled
                  />
                </InputWrapper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '16px' }}>
            <ReCaptchaComponent captchaValidate={captchaValidate} />
          </Grid>
          <Grid item xs={12}>
            <p className={classes.loginHeading} style={{ textAlign: 'center' }}>
              {t('newsLetter.unsubscribeReasonHeading')}
            </p>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label={t('newsLetter.unsubscribeReasonHeading')}
                id="reason"
                name="reason"
                onChange={handleChange}
                value={params.reason}>
                <FormControlLabel value="a" control={<Radio color="primary" />} label={t('newsLetter.unsubscribeReason1')} />
                <FormControlLabel value="c" control={<Radio color="primary" />} label={t('newsLetter.unsubscribeReason2')} />
                <FormControlLabel value="b" control={<Radio color="primary" />} label={t('newsLetter.unsubscribeReason3')} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center', paddingTop: '40px' }}>
            <Button disabled={captchaNotValid === true} variant="outlined" color="primary" type="submit">
              {t('unsubscribe')}
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  );
};

export default withTranslation()(withStyles(styles)(withSnackbar(Unsubscribe)));
