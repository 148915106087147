import React, { useState, useEffect } from 'react';
import { Grid, withStyles, Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { authService } from '../services/authentication';
import { styles } from '../theme/styles';
import InputWrapper from '../components/ui/InputWrapper';
import ReCaptchaComponent from '../components/ui/ReCaptchaComponent';
import useValidator from '../utils/useValidator';

const ForgottenDetails = props => {
  const [state, setState] = useState({
    email: '',
    errorMessages: [],
    showError: false,
    captchaNotValid: true
  });

  const { setErrors, removeErrors, resetErrors, hasErrors, setFields, didFillEverything, giveErrors } = useValidator();

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const { classes } = props;

  useEffect(() => {
    setFields(['email']);
    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = event => {
    setState({
      ...state,
      [[event.target.name]]: event.target.value
    });
  };

  const captchaValidate = captchaToken => {
    if (captchaToken) {
      setState({
        ...state,
        captchaNotValid: false
      });
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (didFillEverything({ email: state.email }).length === 0) {
      try {
        const response = await authService.forgotPassword(state.email);
        if (response.data.status === 'OK') {
          enqueueSnackbar(t('emailSent'), {
            variant: 'success'
          });
        } else {
          response.data.messages.map(message => {
            const key = enqueueSnackbar(t(message), {
              variant: 'error'
            });

            if (message === 'ACC_ACTIVE_USER') {
              giveErrors('email', key);
            }

            return key;
          });
        }
      } catch (error) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
    }
  };

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
  };

  const handleFocus = e => {
    removeErrors(e);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <p className={classes.loginHeading}>{t('forgottenPassword')}</p>
        </Grid>
        <Grid item xs={12} className={classes.registerPageContainer}>
          <Grid container alignItems="flex-start" style={{ maxWidth: '550px', margin: '0 auto', height: '100%' }}>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit} onError={errors => console.error(errors)} style={{ height: '100%' }}>
                <Grid container style={{ height: '100%' }} alignItems="flex-start">
                  <Grid item xs={12}>
                    <InputWrapper inputStyle={hasErrors('email') ? 'Error' : 'Main'} label={t('privateEmail')}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        id="email"
                        name="email"
                        value={state.email}
                        onChange={handleChange}
                        onBlur={e => handleBlur(e, ['required', 'isEmail'])}
                        onFocus={handleFocus}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <ReCaptchaComponent captchaValidate={captchaValidate} />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '200px' }}>
                    <Grid container justify="center" alignItems="flex-end">
                      <Grid item xs={12} sm={5} className={classes.leftButton}>
                        <Button variant="outlined" color="secondary" type="button" onClick={() => history.push('/')}>
                          {t('back')}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={5} className={classes.rightButton}>
                        <Button
                          disabled={state.captchaNotValid === true}
                          variant="outlined"
                          color="primary"
                          type="submit"
                          style={{ padding: '6px 0' }}>
                          {t('forgottenPasswordSubmit')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ForgottenDetails.propTypes = {
  classes: PropTypes.shape()
};

ForgottenDetails.defaultProps = {
  classes: {}
};

export default withStyles(styles)(ForgottenDetails);
