import React, { useState, useEffect } from 'react';
import {
  Typography, Grid, Button, Box, withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import InputWrapper from '../components/ui/InputWrapper';
import { authService } from '../services/authentication';
import { styles } from '../theme/styles';
import useValidator from '../utils/useValidator';

const ChPass = (props) => {
  const [values, setValues] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordAgain: '',
  });

  const { classes } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    setErrors,
    removeErrors,
    resetErrors,
    setFields,
    didFillEverything,
    giveErrors,
    hasErrors,
  } = useValidator();

  useEffect(() => {
    setFields(['oldPassword', 'newPassword', 'newPasswordAgain']);

    return () => resetErrors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordsMatch', (value) => {
      if (
        values.newPassword === values.newPasswordAgain
        || values.newPasswordAgain.length === 0
      ) {
        return true;
      }
      return false;
    });

    return () => {
      ValidatorForm.removeValidationRule('isPasswordsMatch');
    };
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toCheck = { ...values };
    if (didFillEverything(toCheck).length === 0) {
      try {
        const { oldPassword, newPassword } = values;
        const response = await authService.changePassword(
          oldPassword,
          newPassword,
        );
        setValues({
          oldPassword: '',
          newPassword: '',
          newPasswordAgain: '',
        });
        if (response.data.status === 'OK') {
          enqueueSnackbar(t('passwordChanged'), {
            variant: 'success',
          });
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (error) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    } else {
      const errors = didFillEverything(toCheck);
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error',
      });
      errors.map((field) => giveErrors(field, key));
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
  };

  const handleFocus = (e) => {
    removeErrors(e);
  };

  return (
    <>
      <Grid container style={{ padding: '24px 32px' }}>
        <Grid item xs={12} style={{ paddingTop: '4px', marginBottom: '16px' }}>
          <Typography component="h6" variant="subtitle2">
            <span style={{ fontWeight: '700', fontSize: '14px' }}>{t('menu.profil.chpass')}</span>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <ValidatorForm
            onSubmit={handleSubmit}
            onError={(errors) => console.error(errors)}
          >
            <Box>
              <InputWrapper inputStyle={hasErrors('oldPassword') ? 'Error' : 'Main'} label={t('currentPassword')}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  type="password"
                  id="oldPassword"
                  name="oldPassword"
                  value={values.oldPassword}
                  onChange={handleChange}
                  onBlur={(e) => handleBlur(e, ['required'])}
                  onFocus={handleFocus}
                />
              </InputWrapper>
              <InputWrapper inputStyle={hasErrors('newPassword') ? 'Error' : 'Main'} label={t('newPassword')}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={(e) => handleBlur(e, ['required', 'min5', 'isPasswordsMatch'], values.newPasswordAgain)}
                  onFocus={handleFocus}
                />
              </InputWrapper>
              <InputWrapper inputStyle={hasErrors('newPasswordAgain') ? 'Error' : 'Main'} label={t('newPasswordAgain')}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  type="password"
                  id="newPasswordAgain"
                  name="newPasswordAgain"
                  value={values.newPasswordAgain}
                  onChange={handleChange}
                  onBlur={(e) => handleBlur(e, ['required', 'min5', 'isPasswordsMatch'], values.newPassword)}
                  onFocus={handleFocus}
                />
              </InputWrapper>
            </Box>
            <Button variant="outlined" color="primary" type="submit" className={classes.bottomButton}>
              {t('saveButton')}
            </Button>
          </ValidatorForm>
        </Grid>
      </Grid>
    </>
  );
};

ChPass.propTypes = {
  classes: PropTypes.shape(),
  location: PropTypes.shape().isRequired,
};

ChPass.defaultProps = {
  classes: {},
};

export default (withStyles(styles)(ChPass));
