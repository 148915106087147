import React from 'react';
import {
  Grid, Typography, Box, withStyles, Link, Button,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { styles } from '../../theme/styles';

const ContractSumDetails = (props) => {
  const { classes, t } = props;
  return (
    <>
      <Grid item xs={12} className={classes.finalStepText}>
        <Box variant="div">
          <Typography variant="body1">
            {t('epassInfo1')}
          </Typography>
          <Grid item xs={12}>
            <Box
              className={classes.download2Wrapper}
            >
              <Grid container justify="space-between">
                <Grid item xs style={{ textAlign: 'center' }}>
                  <p
                    className={classes.downloadWrapperText}
                    style={{ color: 'black', fontWeight: '700' }}
                  >
                    {t('epassInfo')}
                  </p>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                  <Link
                    href="https://allyoucanmove.hu/content_files/ePASS_tajekoztato.pdf"
                    target="_blank"
                    underline="none"
                  >
                    <Button>{t('download')}</Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};
export default withTranslation()(withStyles(styles)(ContractSumDetails));
