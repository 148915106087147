import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { authService } from '../services/authentication';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!sessionStorage.token) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }
      const expired = authService.isTokenExpired();
      const { pathname } = props.location;
      if (expired && pathname !== '/login') {
        authService.logout();
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }
      // authorised so return component
      return <Component {...props} />;
    }}
  />
);
