import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:8080'}/intranet/api/protected/intranet/v1`;

const config = {
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
};

function addJWT() {
  config.withCredentials = true;
  config.headers.Authorization = `Bearer ${sessionStorage.token}`;
}

// 2021-05-12 pjung TNRUKAYCM-1099 Upgrade-ként indul, de normál lesz belőle
async function getIntraPackages(token, cType) {
  config.type = cType === 'U' ? 1 : 0;
  addJWT();
  return axios.post(`${baseUrl}/prices`, token, config);
}

async function hasMessage() {
  addJWT();
  return axios.get(`${baseUrl}/hasMessage`, config);
}

async function closeMessage(id) {
  addJWT();
  return axios.post('/closeMessage', { id }, config);
}

// eslint-disable-next-line import/prefer-default-export
export const intraService = {
  getIntraPackages,
  hasMessage,
  closeMessage,
};
