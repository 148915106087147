import React from 'react';
import { withStyles, Box, Grid } from '@material-ui/core';
import { styles } from '../../theme/styles';

const SocialIcons = (props) => {
  const { classes } = props;
  console.log(props);
  const handleClick = (route) => {
    switch (route) {
      case 'facebook':
        window.open('https://www.facebook.com/allyoucanmove.hu', '_blank');
        break;
      case 'instagram':
        window.open('https://www.instagram.com/allyoucanmove/', '_blank');
        break;
      case 'youtube':
        window.open('https://www.youtube.com/channel/UCCfaAgZ6Br0a9m5cLMcytqw', '_blank');
        break;
      case 'linkedin':
        window.open('https://www.linkedin.com/company/all-you-can-move-sportpass', '_blank');
        break;
      case 'contact':
        window.open('https://allyoucanmove.hu/kapcsolat', '_blank');
        break;
      default:
        return;
    }
  };
  return (
    <>
      <Grid container>
        <Grid item xs>
          <Box className={classes.socialIconFb} onClick={() => handleClick('facebook')} />
        </Grid>
        <Grid item xs>
          <Box className={classes.socialIconInsta} onClick={() => handleClick('instagram')} />
        </Grid>
        <Grid item xs>
          <Box className={classes.socialIconLinkedIn} onClick={() => handleClick('linkedin')} />
        </Grid>
        <Grid item xs>
          <Box className={classes.socialIconYoutube} onClick={() => handleClick('youtube')} />
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs style={{ margin: '1em' }}>
          <Box className={classes.socialIconContact} onClick={() => handleClick('contact')} />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(SocialIcons);
