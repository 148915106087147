import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import useValidator from '../../utils/useValidator';
import { authService } from '../../services/authentication';
import Address from './Address';
import { contractService } from '../../services/contracting';

const ChangeAddress = ({ currentAddress }) => {
  const [userAddress, setUserAddress] = useState({
    zip: '',
    city: '',
    street: '',
    streetType: '',
    house: '',
    staircase: '',
    floor: '',
    door: '',
  });
  const [cityList, setCityList] = useState([]);

  const {
    setErrors, removeErrors, didFillEverything, setFields, resetErrors, giveErrors, requiredGotFilled, errorList,
  } = useValidator();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFields(['zip', 'city', 'street', 'streetType', 'house']);

    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.entries(currentAddress).length !== 0) {
      setUserAddress({ ...currentAddress });
      setCityList([currentAddress.city]);
    }
  }, [currentAddress]);

  const handleFocus = (e) => {
    removeErrors(e);
  };

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
  };

  const listCitiesByZip = async (zip) => {
    let result = '';
    try {
      const response = await contractService.listCitiesByZip(zip);
      if (response.data.status === 'OK') {
        result = response.data.list.map((city) => city.name);
      } else {
        response.data.messages.map((message) => enqueueSnackbar(t(message), {
          variant: 'error',
        }));
      }
    } catch (e) {
      enqueueSnackbar(t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
    return result;
  };

  const handleOnAddressChange = async (event) => {
    requiredGotFilled(event);
    const { name, value } = event.target;
    const addressCopy = JSON.parse(JSON.stringify(userAddress));
    addressCopy[name] = value;

    if (name === 'zip' && value.length === 4) {
      const cityListCopy = await listCitiesByZip(value);
      addressCopy.city = addressCopy.city
        ? addressCopy.city
        : cityListCopy[0];
      setCityList(cityListCopy);
    } else if (name === 'zip' && value.length < 4) {
      addressCopy.city = '';
      setCityList([]);
    }
    setUserAddress(addressCopy);
  };

  const handleAddressSubmit = async () => {
    if (didFillEverything(userAddress).length === 0) {
      try {
        const response = await authService.changeAddress(userAddress);
        if (response.data.status === 'OK') {
          enqueueSnackbar(t('profileChangeSuccess'), {
            variant: 'success',
          });
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    } else {
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error',
        // persist: true,
      });
      didFillEverything(userAddress).map((input) => giveErrors(input, key));
    }
  };

  return (
    <>
      <Box style={{ marginRight: 'auto', marginBottom: '8px' }}>
        <Typography variant="subtitle2" component="h3">
          {t('mailAddress')}
        </Typography>
      </Box>
      <Grid item xs={12}>
        <Address
          required
          addressType="home"
          address={userAddress}
          cityList={cityList}
          handleOnAddressChange={handleOnAddressChange}
          namePrefix=""
          handleBlur={handleBlur}
          handleFocus={handleFocus}
          errorList={errorList}
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button
          variant="outlined"
          color="primary"
          type="button"
          onClick={handleAddressSubmit}
          disabled={cityList.length < 1}
        >
          {t('saveButton')}
        </Button>
      </Grid>
    </>
  );
};

ChangeAddress.propTypes = {
  currentAddress: PropTypes.shape({
    zip: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    streetType: PropTypes.string,
    house: PropTypes.string,
    staircase: PropTypes.string,
    floor: PropTypes.string,
    door: PropTypes.string,
  }),
};

ChangeAddress.defaultProps = {
  currentAddress: {
    zip: '',
    city: '',
    street: '',
    streetType: '',
    house: '',
    staircase: '',
    floor: '',
    door: '',
  },
};

export default ChangeAddress;
