import { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import validatorRules from './validationRules';

const useValidator = () => {
  const [errorList, setErrorList] = useState({});
  const [requiredList, setRequiredList] = useState([]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  /**
   * Ez a fő validálás. Megnézi, hogy adott mező a beállított dolgoknak megfelelő
   * értékekkel rendelkezik-e. Ha nem, akkor az errors[] array-be kerül a mező neve,
   * onnan pedig snackbar értesítés, és errorList[]-be helyezés történik.
   */
  const setErrors = (event, rules, password) => {
    const { value, name } = event.target;
    const errors = rules
      .filter((rule) => validatorRules[rule.replace(/\d+/g, '')] && validatorRules[rule.replace(/\d+/g, '')](value, rule, password))
      .map((rule) => `validate_${rule}`);

    if (errors.length) {
      const key = enqueueSnackbar(t(errors[0].replace(/\d+/g, ''), { num: errors[0].replace(/\D/g, '') }), {
        variant: 'error',
        // persist: true,
      });
      return setErrorList({
        ...errorList,
        [name]: key || Math.random(),
      });
    }
    setErrorList({
      ...errorList,
      [name]: false,
    });

    return errorList;
  };

  /**
   * Hibás mezőbe kattintáskor levesszük az error-t az adott inputmezőről, ezáltal eltűnik
   * a snackbar és az input átszínezése
   */
  const removeErrors = (event) => {
    const { name } = event.target;

    if (errorList[name]) {
      closeSnackbar(errorList[name]);
      setErrorList({
        ...errorList,
        [name]: 0,
      });
    }
  };

  /**
   * Eltünteti a snackbarokat ha átnavigálunk másik oldalra
   */
  const resetErrors = () => {
    closeSnackbar();
    setErrorList({});
  };

  /**
   * true értéket ad vissza, ha adott inputmezőn van error
   */
  const hasErrors = (fieldName) => errorList && errorList[fieldName];

  /**
   * Backend validációs hibákat hozzáadjuk az errorlist-hez,
   * hogy be tudjuk színezni a hibás input mezőt
   */
  const giveErrors = (fieldName, key) => {
    setErrorList({
      ...errorList,
      [fieldName]: key || Math.random(),
    });
  };

  /**
   * A komponens mountolásakor inicializáljuk a mezőket, amiket szeretnénk,
   * ha kötelező lenne kitölteni.
   */
  const setFields = (array) => {
    setRequiredList(array);
    const obj = {};
    array.map((item) => (obj[item] = 0));
    setErrorList(obj);
  };

  /**
   * Kötelező mezőket "kitöltöttre" változtatni, amennyiben kapnak értéket
   */
  const requiredGotFilled = (event) => {
    const { name, value } = event.target;
    if (value && errorList[name]) {
      setErrorList({
        ...errorList,
        [name]: 0,
      });
    } else if (!value && errorList[name] === false) {
      setErrorList({
        ...errorList,
        [name]: 0,
      });
    }
  };

  /**
   * Azokat a mezőket adja vissza, amik kötelezőek, és rosszul-,
   * vagy egyáltalán nem lettek kitöltve.
   */
  const didFillEverything = (values) =>
    requiredList.filter((field) => errorList[field] !== false).filter((field) => !(values[field] && errorList[field] === 0));

  return {
    setErrors,
    removeErrors,
    errorList,
    resetErrors,
    hasErrors,
    setFields,
    didFillEverything,
    giveErrors,
    requiredGotFilled,
  };
};

export default useValidator;
