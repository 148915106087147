import React, { Component } from 'react';
import i18n from '../translation/i18n';

export const Context = React.createContext([]);

class Provider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: localStorage.getItem('i18nextLng'),

      changeLanguageToHu: () => {
        this.setState({ language: 'hu' });
        i18n.changeLanguage('hu');
      },

      changeLanguageToEn: () => {
        this.setState({ language: 'en' });
        i18n.changeLanguage('en');
      },
    };
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default Provider;
