import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { ValidatorForm } from 'react-material-ui-form-validator';
import enLocale from 'date-fns/locale/en-US';
import huLocale from 'date-fns/locale/hu';
import { styles } from '../theme/styles';
import { paymentService } from '../services/payment';
import { Grid, Typography, Box, Link, Button, withStyles } from '@material-ui/core';
import InputWrapper from '../components/ui/InputWrapper';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { formatDate } from '../utils/Utils';
import { API_URL } from '../utils/Consts';
import ErrorDialog from '../components/ui/ErrorDialog';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

const localeMap = {
  en: enLocale,
  hu: huLocale
};

const RegularCreditPage = props => {
  const [fromDate, setFromDate] = useState(new Date());
  const [docTransfer, setDocTransfer] = useState(null);
  const [dialog, setDialog] = useState({ open: false, content: '', disabled: true, icon: '' });
  const [hasRegularCredit, setHasRegularCredit] = useState(false);

  const { t, i18n, classes } = props;

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const request = {
        mode: true,
        from: formatDate(fromDate)
      };
      const dialogContent = (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1" component="h6" style={{ color: 'black', fontWeight: '700', fontSize: '14px', marginTop: '24px' }}>
              {t('regularCreditLoading')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.popupBottom}></Box>
          </Grid>
        </Grid>
      );
      setDialog({
        open: true,
        content: dialogContent,
        icon: 'loading',
        disabled: true
      });

      const response = await paymentService.setRegularCreditAuth(request);
      if (response.data.status === 'OK') {
        setDocTransfer(response.data.key);

        const dialogContent = (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="h6" style={{ color: 'black', fontWeight: '700', fontSize: '14px', marginTop: '24px' }}>
                {t('regularCreditSetup')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.popupBottom}></Box>
            </Grid>
          </Grid>
        );

        setDialog({
          open: true,
          content: dialogContent,
          icon: 'success',
          disabled: false
        });

        const regularCreditData = await paymentService.getRegularCreditData();
        if (regularCreditData.data.status === 'OK' && regularCreditData.data.state === true) {
          setHasRegularCredit(true);
        }
      } else {
        response.data.messages.map(message => {
          return props.enqueueSnackbar(props.t(message), {
            variant: 'error',
            autoHideDuration: 5000
          });
        });
      }
    } catch (error) {
      props.enqueueSnackbar(props.t('TECHNICAL_ERROR'), {
        variant: 'error',
        autoHideDuration: 5000
      });
    }
  };

  const handleDateChange = date => {
    setFromDate(date);
  };

  return (
    <>
      <ErrorDialog
        showError={dialog.open}
        icon={dialog.icon}
        disabled={dialog.disabled}
        content={dialog.content}
        handleClose={() => {
          setDialog({ open: false, content: '', title: '' });
        }}
      />
      <Grid container style={{ padding: '16px' }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" component="h3" style={{ margin: '8px 0 32px 0', fontWeight: '700' }}>
            {t('paymentSetupBoxCard')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
                  <InputWrapper inputStyle="Main" label={t('fromDate')}>
                    <DatePicker
                      autoOk
                      disablePast
                      fullWidth
                      margin="dense"
                      id="fromDate"
                      name="fromDate"
                      inputVariant="outlined"
                      initialFocusedDate={new Date()}
                      format="yyyy.MM.dd"
                      views={['year', 'month', 'date']}
                      openTo="year"
                      onChange={handleDateChange}
                      value={fromDate}
                      invalidDateMessage={t('validate_date')}
                      variant="inline"
                    />
                  </InputWrapper>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.downloadWrapper}>
                  <Grid container justify="space-between">
                    <Grid item xs={12} sm style={{ textAlign: 'center' }}>
                      <p className={classes.downloadWrapperText} style={{ color: 'black', fontWeight: '400' }}>
                        <strong>{t('label.monthlyCardInfo1')}</strong>
                        {t('label.monthlyCardInfo2')}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={'https://allyoucanmove.hu/content_files/rendszeres_bankkartya_tajekoztato.pdf'}
                        underline="none">
                        <Button>{t('download')}</Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.downloadWrapper} display={docTransfer ? 'block' : 'none'}>
                  <Grid container justify="space-between">
                    <Grid item xs={12} sm style={{ textAlign: 'center' }}>
                      <p className={classes.downloadWrapperText} style={{ color: 'black', fontWeight: '400' }}>
                        {t('link.docTransfer')}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                      <Link href={`${API_URL}/common-services/document?id=${docTransfer}&kr=${props.currentUser.kr}`} underline="none">
                        <Button>{t('download')}</Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'right', position: 'absolute', bottom: '50px', right: '36px' }}>
                <Button type="submit" variant="outlined" color="primary" disabled={hasRegularCredit}>
                  {t('saveButton')}
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser
});

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(withSnackbar(RegularCreditPage))));
