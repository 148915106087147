import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { withStyles, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { styles } from '../../theme/styles';
import StreetTypeSelect from './StreetTypeSelect';
import InputWrapper from './InputWrapper';

const Address = (props) => {
  const {
    address,
    required,
    disabled,
    hidden,
    title,
    classes,
    namePrefix,
    cityList,
    handleFocus,
    handleBlur,
    handleOnAddressChange,
    errorList,
  } = props;
  const { t } = useTranslation();

  const showTitle = title ? (
    <p className={classes.contractTitle}>{title}</p>
  ) : null;
  const combinePrefix = (name) => namePrefix + name;
  return (
    <Grid
      container
      component="article"
      style={hidden ? { display: 'none' } : {}}
    >
      <Grid item xs={12}>
        {showTitle}
      </Grid>
      <Grid item xs={12} md={6} style={{ marginRight: '8px' }}>
        <InputWrapper
          inputStyle={
            errorList && errorList[combinePrefix('zip')] ? 'Error' : 'Main'
          }
          label={t('zip')}
        >
          <TextField
            id={`${namePrefix}zip`}
            name={`${namePrefix}zip`}
            fullWidth
            variant="outlined"
            margin="dense"
            disabled={disabled}
            value={address.zip}
            inputProps={{ maxLength: 4 }}
            onChange={(event) => handleOnAddressChange(event, props.addressType)}
            onBlur={(e) => handleBlur(e, required ? ['required', 'min4', 'max4', 'isNumber'] : [])}
            onFocus={handleFocus}
          />
        </InputWrapper>
      </Grid>
      <Grid item xs={12} md>
        <InputWrapper
          inputStyle={
            errorList && errorList[combinePrefix('city')] ? 'Error' : 'Main'
          }
          label={t('city')}
        >
          <Select
            variant="outlined"
            margin="dense"
            style={{ textAlign: 'left' }}
            id={`${namePrefix}city`}
            name={`${namePrefix}city`}
            fullWidth
            disabled={disabled || cityList.length < 2}
            value={address.city}
            onChange={(event) => handleOnAddressChange(event, props.addressType)}
            onBlur={(e) => (required ? handleBlur(e, ['required']) : null)}
            onFocus={handleFocus}
          >
            {cityList.map((text) => (
              <MenuItem value={text} key={text}>
                {text}
              </MenuItem>
            ))}
          </Select>
        </InputWrapper>
      </Grid>
      <Grid item xs={12} md={6} style={{ marginRight: '8px' }}>
        <InputWrapper
          inputStyle={
            errorList && errorList[combinePrefix('street')] ? 'Error' : 'Main'
          }
          label={t('street')}
        >
          <TextField
            id={`${namePrefix}street`}
            name={`${namePrefix}street`}
            fullWidth
            variant="outlined"
            margin="dense"
            disabled={disabled}
            value={address.street}
            onChange={(event) => handleOnAddressChange(event, props.addressType)}
            onBlur={(e) => (required ? handleBlur(e, ['required']) : null)}
            onFocus={handleFocus}
          />
        </InputWrapper>
      </Grid>
      <Grid item xs={12} md>
        <StreetTypeSelect
          id={`${namePrefix}streetType`}
          name={`${namePrefix}streetType`}
          fullWidth
          disabled={disabled}
          value={address.streetType}
          onChange={(event) => handleOnAddressChange(event, props.addressType)}
          type={errorList && errorList[combinePrefix('streetType')] ? 'Error' : 'Main'}
        />
      </Grid>
      <Grid item xs={6} style={{ marginRight: '8px' }}>
        <InputWrapper
          inputStyle={
            errorList && errorList[combinePrefix('house')] ? 'Error' : 'Main'
          }
          label={t('house')}
        >
          <TextField
            id={`${namePrefix}house`}
            name={`${namePrefix}house`}
            fullWidth
            variant="outlined"
            margin="dense"
            disabled={disabled}
            value={address.house}
            onChange={(event) => handleOnAddressChange(event, props.addressType)}
            onBlur={(e) => (required ? handleBlur(e, ['required']) : null)}
            onFocus={handleFocus}
          />
        </InputWrapper>
      </Grid>
      <Grid item xs>
        <InputWrapper inputStyle="Secondary" label={t('staircase')}>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            disabled={disabled}
            id={`${namePrefix}staircase`}
            name={`${namePrefix}staircase`}
            value={address.staircase}
            onChange={(event) => handleOnAddressChange(event, props.addressType)}
          />
        </InputWrapper>
      </Grid>
      <Grid item xs={6} style={{ marginRight: '8px' }}>
        <InputWrapper inputStyle="Secondary" label={t('floor')}>
          <TextField
            fullWidth
            disabled={disabled}
            variant="outlined"
            margin="dense"
            id={`${namePrefix}floor`}
            name={`${namePrefix}floor`}
            value={address.floor}
            onChange={(event) => handleOnAddressChange(event, props.addressType)}
          />
        </InputWrapper>
      </Grid>
      <Grid item xs>
        <InputWrapper inputStyle="Secondary" label={t('door')}>
          <TextField
            fullWidth
            disabled={disabled}
            variant="outlined"
            margin="dense"
            id={`${namePrefix}door`}
            name={`${namePrefix}door`}
            value={address.door}
            onChange={(event) => handleOnAddressChange(event, props.addressType)}
          />
        </InputWrapper>
      </Grid>
    </Grid>
  );
};

Address.propTypes = {
  address: PropTypes.shape().isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  title: PropTypes.string,
  classes: PropTypes.shape().isRequired,
  namePrefix: PropTypes.string,
  cityList: PropTypes.arrayOf(PropTypes.string),
  handleOnAddressChange: PropTypes.func.isRequired,
  addressType: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  errorList: PropTypes.shape().isRequired,
};

Address.defaultProps = {
  required: false,
  disabled: false,
  hidden: false,
  title: '',
  namePrefix: '',
  cityList: [],
  addressType: '',
};

export default withStyles(styles)(Address);
