import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import React from 'react';
import { parseISO, endOfYear } from 'date-fns/esm';
import differenceInDays from 'date-fns/differenceInDays'


/** This works in a iframe, too. */
export function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

//

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

//

export function SelectYesNo({ name, value, setValue, label, ...props }) {
  return (
    <FormControl {...props} fullWidth component="fieldset">
      <FormLabel component="legend">
        {label}
      </FormLabel>
      <RadioGroup row required name={name} value={value} onChange={e => setValue(e.target.value)}>
        <FormControlLabel value="true" control={<Radio color="primary" />} label="Igen" />
        <FormControlLabel value="false" control={<Radio color="primary" />} label="Nem" />
      </RadioGroup>
    </FormControl>
  );
}

/**
 * A partner objektumból a vezetéknév és a keresztnév illesztésével visszaad egy teljes nevet
 */

export function getName(partner) {
  var result = '';
  if (partner.firstName) result += partner.firstName + ' ';
  if (partner.lastName) result += partner.lastName + ' ';
  return result.trim();
}

/**
 * A partner címének adataiból készít egy teljes címet string-ként
 */

export function getAddress(address) {
  var result = '';
  if (address.country && address.country !== 'Magyarország') result += address.country + ' ';
  if (address.zip) result += address.zip + ' ';
  if (address.city) result += address.city + ', ';
  if (address.poBox) result += 'Pf: ' + address.poBox;
  else {
    if (address.street) result += address.street + ' ';
    if (address.streetType) result += address.streetType + ' ';
    if (address.house) result += address.house + ' ';
    if (address.staircase) result += address.staircase + ' ';
    if (address.floor) result += address.floor + ' ';
    if (address.door) result += address.door + ' ';
  }
  return result.replace(/\s+/, ' ').trim();
}

export function getYearEnd(validFrom) {
  var res = ''
  if (validFrom) {
    var date = parseISO(validFrom),
      end = endOfYear(date)
    res += formatDate(end)
  }
  return res
}

/**
 * ISO 8601 dátum formátum végéről levágja a felesleges időt, pl: 
 * "2019-10-16T17:19:00Z" --> "2019-10-16"
 */

export function formatDate(date) {
  return date ? date.toISOString().substr(0, 10) : '';
}

/**
 * A születési dátum alapján validáljuk, hogy megfelelő adóazonosító szám lett-e megadva
 */

export function checkTaxId(num, birthDay) {
  if (!/8\d{9}/.test(num)) {
    return false
  }

  var days = differenceInDays(
    new Date(birthDay),
    new Date(new Date(1867, 0, 1))
  )

  const diffDays = parseInt(num.substr(1, 5), 10);
  const cdv = parseInt(num.substr(9), 10)

  let sum = 0
  for (var i = 0; i < 9; i++) {
    sum += num.substr(i, 1) * (i + 1);
  }

  if (days === diffDays && cdv === sum % 11) {
    return true
  } else {
    return false
  }
}

/**
 * A backend által küldött hibaüzenetekből general hiba popupot 
 */

export function errorMessages(props, errors) {
  var errorMessages = []
  errors.forEach(element => {
    errorMessages.push(props.t(element))
  });
  return errorMessages
}

/**
 * Egy adott Map()-ben lévő checkbox-okat ellenőriz, hogy mindegyik ki lett-e pipálva
 */

export function checkCheckBoxes(mapToCheck) {
  let result = false
  mapToCheck.forEach((val, key) => {
    if (val === false) {
      result = true
    }
  })
  return result
}

/**
 * Amennyiben az adószám alapján már létezik egy felhasználó az adatbázisban, ellenőrzi, hogy az általa megadott kártyszám egyezik-e a régi kártyszámmal
 */

 export function checkCardNumMatch(num, oldNum) {
   if (num === oldNum){
     return true
   }

   return false
 }
