import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import {
  Button, Grid, MenuItem, Link, Box,
} from '@material-ui/core';
import Select from '@material-ui/core/Select';


import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import { styles } from '../theme/styles';
import InputWrapper from '../components/ui/InputWrapper';
import { authService } from '../services/authentication';
import { commonService } from '../services/common';
import { API_URL } from '../utils/Consts';
import Warning from '../assets/images/figyelmezteto_tabla_zold.png';


class DocumentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kr: '',
      listActual: [],
      listPast: [],
      currentYear: new Date().getFullYear(),
      year: new Date().getFullYear() - 1,
      currentUserKR: authService.currentUserKR(),
    };
  }

  getList = async (year) => {
    try {
      const response = await commonService.documentList(
        authService.currentUserKR(),
        year,
      );
      if (response.data.status === 'OK') {
        const { list } = response.data;
        list.sort((a, b) => b.docKey - a.docKey);
        this.setState({ listPast: list });
      } else {
        response.data.messages.map((message) => this.props.enqueueSnackbar(this.props.t(message), {
          variant: 'error',
        }));
      }
    } catch (e) {
      this.props.enqueueSnackbar(this.props.t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
  };

  getCurrentAndNextYear = async () => {
    const nextYear = (new Date().getFullYear() + 1).toString();
    try {
      const current = await commonService.documentList(
        authService.currentUserKR(),
        this.state.currentYear.toString(),
      );
      const next = await commonService.documentList(
        authService.currentUserKR(),
        nextYear,
      );
      if (current.data.status === 'OK') {
        const { list } = current.data;
        list.sort((a, b) => b.docKey - a.docKey);
        this.setState({ listActual: list });
      } else {
        current.data.messages.map((message) => this.props.enqueueSnackbar(this.props.t(message), {
          variant: 'error',
        }));
      }
      if (next.data.status === 'OK' && next.data.list.length) {
        let { list } = next.data;
        if (list) {
          list = [...list, ...this.state.listActual];
          list.sort((a, b) => b.docKey - a.docKey);
          this.setState({ listActual: list });
        }
      }
    } catch (e) {
      console.error(e);
      this.props.enqueueSnackbar(this.props.t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
  }

  async componentDidMount() {
    const lastYear = (new Date().getFullYear() - 1).toString();
    this.getCurrentAndNextYear();
    this.getList(lastYear);
  }

  getDocName = (id) => id.substr(0, id.length - 4)

  getDocYear = (id) => id.substr(id.length - 4, id.length)

  handleOnChange = (event) => {
    if ((this.state.currentYear - event.target.value) < 4) {
      this.getList(event.target.value);
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  selectItems = () => {
    const current = new Date().getFullYear();

    const items = [];
    for (let i = current - 1; i >= 2011; i--) {
      items.push(i);
    }

    return items.map((item) => <MenuItem value={item} key={item} style={{ width: '140px' }}>{item}</MenuItem>);
  }

  render() {
    const { t, classes } = this.props;
    return (
      <>
        <Typography component="section">
          <Grid container justify="center" style={{ padding: '16px 32px' }}>
            <Grid item xs={12}>
              <h2 style={{ fontSize: '14px' }}>
                {t('label.documentsCurrentYear')}
              </h2>
            </Grid>

            {this.state.listActual.map((row) => (
              <Grid item xs={12} key={row.docKey}>
                <Box
                  className={classes.downloadWrapper}
                  style={{ border: '2px solid rgba(49, 139, 129, 1)' }}
                >
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item xs="auto">
                      <p
                        className={classes.downloadWrapperText}
                        style={{ color: 'black' }}
                      >
                        {this.getDocYear(row.docId)}
.
                        {' '}
                        {t(`${this.getDocName(row.docId)}`)}
                      </p>
                    </Grid>
                    <Grid item xs style={{ textAlign: 'right' }}>
                      <Link
                        href={`${API_URL}/common-services/document?id=${
                          row.docKey
                        }${row.docKr || this.getDocName(row.docId) === 'intra.aszf.' ? `&kr=${this.state.currentUserKR}` : ''}`}
                        underline="none"
                      >
                        <Button style={{ color: 'rgba(49, 139, 129, 1)' }}>
                          {t('download')}
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
            <Grid item xs={12} style={{ paddingTop: '20px' }}>
              <Grid container justify='space-between'>
                <Grid item>
                  <h2 style={{ fontSize: '14px', fontWeight: '400' }}>
                    {t('label.pastDocuments')}
                  </h2>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Grid container>
                    <Grid item xs={8}>
                      <p
                        style={{
                          margin: '12px 0',
                          fontSize: '14px',
                          color: 'rgba(161,161,161,1)',
                          fontWeight: '700',
                        }}
                      >
                        {t('chooseYear')}
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <InputWrapper inputStyle="SelectYear">
                        <Select
                          id="year"
                          name="year"
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '200px',
                              },
                            },
                          }}
                          variant="outlined"
                          margin="dense"
                          value={this.state.year}
                          onChange={this.handleOnChange}
                          IconComponent="none"
                          style={{ textAlign: 'center' }}
                          SelectDisplayProps={{
                            style: {
                              width: '140px',
                              paddingRight: '0',
                            },
                          }}
                        >
                          {this.selectItems()}
                        </Select>
                      </InputWrapper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {(this.state.currentYear - this.state.year) < 4
              ? this.state.listPast.map((row) => (
                <Grid item xs={12} key={row.docKey}>
                  <Box
                    className={classes.downloadWrapper}
                    style={{ border: '2px solid rgba(49, 139, 129, 1)' }}
                  >
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item xs="auto">
                        <p
                          className={classes.downloadWrapperText}
                          style={{ color: 'black' }}
                        >
                          {this.getDocYear(row.docId)}
.
                          {t(`${this.getDocName(row.docId)}`)}
                        </p>
                      </Grid>
                      <Grid item xs style={{ textAlign: 'right' }}>
                        <Link
                          href={`${API_URL}/common-services/document?id=${
                            row.docKey
                          }${row.docKr || this.getDocName(row.docId) === 'intra.aszf.' ? `&kr=${this.state.currentUserKR}` : ''}`}
                          underline="none"
                        >
                          <Button style={{ color: 'rgba(49, 139, 129, 1)' }}>
                            {t('download')}
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))
              : (
                <Grid item xs={12}>
                  <Grid container justify="flex-start" alignItems="center" className={classes.warningField}>
                    <Grid item xs={12} sm="auto" style={{ textAlign: 'center' }}>
                      <img src={Warning} style={{ width: '75px ', height: 'auto' }} alt="" />
                    </Grid>
                    <Grid item xs={12} sm className="warningText">
                      <p style={{ marginTop: '0', marginBottom: '8px' }}><strong>{t('oldDocument')}</strong></p>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Typography>
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(withSnackbar(DocumentsPage)));
