import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Grid,
  withStyles,
  Typography,
  FormHelperText,
  TextField,
  Box,
} from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import Address from '../../components/ui/Address';
import InputWrapper from '../../components/ui/InputWrapper';
import PropTypes from 'prop-types';
import { contractService } from '../../services/contracting';
import { styles } from '../../theme/styles';
import useValidator from '../../utils/useValidator';
// import ReactGA from 'react-ga';
// import { isObjectNotEmpty, removeAddressPrefix } from '../../utils/Utils';
// import { authService } from '../../services/authentication';
// import ErrorDialog from '../../components/ui/ErrorDialog';

/// 2021-05-25 pjung TNRUKAYCM-1119 SZÉP kártya tulajdonos adatait bekérő form-részlet
const AskSzepForm = ({ fizetes, ...props }) => {
  // const user = JSON.parse(sessionStorage.getItem('currentUser'));
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setErrors, removeErrors, resetErrors, setFields, errorList, hasErrors, requiredGotFilled, didFillEverything } = useValidator();

  // Kezdőértékek PROPS alapján: Saját, partner...
  const [state, setState] = useState({
    sajat: true,
    part: '',
  });
  // Kezdőértékek PROPS alapján: Cím...
  const [homeAddress, setHomeAddress] = useState({
    zip: '',
    city: '',
    street: '',
    streetType: '',
    house: '',
    staircase: '',
    floor: '',
    door: '',
    poBox: '',
  });
  // További adatok
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    setFields(['name', 'zip', 'city', 'street', 'streetType', 'house']);
    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
  };

  const handleFocus = (e) => {
    removeErrors(e);
  };

  const listCitiesByZip = async (zip) => {
    let result = '';
    try {
      const response = await contractService.listCitiesByZip(zip);
      if (response.data.status === 'OK') {
        result = response.data.list.map((city) => city.name);
      } else {
        response.data.messages.map((message) =>
          enqueueSnackbar(t(message), {
            variant: 'error',
          }),
        );
      }
    } catch (e) {
      enqueueSnackbar(t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
    return result;
  };

  const handleSajat = (event) => {
    // requiredGotFilled(event);
    const { name, value } = event.target;
    console.log('handleSajat', name, value, typeof value);
    setState({ ...state, sajat: value === 'true' });
  };

  const handlePart = (event) => {
    requiredGotFilled(event);
    const { name, value } = event.target;
    console.log('handlePart', name, value, typeof value);
    setState({ ...state, part: value });
  };

  const handleOnAddressChange = async (event) => {
    requiredGotFilled(event);
    const { name, value } = event.target;
    console.log('handleOnAddressChange', name, value);
    const copy = { ...homeAddress };
    copy[name] = value;
    if (name === 'zip' && value.length === 4) {
      const cityListCopy = await listCitiesByZip(value);
      copy.city = copy.city || cityListCopy[0];
      setCityList(cityListCopy);
    } else if (name === 'zip') {
      copy.city = '';
      setCityList([]);
    }
    if (name === 'zip') {
      requiredGotFilled({ ...event, target: { ...event.target, name: 'city', value: copy.city } });
    }
    setHomeAddress(copy);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              component="h6"
              style={{
                color: 'red',
                fontWeight: '700',
                fontSize: '14px',
              }}>
              {t('szepCardOwnerMsg')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" style={{ margin: '10px 40px 20px' }}>
              <RadioGroup aria-label={t('szepCardOwner')} id="sajat" name="sajat" onChange={handleSajat} value={state.sajat}>
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBox />} />}
                  label={t('szepCardOwnerMe')}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBox />} />}
                  label={t('szepCardOwnerNotMe')}
                />
              </RadioGroup>
              <Box display={hasErrors('sajat') ? 'block' : 'none'}>
                <FormHelperText>{t('validate_require')}</FormHelperText>
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box ms="auto" display={state.sajat !== false ? 'none' : 'block'}>
              <InputWrapper inputStyle={hasErrors('name') ? 'Error' : 'Main'} label={t('szepCardOwnerName')} style={{ marginBottom: '10px' }}>
                <TextField
                  fullWidth
                  disabled={state.sajat !== false}
                  id="name"
                  name="name"
                  variant="outlined"
                  margin="dense"
                  value={state.part}
                  onChange={handlePart}
                  onBlur={(e) => handleBlur(e, ['required'])}
                  onFocus={handleFocus}
                />
              </InputWrapper>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box ms="auto">
              <Address
                required
                hidden={state.sajat !== false}
                addressType="home"
                address={homeAddress}
                cityList={cityList}
                namePrefix=""
                handleOnAddressChange={handleOnAddressChange}
                handleBlur={handleBlur}
                handleFocus={handleFocus}
                errorList={errorList}
              />
            </Box>
          </Grid>
        </Grid>
        <Button
          style={{
            display: 'block',
            margin: '10px auto',
          }}
          type="button"
          color="primary"
          variant="contained"
          onClick={(event) => {
            // event.preventDefault();
            // TODO 2021-05-28 pjung TNRUKAYCM-1119 Validálás...
            if (
              state.sajat === false &&
              didFillEverything({
                name: state.part,
                zip: homeAddress.zip,
                city: homeAddress.city,
                street: homeAddress.street,
                streetType: homeAddress.streetType,
                house: homeAddress.house,
              }).length !== 0
            ) {
              enqueueSnackbar(t('validate_required'), {
                variant: 'error',
                // persist: true,
              });
            } else {
              const cim = {
                irszam: homeAddress.zip,
                varos: homeAddress.city,
                utca: homeAddress.street,
                kterj: homeAddress.streetType,
                hsz: homeAddress.house,
                lh: homeAddress.staircase,
                em: homeAddress.floor,
                aj: homeAddress.door,
              };
              console.log(state.sajat, state.part, cim);
              fizetes(state.sajat, state.part, cim);
            }
          }}>
          {t('button.pay')}
        </Button>
      </Grid>
    </Grid>
  );
};

AskSzepForm.propTypes = {
  fizetes: PropTypes.func.isRequired,
};

AskSzepForm.defaultProps = {};

export default withStyles(styles)(AskSzepForm);
