export const HO = ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember', 'október', 'november', 'december'];
export const MONTHS = ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];

// a=meglévő kártyát használja, b=Új plasztik kártya, c=Új ePASS
export const cardTypes = [
  { key: ' ', label: 'none' },
  { key: 'a', label: 'cardExists' },
  // { key: 'b', label: 'cardNewPlastic' },
  { key: 'c', label: 'cardNewEpass' },
];

// 1=cég, szervezet, 2=magánszemély, 3=munkáltatóm, 0=saját
export const invoiceDirTypes = [
  { key: 1, label: 'invoiceDirType1' },
  { key: 2, label: 'invoiceDirType2' },
  { key: 3, label: 'invoiceDirType3' },
];

// 1=Állandó, 2=levelezési, 3=egyéb
export const deliveryAddressTypes = [
  { key: 1, label: 'label.deliveryAddress1' },
  { key: 2, label: 'label.deliveryAddress2' },
  { key: 3, label: 'label.deliveryAddress3' },
];

export const API_URL = `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:8080'}`;
export const OTP_CALLBACK_URL = `${process.env.REACT_APP_CLIENT_URL ? process.env.REACT_APP_CLIENT_URL : 'http://localhost:3000'}/payment/callback`;
export const ONLINE_PAYMENT_CALLBACK_URL = `${process.env.REACT_APP_CLIENT_URL ? process.env.REACT_APP_CLIENT_URL : 'http://localhost:3000'}/payment`;
export const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

export const intraBaseUrl = process.env.REACT_APP_INTRA_URL;
