import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import InputWrapper from './InputWrapper';

const Honapok = (props) => {
  /**
   * A kártyaérvényesség kezdete dinamikusan változik szerződéskötés dátumától függően
   */
  const { t } = useTranslation();
  const {
    dates,
    fullWidth,
    validFrom,
    handleOnContractChange,
    errorList,
    handleBlur,
    handleFocus,
  } = props;
  const result = dates.map((dateStr) => {
    const date = new Date(dateStr);
    const txt = `${date.getFullYear()}. ${t(`month.${date.getMonth()}`)}`;

    const res = [];
    res.push(
      <MenuItem value={dateStr} key={date.getMonth}>
        {txt}
      </MenuItem>,
    );
    return res;
  });

  return (
    <FormControl fullWidth={fullWidth}>
      <InputWrapper
        inputStyle={errorList && errorList.validFrom ? 'Error' : 'Main'}
        label={t('cardValidFrom')}
      >
        <Select
          id="validFrom"
          name="validFrom"
          variant="outlined"
          margin="dense"
          value={validFrom}
          onChange={handleOnContractChange}
          onBlur={(e) => handleBlur(e, ['required'])}
          onFocus={handleFocus}
        >
          {result}
        </Select>
      </InputWrapper>
    </FormControl>
  );
};

Honapok.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string).isRequired,
  fullWidth: PropTypes.bool.isRequired,
  validFrom: PropTypes.string.isRequired,
  handleOnContractChange: PropTypes.func.isRequired,
  errorList: PropTypes.shape().isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
};

export default Honapok;
