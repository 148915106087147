import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
  Box,
  TextField
} from '@material-ui/core';
import Checkbox from '@material-ui/icons/CheckBox';
import CheckboxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { authService } from '../services/authentication';
import { paymentService } from '../services/payment';
import { ONLINE_PAYMENT_CALLBACK_URL } from '../utils/Consts';
import { isBlank, formatDate, priceMask, changeObjectNullValuesToEmptyString } from '../utils/Utils';
import { setCurrentUser } from '../redux/user/user.actions';
import useInterval from '../utils/useInterval';
import useValidator from '../utils/useValidator';
import ErrorDialog from '../components/ui/ErrorDialog';
import InputWrapper from '../components/ui/InputWrapper';
import PaymentLogos from '../components/ui/PaymentLogos';
import AskSzepForm from '../components/ui/SzepDlg';
import { styles } from '../theme/styles';

const PaymentForm = props => {
  const [state, setState] = useState({
    showError: false,
    errorMessages: '',
    invoiceDone: false,
    card: '',
    amount: '',
    annual: 0,
    method: '4',
    hasRegularCredit: false,
    hasDirectDebit: false
  });

  // 2021-05-25 pjung TNRUKAYCM-11119 SZÉP adatok kezelése

  const [hasRegularCredit, setHasRegularCredit] = useState(false);
  const [hasDirectDebit, setHasDirectDebit] = useState(false);
  const [popup, setPopup] = useState({
    open: false,
    dialogContent: null,
    dialogTitle: '',
    icon: '',
    popupDisabled: false
  });

  const [directDebitData, setDirectDebitData] = useState({});
  const [regularCreditData, setRegularCreditData] = useState({});
  const [start, setStart] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { transaction } = useParams();
  const history = useHistory();

  const { setErrors, removeErrors, resetErrors, hasErrors, setFields, didFillEverything, giveErrors } = useValidator();

  const getAmounts = useCallback(async () => {
    // Lekérdezzük az összegeket
    const request = {
      kr: authService.currentUserKR(),
      year: new Date().getFullYear()
    };

    try {
      const response = await paymentService.getAmounts(request);
      if (response.data.status === 'OK') {
        setState({
          ...state,
          amount: response.data.currentDebit ? parseInt(response.data.currentDebit, 10) : 0,
          annual: response.data.allDebit ? parseInt(response.data.allDebit, 10) : 0
        });
      } else {
        response.data.messages.map(message =>
          enqueueSnackbar(t(message), {
            variant: 'error'
          })
        );
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t('TECHNICAL_ERROR'), {
        variant: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.annual]);

  const getStatus = async () => {
    const dialogContent = (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            component="h6"
            style={{
              color: 'black',
              fontWeight: '700',
              fontSize: '14px',
              marginTop: '24px'
            }}>
            {t('invoiceDone')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={props.classes.popupBottom} />
        </Grid>
      </Grid>
    );
    try {
      const response = await paymentService.getInvState(authService.currentUserKR());

      const { status, card } = response.data;
      setState({
        ...state,
        invoiceDone: status === 'DONE',
        card: card || ''
      });
      setPopup({
        ...popup,
        open: true,
        popupDisabled: status !== 'DONE',
        icon: status === 'DONE' ? 'success' : 'loading',
        dialogContent: status === 'DONE' ? dialogContent : popup.dialogContent
      });
      if (status === 'DONE') {
        setStart(false);
        sessionStorage.removeItem('transaction');
        const headData = await authService.getHeadData();
        if (headData.data.status === 'OK') {
          const user = changeObjectNullValuesToEmptyString(headData.data);
          props.setCurrentUser(user);
        }
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t('TECHNICAL_ERROR'), {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    setFields(['amount']);
    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const directDebit = await paymentService.getDirectDebitData();
        if (directDebit.data.status === 'OK') {
          setDirectDebitData({
            ...directDebitData,
            ...directDebit.data
          });
          if (directDebit.data.state === true) {
            setHasDirectDebit(true);
          }
        } else {
          directDebit.data.messages.map(message =>
            enqueueSnackbar(t(message), {
              variant: 'error'
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const regularCredit = await paymentService.getRegularCreditData();
        if (regularCredit.data.status === 'OK') {
          setRegularCreditData({
            ...regularCreditData,
            ...regularCredit.data
          });
          if (regularCredit.data.state === true) {
            setHasRegularCredit(true);
          }
        } else {
          regularCredit.data.messages.map(message =>
            enqueueSnackbar(t(message), {
              variant: 'error'
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (sessionStorage.transaction && transaction === sessionStorage.transaction) {
        try {
          const response = await paymentService.endTransaction(transaction);
          const isPaymentSuccess = !isBlank(response.data.auth);

          if (response.data.status === 'OK') {
            const dialogContent = (
              <Grid container>
                <Grid item xs={12}>
                  <Grid container style={{ overflowWrap: 'break-word', fontSize: '14px' }}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        component="h6"
                        style={{
                          color: 'black',
                          fontWeight: '700',
                          fontSize: '14px'
                        }}>
                        {isPaymentSuccess ? t('label.online.payment.invoice') : t('paymentError')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {t('amount2')}
                    </Grid>
                    <Grid item xs={6}>
                      {response.data.amount} {t('msg.forint')}
                    </Grid>
                    <Grid item xs={6}>
                      {t('chosen.fizmod')}
                    </Grid>
                    <Grid item xs={6}>
                      {response.data.method ? t(`chosen.fizmod.${response.data.method}`) : ''}
                    </Grid>
                    <Grid item xs={6}>
                      {t('label.transactionID')}
                    </Grid>
                    <Grid item xs={6}>
                      {response.data.transaction}
                    </Grid>
                    <Grid item xs={6}>
                      {t('label.bankAcceptCode')}
                    </Grid>
                    <Grid item xs={6}>
                      {response.data.auth}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box className={props.classes.popupBottom} />
                </Grid>
              </Grid>
            );

            setPopup({
              ...popup,
              dialogContent,
              open: true,
              popupDisabled: true,
              icon: 'loading'
            });

            if (isPaymentSuccess) {
              setStart(true);
            } else {
              setState({
                ...state,
                invoiceDone: true
              });
              setPopup({
                ...popup,
                dialogContent,
                open: true,
                popupDisabled: false,
                icon: 'warning'
              });
            }
          } else {
            response.data.messages(message =>
              enqueueSnackbar(t(message), {
                variant: 'error'
              })
            );
          }
        } catch (e) {
          enqueueSnackbar(t('TECHNICAL_ERROR'), {
            variant: 'error'
          });
        }
      }
    };

    fetchData();
    getAmounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(
    () => {
      getStatus();
    },
    start ? 5000 : null
  );

  useEffect(() => {
    getAmounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = event => {
    if (event.target.name === 'amount') {
      // 2020-09-04 pjung TNRUKAYCM-843 Space-szel vagy vesszővel tagolt szám...
      const re = /^[0-9, \b]+$/;

      if (event.target.value === '' || re.test(event.target.value)) {
        setState({ ...state, amount: event.target.value.replace(/[, ]/, '') });
      }
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (didFillEverything({ amount: state.amount }).length === 0) {
      if (state.amount > state.annual) {
        const key = enqueueSnackbar(t('validate_maxAmount'), {
          variant: 'error'
        });
        giveErrors('amount', key);
      } else if (Number(state.amount) === 0 || !state.amount) {
        const key = enqueueSnackbar(t('validate_nullPayment'), {
          variant: 'error'
        });
        giveErrors('amount', key);
      } else if (state.method === 'A') {
        // 2021-05-25 pjung TNRUKAYCM-1119 SZÉP: bekérjük az adatokat, Fizetés megnyomásakor ugrik fel
        askSzepDlg({ fizetes });
      } else {
        fizetes(null, '', {});
      }
    } else {
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error'
        // persist: true,
      });
      didFillEverything(['amount']).map(input => giveErrors(input, key));
    }
  };

  const closeErrorDialog = () => {
    sessionStorage.removeItem('transaction');
    setPopup({
      ...popup,
      open: false,
      dialogContent: null
    });
    getAmounts();
  };

  const fizetes = async (sajat, partner, cim) => {
    try {
      console.log('Sajat', sajat, 'Partner', partner, 'Cim', cim);
      const data = {
        kr: authService.currentUserKR(),
        amount: state.amount,
        method: state.method,
        label: t('paymentLabel'),
        lang: localStorage.getItem('i18nextLng'),
        url: ONLINE_PAYMENT_CALLBACK_URL,
        // 2021-05-18 pjung TNRUKAYCM-1113 Debug SZÉP kártyatulajdonos adatok...
        fSajat: sajat || false,
        fPart: sajat ? '' : partner,
        fCim: !sajat && cim?.irszam ? cim : null
      };
      console.log('data', data);
      const response = await paymentService.startTransaction(data);
      if (response.data.status === 'OK') {
        sessionStorage.setItem('transaction', response.data.transaction);
        // redirect -> OTP
        if (response.data.url) {
          window.location.replace(response.data.url);
        }
      } else {
        response.data.messages.map(message =>
          enqueueSnackbar(t(message), {
            variant: 'error'
          })
        );
      }
    } catch (e) {
      enqueueSnackbar(t('TECHNICAL_ERROR'), {
        variant: 'error'
      });
    }
  };

  // 2021-05-27 pjung TNRUKAYCM-1119 Felugró ablak a SZÉP kártya tulajdonos adatainak
  const askSzepDlg = props => {
    console.debug(props.fSajat, props.fPart);
    console.debug(props.fCim);

    setPopup({
      ...popup,
      dialogTitle: t('szepCardOwner'),
      icon: 'warning',
      // popupDisabled: false,
      open: true,
      dialogContent: <AskSzepForm {...props} />,
      size: 'md'
    });
  };

  const removeRecurring = async () => {
    const cancelRecurring = async () => {
      try {
        const response = await paymentService.setRegularCreditAuth({
          mode: false,
          from: formatDate(new Date())
        });
        if (response.data.status === 'OK') {
          setPopup({
            ...popup,
            open: false,
            dialogContent: ''
          });
          setHasRegularCredit(false);
        } else {
          setPopup({
            ...popup,
            open: true,
            dialogContent: ['Technikai hiba']
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    const dialogContent = (
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12}>
          <p style={{ textAlign: 'left', fontSize: '14px', fontWeight: '700' }}>{t('regularCreditCancel')}</p>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right', marginTop: '16px' }}>
          <Button variant="outlined" color="primary" onClick={cancelRecurring}>
            {t('button.paymentCancel')}
          </Button>
          <Grid item xs={12}>
            <Box className={props.classes.popupBottom} />
          </Grid>
        </Grid>
      </Grid>
    );
    setPopup({
      ...popup,
      open: true,
      dialogContent,
      icon: 'warning'
    });
  };

  const regularCreditHandler = () => {
    if (regularCreditData.reg) {
      history.push('/payment/regularcredit');
    } else {
      const dialogContent = (
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  component="h6"
                  style={{
                    color: 'black',
                    fontWeight: '700',
                    fontSize: '14px'
                  }}>
                  {t('noRegisteredBankcard')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="p"
                  style={{
                    color: 'black',
                    fontSize: '14px',
                    marginTop: '24px'
                  }}>
                  {t('noRegisteredBankcard2')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={props.classes.popupBottom} />
          </Grid>
        </Grid>
      );

      setPopup({
        ...popup,
        icon: 'warning',
        open: true,
        dialogContent
      });
    }
  };

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
  };

  const handleFocus = e => {
    removeErrors(e);
  };

  const { classes } = props;
  const kr = authService.currentUserKR();

  const urlink = 'https://allyoucanmove.hu/content_files/rendszeres_bankkartya_tajekoztato.pdf';

  return (
    <>
      <ErrorDialog
        showError={popup.open}
        title={popup.dialogTitle}
        content={popup.dialogContent}
        handleClose={closeErrorDialog}
        icon={popup.icon}
        disabled={popup.popupDisabled}
        size={popup.size}
      />

      <form onSubmit={handleSubmit} onError={errors => console.error(errors)}>
        <Grid container className={classes.paymentBox}>
          <Grid item xs={12} sm={6} className={classes.paymentBoxGold}>
            <Grid container justify="flex-start" alignItems="baseline" style={{ height: '100%' }}>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="amount"
                      style={{
                        fontWeight: '700',
                        marginBottom: '16px',
                        fontSize: '14px',
                        color: 'black'
                      }}>
                      {t('amount')}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6} className={classes.amountInput} style={{}}>
                    <InputWrapper inputStyle={hasErrors('amount') ? 'ErrorPayment' : 'Payment'}>
                      <TextField
                        id="amount"
                        name="amount"
                        type="text"
                        className="amount"
                        variant="outlined"
                        inputProps={{
                          style: {
                            padding: 0
                          }
                        }}
                        placeholder="0"
                        onChange={handleChange}
                        value={state.amount.toLocaleString()}
                        onBlur={e => handleBlur(e, ['required'])}
                        onFocus={handleFocus}
                        // validators={[
                        //   'required',
                        //   'minNumber:0',
                        //   'isNumber',
                        // ]}
                        // errorMessages={[
                        //   t('validate_require'),
                        //   t('validate_minAmount'),
                        //   t('validate_isNumber'),
                        // ]}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="h5"
                      component="h3"
                      style={{
                        marginTop: '3px',
                        fontSize: '30px',
                        marginLeft: '10px'
                      }}>
                      <strong>{t('msg.forint')}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: '700',
                  fontSize: '12px',
                  color: 'rgb(161,161,161)'
                }}>
                {t('multiPaymentTypes')}
              </Grid>
              <Grid item xs={12} style={{ marginLeft: '8px', marginTop: '8px' }}>
                <FormControl component="fieldset" style={{ marginTop: '30px' }}>
                  <FormLabel
                    component="legend"
                    style={{
                      fontWeight: '700',
                      margin: '0 0 16px -8px',
                      fontSize: '14px',
                      color: 'black'
                    }}>
                    {t('paymentType')}
                  </FormLabel>
                  <RadioGroup aria-label={t('paymentType')} id="method" name="method" onChange={handleChange} value={state.method}>
                    <FormControlLabel
                      value="4"
                      control={<Radio color="primary" icon={<CheckboxOutlineBlank />} checkedIcon={<Checkbox />} disabled={!kr} />}
                      label={t('value.fizmod.4')}
                    />
                    <FormControlLabel
                      value="31"
                      control={<Radio color="primary" icon={<CheckboxOutlineBlank />} checkedIcon={<Checkbox />} disabled={!kr} />}
                      label={t('value.fizmod.31')}
                    />
                    {/* 2021-11-30 pjung TNRUKAYCM-1281 OTP Cafeteria
                    <FormControlLabel
                      value="15"
                      control={<Radio color="primary" icon={<CheckboxOutlineBlank />} checkedIcon={<Checkbox />} disabled={!kr} />}
                      label={t('value.fizmod.15')}
                  /> */}
                    {/* 2021-05-14 pjung TNRUKAYCM-1113 OTP SZÉP kártya */}
                    <FormControlLabel
                      value="A"
                      control={<Radio color="primary" icon={<CheckboxOutlineBlank />} checkedIcon={<Checkbox />} disabled={!kr} />}
                      label={t('value.fizmod.A')}
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  style={{
                    display: 'block',
                    marginTop: '32px',
                    marginLeft: '-8px',
                    marginBottom: '32px'
                  }}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={!state.annual || !kr}>
                  {t('button.pay')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" component="h4" style={{ color: 'rgba(161,161,161,1)' }}>
                      {t('annualPayment')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" component="h4" style={{ fontWeight: '700', textAlign: 'left' }}>
                      {`${priceMask(state.annual)} Ft`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '8px' }}>
                <PaymentLogos />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.paymentBoxGrey}>
            <Grid container>
              <Grid item xs={12} style={{ height: '103px' }}>
                <Typography variant="subtitle2" component="h4" style={{ fontWeight: '700' }}>
                  {t('paymentSetupBoxHeading')}
                </Typography>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" component="h4" style={{ fontWeight: '700' }}>
                    {t('paymentSetupBoxCard')}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="h4"
                    style={{
                      fontWeight: '700',
                      fontSize: '12px',
                      color: 'rgb(0, 191, 243)',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      window.open(urlink);
                    }}>
                    {t('paymentSetupBoxMore')}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Button onClick={regularCreditHandler} variant="outlined" color="secondary" disabled={hasRegularCredit || !kr}>
                    {t('button.paymentSetup')}
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button onClick={removeRecurring} variant="outlined" disabled={!hasRegularCredit || !kr} color="secondary">
                    {t('button.paymentCancel')}
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ position: 'relative' }}>
                  {regularCreditData.reg ? (
                    <span>
                      {t('changeRegisteredBankCard')}
                      <br />
                      {regularCreditData.reg}
                    </span>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container alignItems="center" style={regularCreditData.reg ? { marginTop: '24px' } : { marginTop: '64px' }} spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" component="h4" style={{ fontWeight: '700' }}>
                    {t('paymentSetupBoxDirectDebit')}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Button onClick={() => history.push('/payment/directdebit')} variant="outlined" color="secondary" disabled={!kr}>
                    {t('button.paymentSetup')}
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button variant="outlined" color="secondary" disabled={!hasDirectDebit || kr}>
                    {t('button.paymentCancel')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

PaymentForm.propTypes = {
  classes: PropTypes.shape(),
  setCurrentUser: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired
};

PaymentForm.defaultProps = {
  classes: {}
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser
});

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PaymentForm));
