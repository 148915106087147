import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import ReactGA from 'react-ga4';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  // FormHelperText,
  Grid,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import Address from '../../components/ui/Address';
import ErrorDialog from '../../components/ui/ErrorDialog';
import FinalCheck from '../../components/ui/FinalCheck';
import InputWrapper from '../../components/ui/InputWrapper';
import PaymentMonths from '../../components/ui/PaymentMonths';
import { checkCheckBoxes } from '../../components/utils/Tools';
import { contractService } from '../../services/contracting';
import { paymentService } from '../../services/payment';
import { styles } from '../../theme/styles';
import { OTP_CALLBACK_URL, API_URL } from '../../utils/Consts';
import { priceMask } from '../../utils/Utils';
import useValidator from '../../utils/useValidator';
// import Warning from '../../assets/images/figyelmezteto_tabla_zold.png';

// 7. Page

const PaymentData = props => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setErrors, removeErrors, resetErrors, setFields, errorList, requiredGotFilled, didFillEverything } = useValidator();

  const [state, setState] = useState({
    checkedItems: new Map([
      ['cb-1', false],
      ['cb-2', false],
      ['cb-3', false],
      ['cb-4', false],
      ['cb-5', false]
    ]),
    checkedFinal: false,
    otpError: false,
    dialogContent: null,
    kr: 0,
    type: '',
    method: '',
    paymentMonths: 2,
    paymentSum: 0,
    validFrom: '',
    fees: {
      hdij: 0,
      dij1: 0,
      dij2: 0,
      dij3: 0,
      dij4: 0,
      fdij: 0,
      fdij1: 0,
      fdij2: 0,
      fdij3: 0,
      fdij4: 0,
      fhonap: 0,
      fhdijo: 0,
      fosszeg: 0
    },
    feeSum: 0,
    account: '',
    name: '',
    owner: '',
    birthPlace: '',
    birthDate: '',
    mothersName: '',
    address: {
      zip: '',
      city: '',
      street: '',
      streetType: '',
      house: '',
      staircase: '',
      floor: '',
      door: ''
    },
    key: 0, // payment
    reg: 0,
    start: 0,
    amount: 0,
    payMethod: '',
    transaction: '',
    // 2020-11-23 pjung TNRUKAYCM-928 Túlfizetés beszámolása
    tulfiz: 0,
    reszosszeg: NaN
  });
  // 2021-06-04 pjung TNRUKAYCM-1120 SZÉP kártyás adatok
  const [fSajat, setSajat] = useState(null);
  const [fPart, setPart] = useState('');
  const [fCim, setCim] = useState({
    zip: '',
    city: '',
    street: '',
    streetType: '',
    house: '',
    staircase: '',
    floor: '',
    door: '',
    poBox: ''
  });
  const [cityList, setCityList] = useState([]);

  const responseToPaymentData = data => {
    const stateCopy = { ...state };
    const { ledger } = data;
    stateCopy.type = data.type || state.type;
    stateCopy.validFrom = data.validFrom || state.validFrom;
    stateCopy.kr = data.kr || state.kr;
    stateCopy.docAszf = data.docAszf;
    if (ledger) {
      stateCopy.method = ledger.method ? ledger.method : '';
      const { fees, bank, payment } = ledger;
      if (fees) {
        stateCopy.fees = {
          ...stateCopy.fees,
          ...fees,
          fdij2: 0,
          fdij4: 0
        };
        // TODO Ezt kell szerver oldalon csinálni !!!
        // 2020-11-23 pjung TNRUKAYCM-928 Túlfizetés beszámolása
        stateCopy.feeSum =
          (stateCopy.reszosszeg || stateCopy.fees.fosszeg) +
          stateCopy.fees.fdij1 +
          stateCopy.fees.fdij2 +
          stateCopy.fees.fdij3 +
          stateCopy.fees.fdij4;
      }
      if (bank) {
        stateCopy.account = bank.account ? bank.account : '';
        stateCopy.name = bank.name ? bank.name : '';
        stateCopy.owner = bank.owner ? bank.owner : '';
        stateCopy.birthPlace = bank.birthPlace ? bank.birthPlace : '';
        stateCopy.birthDate = bank.birthDate ? bank.birthDate : '';
        stateCopy.mothersName = bank.mothersName ? bank.mothersName : '';

        if (bank.address) {
          stateCopy.bankAddress = { ...stateCopy.bankAddress, ...bank.address };
        }
      }

      if (payment) {
        stateCopy.key = payment.key ? payment.key : 0; // payment
        stateCopy.reg = payment.reg ? payment.reg : 0;
        stateCopy.start = payment.start ? payment.start : 0;
        stateCopy.amount = payment.amount ? payment.amount : 0;
        stateCopy.payMethod = payment.method ? payment.method : '';
        stateCopy.transaction = payment.transaction ? payment.transaction : '';
      }
      if (stateCopy.method === '31') {
        stateCopy.payMethod = stateCopy.method;
      }
    }
    return stateCopy;
  };

  const get7 = useCallback(async () => {
    let newState = { ...state };
    if (sessionStorage.contractKey) {
      try {
        const response = await contractService.get(7, sessionStorage.contractKey);
        if (response.data.status === 'OK') {
          newState = {
            ...newState,
            ...responseToPaymentData(response.data)
          };
          const { fees } = newState;
          const otherFees = fees.fdij1 + fees.fdij2 + fees.fdij3 + fees.fdij4;
          if (newState.method === '4') {
            // Ha éves: fosszeg + fdij1-4
            newState.paymentSum = fees.fosszeg + otherFees;
          } else {
            // Ha havi: fdij * honap + fdij1-4
            newState.paymentSum = fees.fdij * newState.paymentMonths + otherFees;
            newState.fees.fhonap = newState.paymentMonths;
            newState.fees.fhdijo = newState.paymentSum;
          }
        } else {
          response.data.messages.map(message =>
            enqueueSnackbar(t(message), {
              variant: 'error'
            })
          );
        }
      } catch (e) {
        // console.error(e);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
    }

    // 2020-11-23 pjung TNRUKAYCM-928 Túlfizetés beszámolása
    console.log(newState.kr, newState.validFrom);
    if (newState.kr && newState.validFrom) {
      try {
        const response = await paymentService.getAmounts({
          kr: newState.kr,
          // Date helyett String, úgyhogy az első 4 karaktert vesszük...
          year: Number.parseInt(newState.validFrom.substring(0, 4), 10)
        });
        console.log(response.data);
        if (response.data?.status === 'OK') {
          if (response.data.allDebit < 0) {
            // const newState = { ...state };
            const fees = { ...newState.fees };
            const otherFees = fees.fdij1 + fees.fdij2 + fees.fdij3 + fees.fdij4;
            newState.tulfiz = -response.data.allDebit;
            newState.reszosszeg = fees.fosszeg - Math.min(fees.fosszeg, newState.tulfiz);
            newState.feeSum = newState.reszosszeg + otherFees;

            if (newState.method === '4') {
              newState.paymentSum = newState.feeSum;
            } else {
              const reszossz = fees.fdij * newState.paymentMonths;
              newState.paymentSum = reszossz - Math.min(newState.tulfiz, reszossz) + otherFees;
              newState.fees.fhonap = newState.paymentMonths;
              newState.fees.fhdijo = newState.paymentSum;
            }
            console.log(state, newState);
          }
        } else {
          response.data.messages.map(message =>
            enqueueSnackbar(t(message), {
              variant: 'error'
            })
          );
        }
      } catch (e) {
        // console.error(e);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
    }
    setState(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/step7' });
    get7();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sessionStorage.otpres) {
      const otpres = JSON.parse(sessionStorage.otpres);
      const errors = (
        <Grid container>
          <Grid item xs={12}>
            <Grid container style={{ overflowWrap: 'break-word', fontSize: '14px' }}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="h6" style={{ color: 'black', fontWeight: '700', fontSize: '14px' }}>
                  {t('paymentError')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {t('amount2')}
              </Grid>
              <Grid item xs={6}>
                {otpres.amount} {t('msg.forint')}
              </Grid>
              <Grid item xs={6}>
                {t('chosen.fizmod')}
              </Grid>
              <Grid item xs={6}>
                {otpres.method ? t(`chosen.fizmod.${otpres.method}`) : ''}
              </Grid>
              <Grid item xs={6}>
                {t('label.transactionID')}
              </Grid>
              <Grid item xs={6}>
                {otpres.transaction}
              </Grid>
              <Grid item xs={6}>
                {t('label.bankAcceptCode')}
              </Grid>
              <Grid item xs={6}>
                {otpres.auth}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={props.classes.popupBottom} />
          </Grid>
        </Grid>
      );

      setState({
        ...state,
        dialogContent: errors,
        otpError: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnPaymentChange = event => {
    // TODO Ezt is a backendnek kell ellenőriznie! Itt csak a megjelenítés miatt van
    const newFees = { ...state.fees };
    let { paymentSum } = state;
    if (event.target.name === 'paymentMonths') {
      // 2020-11-23 pjung TNRUKAYCM-928 Túlfizetés beszámolása
      newFees.fhonap = event.target.value;
      const dij = newFees.fhonap * newFees.fdij;
      // 2021-11-12 TNRUKAYCM-1271 reszosszeg kezdőértéke NaN, ezért ha nincs túlfizetés, akkor itt mindent elront...
      const dij2 = Math.min(dij, state.tulfiz, state.reszosszeg || dij);
      paymentSum = dij - dij2 + newFees.fdij1 + newFees.fdij2 + newFees.fdij3 + newFees.fdij4;
      newFees.fhdijo = paymentSum;
    }

    setState({
      ...state,
      [event.target.name]: event.target.value,
      fees: newFees,
      paymentSum
    });
  };

  const prevStep = () => {
    props.prevStep();
  };

  const finish = async () => {
    if (sessionStorage.contractKey) {
      try {
        const submitResponse = await contractService.submit(7, {
          key: sessionStorage.contractKey,
          ledger: {
            fees: state.fees,
            method: state.payMethod
          }
        });

        if (submitResponse.data.status === 'OK') {
          const request = {
            contracting: sessionStorage.contractKey,
            amount: state.paymentSum,
            method: state.payMethod,
            label: t('paymentLabel'),
            lang: localStorage.getItem('i18nextLng'),
            url: OTP_CALLBACK_URL,
            // 2021-06-04 pjung TNRUKAYCM-1120 Debug SZÉP kártyatulajdonos adatok...
            fSajat: fSajat || false,
            fPart: fSajat !== false ? '' : fPart,
            fCim:
              fSajat === false && fCim?.zip
                ? {
                    irszam: fCim.zip,
                    varos: fCim.city,
                    utca: fCim.street,
                    kterj: fCim.streetType,
                    hsz: fCim.house,
                    lh: fCim.staircase,
                    em: fCim.floor,
                    aj: fCim.door
                  }
                : null
          };

          if (state.kr > 0) {
            request.kr = state.kr;
          }

          const response = await paymentService.startTransaction(request);
          if (response.data.status === 'OK') {
            sessionStorage.setItem('transaction', response.data.transaction);
            // redirect -> OTP
            if (response.data.url) {
              window.location.replace(response.data.url);
            }
          } else {
            response.data.messages.map(message =>
              props.enqueueSnackbar(t(message), {
                variant: 'error'
              })
            );
          }
        } else {
          submitResponse.data.messages.map(message =>
            props.enqueueSnackbar(t(message), {
              variant: 'error'
            })
          );
        }
      } catch (e) {
        props.enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
    }
  };

  const handleCheckAll = e => {
    const { checkedItems, checkAll, checkedFinal } = state;
    const toggleCheckboxes = (val, key, map) => {
      checkedItems.set(key, !checkAll);
    };

    checkedItems.forEach(toggleCheckboxes);

    setState({
      ...state,
      checkedItems,
      checkAll: !checkAll,
      checkedFinal: !checkedFinal
    });
  };

  const handleCheckboxes = event => {
    const item = event.target.name;
    const isChecked = event.target.checked;
    setState(prevState => ({
      ...state,
      checkedItems: prevState.checkedItems.set(item, isChecked)
    }));
  };

  const closeErrorDialog = () => {
    setState({
      ...state,
      otpError: false,
      dialogContent: null
    });
    sessionStorage.removeItem('otpres');
  };

  const disableButton = () => {
    // A CheckBoxok mind: false
    let res = checkCheckBoxes(state.checkedItems);
    switch (state.payMethod) {
      case '3':
      case '31':
        res |= state.paymentMonths === 0;
        break;
      // A SZÉP kártya tulajdonosának adatai
      case 'A':
        {
          const a = fSajat !== true;
          const b = didFillEverything({ ...fCim, name: fPart });
          console.log(fSajat, a, b);
          res |= a && b.length !== 0;
        }
        break;
      default:
    }
    return res;
  };

  // 2021-06-04 pjung TNRUKAYCM-1120 SZÉP kártya eseménykezelők...
  const listCitiesByZip = useCallback(
    async zip => {
      let result = '';
      try {
        const response = await contractService.listCitiesByZip(zip);
        // console.log(response.data.status, response.data.list);
        if (response.data.status === 'OK') {
          result = response.data.list.map(city => city.name);
        } else {
          response.data.messages.map(message =>
            enqueueSnackbar(t(message), {
              variant: 'error'
            })
          );
        }
      } catch (e) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fCim.zip]
  );

  const handleSajat = e => setSajat(e.target.value === 'true');
  const handlePart = e => setPart(e.target.value);
  const handleCim = async e => {
    requiredGotFilled(e);
    const { name, value } = e.target;
    const copy = { ...fCim };
    console.log(name, value, copy);
    let cityListCopy = [...cityList];
    copy[name] = value;
    if (name === 'zip' && value.length === 4) {
      cityListCopy = await listCitiesByZip(value);
      copy.city = copy.city || cityListCopy[0];
    } else if (name === 'zip') {
      copy.city = '';
      cityListCopy = [];
    }
    if (name === 'zip') {
      requiredGotFilled({ ...e, target: { ...e.target, name: 'city', value: copy.city } });
    }
    setCim(copy);
    setCityList(cityListCopy);
  };

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
  };
  const handleFocus = e => {
    removeErrors(e);
  };

  useEffect(() => {
    setFields(['name', 'zip', 'city', 'street', 'streetType', 'house']);
    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { classes } = props;

  const setLinksOnLabels = name => {
    switch (name) {
      case 'cb-1':
        return t('paymentSumCheckBox2');
      case 'cb-2':
        return (
          <>
            {t('paymentSumCheckBox3-1')}{' '}
            <Link style={{ color: 'black' }} target="_blank" href={`${API_URL}/common-services/document?id=${state.docAszf}`}>
              <strong>{t('paymentSumCheckBox3-2')}</strong>
            </Link>{' '}
            {t('paymentSumCheckBox3-3')}
          </>
        );
      case 'cb-3':
        return t('paymentSumCheckBox4');
      case 'cb-4':
        return (
          <>
            {t('paymentSumCheckBox5')}
            <Link
              style={{ color: 'black' }}
              target="_blank"
              href="https://allyoucanmove.hu/content_files/AYCM_SportPass_Felhasznalasi_Szabalyzat.pdf">
              <strong>{t('paymentSumCheckBox5-2')}</strong>
            </Link>
            {t('paymentSumCheckBox5-3')}
          </>
        );
      case 'cb-5':
        return (
          <>
            {t('paymentSumCheckBox6-1')}
            <Link style={{ color: 'black' }} target="_blank" href="https://allyoucanmove.hu/content_files/adatkezelesi_tajekoztato.pdf">
              <strong>{t('paymentSumCheckBox6-2')}</strong>
            </Link>
            {t('paymentSumCheckBox6-3')}
            <Link style={{ color: 'black' }} target="_blank" href="https://allyoucanmove.hu/online-felhasznalasi-feltetelek">
              <strong style={{ color: 'black' }}>{t('paymentSumCheckBox6-4')}</strong>
            </Link>
            {t('paymentSumCheckBox6-5')}
          </>
        );
      default:
    }
  };

  return (
    <>
      <ErrorDialog
        showError={state.otpError}
        title={t('button.payment')}
        content={state.dialogContent}
        handleClose={closeErrorDialog}
        icon="warning"
      />

      <Typography component="section">
        <Grid container component="article">
          <Grid item xs={12}>
            <Box mx="auto" className={classes.box}>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <Typography variant="subtitle2" component="h6" style={{ color: 'black', fontWeight: '700', marginBottom: '16px' }}>
                    {t('label.nowPayable')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box display={state.method !== '4' ? 'block' : 'none'}>
                    <FormControl fullWidth>
                      <PaymentMonths
                        id="paymentMonths"
                        name="paymentMonths"
                        variant="outlined"
                        margin="dense"
                        validFrom={state.validFrom}
                        value={state.paymentMonths}
                        onChange={handleOnPaymentChange}
                        displayEmpty
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display={state.method !== '31' ? 'block' : 'none'}>
                    <FormControl fullWidth>
                      <InputWrapper inputStyle="Main" label={t('paymentType')}>
                        <Select
                          variant="outlined"
                          margin="dense"
                          value={state.payMethod}
                          onChange={handleOnPaymentChange}
                          name="payMethod"
                          displayEmpty>
                          {
                            // Fizetési módok
                            // 2021-05-14 pjung TNRUKAYCM-1113 +A OTP SZÉP kártya
                            // 2021-11-29 pjung TNRUKAYCM-1284 -15 OTP Cafeteria (Ajándék zseb)
                            ['4', '31', 'A'].map((text, index) => {
                              const tt = t(`value.fizmod.${text}`);
                              return (
                                <MenuItem value={text} key={index}>
                                  {tt}
                                </MenuItem>
                              );
                            })
                          }
                        </Select>
                      </InputWrapper>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper label={t('label.allPayable')}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      disabled
                      style={{ width: '100%' }}
                      inputProps={{
                        style: { textAlign: 'left', color: 'black' }
                      }}
                      value={`${priceMask(state.paymentSum)} ${t('msg.forint')} ${t('withAdministrationFee')}`}
                    />
                  </InputWrapper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* 2021-12-02 pjung TNRUKAYCM-1293 Új szöveg
              2022-11-29 pjung TNRUKAYCM-1472 Rendszeres kártya esetén nem jelenik meg
              2023-01-05 pjung TNRUKAYCM-1548 Megszűntek a zsebek, nem kell megjeleníteni
          <Grid item xs={12} style={{ marginTop: '8px', marginBottom: '50px', display: state.method !== '31' ? 'block' : 'none' }}>
            <img src={Warning} alt="!" style={{ float: 'left', maxWidth: '75px', marginRight: '16px' }} />
            <Typography variant="subtitle2" component="h6" style={{ fontWeight: 'bold', fontSize: '13px', paddingTop: '12px' }}>
              {t('label.szep.zseb')}
            </Typography>
          </Grid>
          */}

          {/* 2021-06-04 pjung TNRUKAYCM-1120 SZÉP Kártya adatok mezői */}
          <Grid container style={{ display: state.payMethod === 'A' ? 'block' : 'none' }}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                component="h6"
                style={{
                  color: 'red',
                  fontWeight: '700',
                  fontSize: '14px'
                }}>
                {t('szepCardOwnerMsg')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" style={{ margin: '20px 11px' }}>
                <RadioGroup aria-label={t('szepCardOwner')} id="sajat" name="sajat" onChange={handleSajat} value={fSajat}>
                  <FormControlLabel
                    value
                    control={<Radio color="primary" icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBox />} />}
                    label={t('szepCardOwnerMe')}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBox />} />}
                    label={t('szepCardOwnerNotMe')}
                  />
                </RadioGroup>
                {/* <Box display={hasErrors('sajat') ? 'block' : 'none'}><FormHelperText>{t('validate_require')}</FormHelperText></Box> */}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box ms="auto" display={state.payMethod !== 'A' || fSajat !== false ? 'none' : 'block'}>
                <InputWrapper inputStyle="Main" label={t('szepCardOwnerName')} style={{ marginBottom: '10px' }}>
                  <TextField
                    fullWidth
                    disabled={state.payMethod !== 'A' || fSajat !== false}
                    id="name"
                    name="name"
                    variant="outlined"
                    margin="dense"
                    value={fPart}
                    onChange={handlePart}
                    onBlur={e => handleBlur(e, ['required'])}
                    onFocus={handleFocus}
                  />
                </InputWrapper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box ms="auto">
                <Address
                  required
                  hidden={state.payMethod !== 'A' || fSajat !== false}
                  addressType="home"
                  address={fCim}
                  cityList={cityList}
                  namePrefix=""
                  handleOnAddressChange={handleCim}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  errorList={errorList}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xy={12}>
            <Box>
              <Typography
                variant="subtitle2"
                component="h6"
                style={{
                  textAlign: 'left',
                  marginTop: '8px',
                  marginBottom: '24px'
                }}>
                {t('label.periodicFee')}
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <InputWrapper label={t('label.fee1')}>
                    <TextField
                      id="foosszeg"
                      name="foosszeg"
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{
                        style: { textAlign: 'left', color: 'black', fontWeight: 'normal' }
                      }}
                      value={`${priceMask(state.fees.fosszeg)} ${t('msg.forint')}`}
                    />
                  </InputWrapper>
                </Grid>

                {/* 2020-11-23 pjung TNRUKAYCM-928 Túlfizetés beszámolása */}
                <Grid item xs={12} hidden={state.tulfiz === 0}>
                  <InputWrapper label={t('label.tulfiz')}>
                    <TextField
                      id="tulfiz"
                      name="tulfiz"
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{
                        style: { textAlign: 'left', color: 'black', fontWeight: 'normal' }
                      }}
                      value={`${priceMask(-state.tulfiz)} ${t('msg.forint')}`}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12} hidden={state.tulfiz === 0}>
                  <InputWrapper label={t('label.reszosszeg')}>
                    <TextField
                      id="reszosszeg"
                      name="reszosszeg"
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{
                        style: { textAlign: 'left', color: 'black', fontWeight: 'normal' }
                      }}
                      value={`${priceMask(state.reszosszeg)} ${t('msg.forint')}`}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12} hidden={state.tulfiz === 0}>
                  <InputWrapper label={t('label.tulfiz.marad')}>
                    <TextField
                      id="marad"
                      name="marad"
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{
                        style: { textAlign: 'left', color: 'black', fontWeight: 'normal' }
                      }}
                      value={`${priceMask(-Math.min(0, state.fees.fosszeg - state.tulfiz))} ${t('msg.forint')}`}
                    />
                  </InputWrapper>
                </Grid>

                <Grid item xs={12}>
                  <InputWrapper label={t('label.fee2')}>
                    <TextField
                      id="fdij1"
                      name="fdij1"
                      variant="outlined"
                      margin="dense"
                      disabled
                      style={{ width: '100%' }}
                      inputProps={{
                        style: { textAlign: 'left', color: 'black', fontWeight: 'normal' }
                      }}
                      value={`${priceMask(state.fees.fdij1)} ${t('msg.forint')} ${t('withOverpayment')}`}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper label={t('label.feeSum')}>
                    <TextField
                      id="feeSum"
                      name="feeSum"
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{
                        style: { textAlign: 'left', color: 'black', fontWeight: 'normal' }
                      }}
                      value={`${priceMask(state.feeSum)} ${t('msg.forint')}`}
                    />
                  </InputWrapper>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '24px', marginBottom: '16px' }}>
            <Typography variant="h6" component="h6" className={classes.subHeading} style={{ textTransform: 'uppercase', fontSize: '14px' }}>
              {t('paymentSumTitle')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.checkAll}>
            <FormControlLabel
              control={<Checkbox id="checkAll" name="checkAll" color="primary" checked={state.checkedFinal} onChange={handleCheckAll} />}
            />
            <label htmlFor="checkAll" style={{ fontSize: '13px', fontWeight: '700', color: 'rgba(49, 139, 129, 1)' }}>
              {t('acceptAll')}
            </label>
          </Grid>
          <Grid item xs={12}>
            <FinalCheck handleCheckboxes={handleCheckboxes} checkedItems={state.checkedItems} name="cb-1" label={setLinksOnLabels} />
          </Grid>
          <Grid item xs={12}>
            <FinalCheck handleCheckboxes={handleCheckboxes} checkedItems={state.checkedItems} name="cb-2" label={setLinksOnLabels} />
          </Grid>
          <Grid item xs={12}>
            <FinalCheck handleCheckboxes={handleCheckboxes} checkedItems={state.checkedItems} name="cb-3" label={setLinksOnLabels} />
          </Grid>
          <Grid item xs={12}>
            <FinalCheck handleCheckboxes={handleCheckboxes} checkedItems={state.checkedItems} name="cb-4" label={setLinksOnLabels} />
          </Grid>
          <Grid item xs={12}>
            <FinalCheck handleCheckboxes={handleCheckboxes} checkedItems={state.checkedItems} name="cb-5" label={setLinksOnLabels} />
          </Grid>
        </Grid>
      </Typography>
      <Grid container justify="flex-end" style={{ marginTop: '30px' }}>
        <Button variant="outlined" color="secondary" onClick={prevStep}>
          {t('back')}
        </Button>
        <Button variant="outlined" color="primary" onClick={finish} style={{ marginLeft: '10px' }} disabled={disableButton() || !state.payMethod}>
          {t('button.pay')}
        </Button>
      </Grid>
    </>
  );
};

//
export default withStyles(styles)(withRouter(PaymentData));
