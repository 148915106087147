import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import InputWrapper from './InputWrapper';
import useValidator from '../../utils/useValidator';
import { authService } from '../../services/authentication';

const ChangeEmail = ({ currentEmail }) => {
  const [email, setEmail] = useState('');

  const {
    setErrors, removeErrors, hasErrors, didFillEverything, setFields, resetErrors, giveErrors,
  } = useValidator();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFields(['email']);

    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentEmail) { setEmail(currentEmail); }
  }, [currentEmail]);

  const handleFocus = (e) => {
    removeErrors(e);
  };

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleEmailSubmit = async () => {
    if (didFillEverything({ email }).length === 0) {
      try {
        const response = await authService.changeEmail({ email });
        if (response.data.status === 'OK') {
          enqueueSnackbar(t('emailSent'), {
            variant: 'success',
          });
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    } else {
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error',
        // persist: true,
      });
      didFillEverything({ email }).map((input) => giveErrors(input, key));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <InputWrapper
          inputStyle={hasErrors('email') ? 'Error' : 'Main'}
          label={t('privateEmail')}
        >
          <TextField
            fullWidth
            id="email"
            name="email"
            variant="outlined"
            margin="dense"
            required
            value={email}
            onChange={handleEmailChange}
            onBlur={(e) => handleBlur(e, ['isEmail'])}
            onFocus={handleFocus}
          />
        </InputWrapper>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button
          variant="outlined"
          color="primary"
          type="button"
          onClick={handleEmailSubmit}
          disabled={!email.length}
          style={{ marginBottom: '10px' }}
        >
          {t('saveButton')}
        </Button>
      </Grid>
    </Grid>
  );
};

ChangeEmail.propTypes = {
  currentEmail: PropTypes.string,
};

ChangeEmail.defaultProps = {
  currentEmail: '',
};

export default ChangeEmail;
