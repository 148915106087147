import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { contractService } from '../../services/contracting';
import InputWrapper from './InputWrapper';

class StreetTypeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }

  /**
   * A közterületek megnevezése (pl.: út, utca) a listStreetTypes backend szervíz által visszaadott
   * közterület típusokból, egy Select választóval kerül kitöltésre, így egységes lesz.
   */

  async componentDidMount() {
    try {
      const response = await contractService.listStreetTypes();
      if (response.data.status === 'OK') {
        this.setState({
          list: response.data.list,
        });
      } else {
        response.data.messages.map((message) => this.props.enqueueSnackbar(this.props.t(message), {
          variant: 'error',
        }));
      }
    } catch (e) {
      this.props.enqueueSnackbar(this.props.t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <FormControl error={this.props.error} fullWidth={this.props.fullWidth}>
        <InputWrapper inputStyle={this.props.type} label={t('streetType')}>
          <Select
            variant="outlined"
            margin="dense"
            id={this.props.id}
            name={this.props.name}
            value={this.props.value}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            MenuProps={{
              style: {
                maxHeight: '350px',
              },
            }}
          >
            {this.state.list.map((text, index) => (
              <MenuItem value={text} key={index}>
                {text}
              </MenuItem>
            ))}
          </Select>
        </InputWrapper>
        <Box display={this.props.error ? 'block' : 'none'}>
          <FormHelperText>{t('validate_require')}</FormHelperText>
        </Box>
      </FormControl>
    );
  }
}

export default withTranslation()(withSnackbar(StreetTypeSelect));
