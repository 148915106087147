import React from 'react';

import { getYear, getMonth, parseISO } from 'date-fns/esm';
import { useTranslation } from 'react-i18next';

import { HO } from '../../utils/Consts';
import { getName } from './Tools';


/**
 * A dinamikusan változó választási opciók a segédfüggvényeknek passzolt propok alapján dől el
 */
export function CardDataLabel(props) {
  const { t } = useTranslation();
  // const res = t('cardData');
  // if (props.cardType === 'NFC') {
  //   res += ' ' + t('plastic');
  // } else if (props.cardType === 'EPASS') {
  //   res += ' ' + t('epass');
  // }

  // if (props.kr) {
  //   res += ` (${props.kr})`;
  // }
  // if (props.update) {
  //   res += ' - ' + t('update');
  // }
  return <div>{t('cardData')}</div>;
}

export function PersonalDataLabel(props) {
  const { t } = useTranslation();
  let res = t('personalData');
  if (props.partner) {
    res += ` - ${getName(props.partner)}`;
  }
  if (props.update) {
    res += ` - ${t('update')}`;
  }
  return <div>{res}</div>;
}

export function ContractDataLabel(props) {
  const { t } = useTranslation();
  let res = t('contractData');
  if (props.contract) {
    if (props.contract.validFrom) {
      const date = parseISO(props.contract.validFrom);
      const year = getYear(date);
      const month = getMonth(date);
      res += ` ${year.toString()}`;
      res += ` ${HO[month]}`;
    }
  }

  if (props.update) {
    res += ` - ${t('update')}`;
  }
  return <div>{res}</div>;
}

export function InvoicingDataLabel(props) {
  const { t } = useTranslation();
  let res = t('invoicingData');
  if (props.ownAddress) {
    res += ` ${t('invoiceCB')}`;
  }

  if (props.update) {
    res += ` - ${t('update')}`;
  }
  return <div>{res}</div>;
}
