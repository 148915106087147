import {
  Button,
  Grid,
  Typography,
  Box,
  withStyles,
  Link,
  TextField,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { useSnackbar } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import enLocale from 'date-fns/locale/en-US';
import huLocale from 'date-fns/locale/hu';
import InputWrapper from '../../components/ui/InputWrapper';
import { checkTaxId, checkCardNumMatch } from '../../components/utils/Tools';
import { authService } from '../../services/authentication';
import { contractService } from '../../services/contracting';
import { formatDate, isBlank } from '../../utils/Utils';
import Warning from '../../assets/images/figyelmezteto_tabla_zold.png';
import { styles } from '../../theme/styles';
import useValidator from '../../utils/useValidator';

const PartnerData = (props) => {
  const [partnerData, setPartnerData] = useState({
    kr: '',
    type: '',
    firstname: '',
    lastname: '',
    birthDate: null,
    birthPlace: '',
    mothersName: '',
    tax: '',
    oldCardNum: '',
    oldCardNumFromService: '',
    oldKR: '',
  });

  const [hasPrevCard, setHasPrevCard] = useState(false);

  const [taxIdError, setTaxIdError] = useState(false);
  const [disabledFields, setDisabledFields] = useState({});
  const [locale, setLocale] = useState('hu');

  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const localeMap = {
    en: enLocale,
    hu: huLocale,
  };

  const {
    setErrors,
    removeErrors,
    resetErrors,
    setFields,
    didFillEverything,
    giveErrors,
    hasErrors,
  } = useValidator();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: '/step1'});

    const user = authService.currentUser();

    const fetchData = async () => {
      try {
        const response = await contractService.get(
          1,
          sessionStorage.contractKey,
        );
        if (response.data.status === 'OK') {
          const { type, partner } = response.data;

          const disabledFieldsCopy = {};
          Object.keys(partner).forEach((i) => (disabledFieldsCopy[i] = !!partner[i]));

          setPartnerData({
            ...partnerData,
            type,
            kr: partner.kr ? partner.kr : '',
            firstname: partner.firstname ? partner.firstname : user.firstname,
            lastname: partner.lastname ? partner.lastname : user.lastname,
            birthDate: partner.birthDate ? partner.birthDate : null,
            birthPlace: partner.birthPlace ? partner.birthPlace : '',
            mothersName: partner.mothersName ? partner.mothersName : '',
            tax: partner.tax ? partner.tax : '',
          });

          setDisabledFields({ ...disabledFieldsCopy });
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (error) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    };

    if (sessionStorage.contractKey) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (hasPrevCard) {
      setFields(['firstname', 'lastname', 'birthPlace', 'birthDate', 'tax', 'mothersName', 'oldCardNum']);
    } else {
      setFields(['firstname', 'lastname', 'birthPlace', 'birthDate', 'tax', 'mothersName']);
    }
    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPrevCard]);

  const handleOnPartnerChange = (event) => {
    const { name, value } = event.target;
    setPartnerData({ ...partnerData, [name]: value });
  };

  const handleDateChange = (date) => {
    setPartnerData({ ...partnerData, birthDate: date });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (didFillEverything(partnerData).length === 0) {
      try {
        const partner = {
          kr: partnerData.kr,
          firstname: partnerData.firstname,
          lastname: partnerData.lastname,
          birthDate: formatDate(partnerData.birthDate),
          birthPlace: partnerData.birthPlace,
          mothersName: partnerData.mothersName,
          tax: partnerData.tax,
        };
        const response = await contractService.submit(1, {
          key: sessionStorage.contractKey,
          partner,
        });
        if (response.data.status === 'OK') {
          props.nextStep();
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (e) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    } else {
      const errors = didFillEverything(partnerData);
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error',
      });
      errors.map((field) => giveErrors(field, key));
    }
  };

  const checkTax = async () => {
    try {
      const response = await contractService.findByTax({
        tax: partnerData.tax,
        birth: formatDate(partnerData.birthDate),
      });
      if (response.data.status === 'OK') {
        if (response.data.card) {
          setHasPrevCard(true);
        }
        setPartnerData({
          ...partnerData,
          oldCardNumFromService: response.data.card,
          oldKR: response.data.kr,
        });
        setTaxIdError(false);
      } else {
        setTaxIdError(true);
        response.data.messages.map((message) => {
          const key = enqueueSnackbar(t(message), {
            variant: 'error',
          });

          return giveErrors('tax', key);
        });
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
  };

  const checkCardNum = () => {
    if (!isBlank(partnerData.oldCardNum)) {
      if (partnerData.oldCardNum === partnerData.oldCardNumFromService) {
        setPartnerData({
          ...partnerData,
          kr: partnerData.oldKR,
        });
      }
    }
  };

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
    if (e.target.name === 'tax') {
      if (e.target.value.length) {
        checkTax();
        const result = checkTaxId(e.target.value, partnerData.birthDate);
        if (!result) {
          if (e.target.value.length < 10) {
            const key = enqueueSnackbar(t('validate_taxId'), {
              variant: 'error',
            });
            giveErrors('tax', key);
          } else {
            const key = enqueueSnackbar(t('validate_taxOrBirth'), {
              variant: 'error',
            });
            giveErrors('tax', key);
          }
        }
      }
    } else if (e.target.name === 'oldCardNum') {
      checkCardNum();
      const result = checkCardNumMatch(partnerData.oldCardNum, partnerData.oldCardNumFromService);
      if (!result) {
        const key = enqueueSnackbar(t('validate_isCardNumMatch'), {
          variant: 'error',
        });
        giveErrors('oldCardNum', key);
      }
    }
  };

  const handleFocus = (e) => {
    removeErrors(e);
  };

  const { classes } = props;

  return (
    <>
      <form onSubmit={handleSubmit} onError={(errors) => console.error(errors)}>
        <Typography component="section">
          <Grid container component="article">
            <Grid item xs={12}>
              <InputWrapper inputStyle={hasErrors('firstname') ? 'Error' : 'Main'} label={t('firstName')}>
                <TextField
                  fullWidth
                  id="firstname"
                  name="firstname"
                  margin="dense"
                  variant="outlined"
                  disabled
                  value={partnerData.firstname}
                  onChange={handleOnPartnerChange}
                  onBlur={(e) => handleBlur(e, ['required'])}
                  onFocus={handleFocus}
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper inputStyle={hasErrors('lastname') ? 'Error' : 'Main'} label={t('lastName')}>
                <TextField
                  id="lastname"
                  name="lastname"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  disabled
                  value={partnerData.lastname}
                  onChange={handleOnPartnerChange}
                  onBlur={(e) => handleBlur(e, ['required'])}
                  onFocus={handleFocus}
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper inputStyle={hasErrors('birthPlace') ? 'Error' : 'Main'} label={t('birthPlace')}>
                <TextField
                  id="birthPlace"
                  name="birthPlace"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  disabled={
                    (partnerData.type === 'U' && disabledFields.birthPlace)
                    || (partnerData.type === 'H' && disabledFields.birthPlace)
                  }
                  value={partnerData.birthPlace}
                  onChange={handleOnPartnerChange}
                  onBlur={(e) => handleBlur(e, ['required'])}
                  onFocus={handleFocus}
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                <InputWrapper
                  inputStyle={hasErrors('birthDate') ? 'Error' : 'Main'}
                  label={t('birthDate')}
                  icon="EventIcon"
                >
                  <DatePicker
                    autoOk
                    disableFuture
                    fullWidth
                    margin="dense"
                    id="birthDate"
                    name="birthDate"
                    inputVariant="outlined"
                    disabled={
                      (partnerData.type === 'U' && disabledFields.birthDate)
                      || (partnerData.type === 'H' && disabledFields.birthDate)
                    }
                    value={partnerData.birthDate}
                    initialFocusedDate={new Date('2000-01-01')}
                    format="yyyy.MM.dd"
                    onChange={(date) => handleDateChange(date)}
                    views={['year', 'month', 'date']}
                    openTo="year"
                    invalidDateMessage={t('validate_date')}
                    variant="inline"
                    // onBlur={(e) => handleBlur(e, ['required'])}
                    onFocus={handleFocus}
                  />
                </InputWrapper>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper inputStyle={hasErrors('tax') ? 'Error' : 'Main'} label={t('tax')}>
                <TextField
                  fullWidth
                  id="tax"
                  name="tax"
                  variant="outlined"
                  margin="dense"
                  disabled={
                    (partnerData.type === 'U' && disabledFields.tax)
                    || (partnerData.type === 'H' && disabledFields.tax)
                  }
                  value={partnerData.tax}
                  onChange={handleOnPartnerChange} // TODO
                  inputProps={{ maxLength: 10 }}
                  onBlur={(e) => handleBlur(e, ['required', 'isTaxValid'])}
                  onFocus={handleFocus}
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <InputWrapper inputStyle={hasErrors('mothersName') ? 'Error' : 'Main'} label={t('mothersName')}>
                <TextField
                  fullWidth
                  id="mothersName"
                  name="mothersName"
                  variant="outlined"
                  margin="dense"
                  disabled={
                    (partnerData.type === 'U' && disabledFields.mothersName)
                    || (partnerData.type === 'H' && disabledFields.mothersName)
                  }
                  value={partnerData.mothersName}
                  onChange={handleOnPartnerChange}
                  onBlur={(e) => handleBlur(e, ['required'])}
                  onFocus={handleFocus}
                />
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <Box
                display={
                  !isBlank(partnerData.oldCardNumFromService) ? 'block' : 'none'
                }
              >
                <InputWrapper inputStyle={hasErrors('oldCardNum') ? 'Error' : 'Main'} label={t('oldCardNum')}>
                  <TextField
                    fullWidth
                    id="oldCardNum"
                    name="oldCardNum"
                    variant="outlined"
                    margin="dense"
                    value={partnerData.oldCardNum}
                    onChange={handleOnPartnerChange} // TODO
                    onBlur={(e) => handleBlur(e, ['required', 'isCardNumMatch'])}
                    onFocus={handleFocus}
                  />
                </InputWrapper>
                <Grid item xs={12}>
                  <Grid
                    container
                    className={classes.warningField}
                    alignItems="center"
                    justify="flex-start"
                  >
                    <Grid item xs={12} sm="auto" className="warningSign">
                      <img src={Warning} alt="!" />
                    </Grid>
                    <Grid item xs={12} sm className="warningText">
                      <p>
                        {t('noOldCard')}
                        <Link
                          href="https://allyoucanmove.hu/kapcsolat/kapcsolat"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: 'black', fontWeight: '700' }}
                        >
                          {t('noOldCard2')}
                        </Link>
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: 'right', marginTop: '30px' }}
            >
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={taxIdError}
              >
                {t('next')}
              </Button>
            </Grid>
          </Grid>
        </Typography>
      </form>
    </>
  );
};

PartnerData.propTypes = {
  nextStep: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(PartnerData);
