import { Box, Grid, Typography, TextField, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/icons/CheckBox';
import CheckboxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { contractService } from '../services/contracting';
import { isArrayNotEmpty, isBlank } from '../utils/Utils';
import useValidator from '../utils/useValidator';

import { styles } from '../theme/styles';
import InputWrapper from '../components/ui/InputWrapper';
import ErrorDialog from '../components/ui/ErrorDialog';
import LoaderIcon from '../components/ui/LoaderIcon';
import { intraService } from '../services/intranet';

const ContractingInitUpgradeMultiPage = props => {
  const [state, setState] = useState({
    roleError: false,
    type: 'U',
    year: '',
    officeEmail: '',
    employer: 0,
    showError: false
  });

  const [dialog, setDialog] = useState({
    open: false,
    content: null,
    icon: '',
    disabled: false
  });

  const [emailSent, setEmailSent] = useState(false);

  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [pressedEnter, setPressedEnter] = useState(false);

  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { classes, isPackageXXL } = props;
  const history = useHistory();
  // Kötőjelet ellenőrző regexp
  const hyphenRegexp = /^([a-zA-Z0-9]*-[a-zA-Z0-9]*)+$/;

  const { giveErrors, hasErrors, setErrors, removeErrors } = useValidator();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await contractService.initUpgrade();
        if (response.data.status === 'OK') {
          //2020-10-19 szbiro TNRUKAYCM-838 intranet folyamatban lévő státuszok kezelése
          if (response.data.token && !response.data.token.match(hyphenRegexp)) {
            if (response.data.type === 'U') {
              sessionStorage.setItem('type', 1);
            } else {
              sessionStorage.setItem('type', 0);
            }
            history.push(`/contracting/options/${response.data.token}`);
          }

          if (isArrayNotEmpty(response.data.messages)) {
            setErrorMessages(response.data.messages);
          } else if (response.data.type.length > 1) {
            const intraMessage = response.data.type.toLowerCase();
            if (intraMessage !== 'elutasitott') {
              setErrorMessages([`intra.status.${intraMessage}`]);
            }
          } else if (response.data.key && response.data.step) {
            sessionStorage.setItem('step', response.data.step);
            sessionStorage.setItem('contractKey', response.data.key);
            sessionStorage.setItem('type', response.data.type);
            history.push('/contracting');
          } else {
            setState({
              ...state,
              type: response.data.type,
              year: response.data.year
            });
            setErrorMessages([]);
          }
        } else {
          setErrorMessages(response.data.messages);
        }
      } catch (e) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkIfNeedsPopup = async () => {
      const response = await intraService.hasMessage();

      if (response.data.status === 'OK') {
        if (response.data.id !== 0) {
          const closeDialogContent = async () => {
            const resp = await intraService.closeMessage(response.data.id);

            if (resp.data.status === 'OK') {
              setDialog({
                open: false,
                content: null,
                icon: '',
                disabled: true
              });
            }
          };

          const dialogContent = (
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  component="h6"
                  style={{
                    color: 'black',
                    fontWeight: '700',
                    fontSize: '14px',
                    marginTop: '24px'
                  }}>
                  {t('intra.contractDenied')}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'right', marginBottom: '24px' }}>
                <Button variant="outlined" color="primary" onClick={closeDialogContent}>
                  {t('accept')}
                </Button>
                <Grid item xs={12}>
                  <Box className={props.classes.popupBottom} />
                </Grid>
              </Grid>
            </Grid>
          );

          setDialog({
            open: true,
            content: dialogContent,
            icon: 'warning',
            disabled: true
          });
        }
      }
    };

    checkIfNeedsPopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorMessages.length) {
      setState({ ...state, roleError: true });
    } else {
      setState({ ...state, roleError: false });
    }
    setTimeout(() => setLoading(false), 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessages]);

  const checkUserEmail = async () => {
    if (!isBlank(state.officeEmail)) {
      try {
        const emailResp = await contractService.checkEmail(state.officeEmail);
        if (emailResp.data.status === 'OK' && !emailResp.data.inUse) {
          const response = await contractService.listEmployersByDomain({
            type: state.type,
            email: state.officeEmail
          });
          if (response.data.status === 'OK') {
            setEmployers(response.data.list);

            if (!isArrayNotEmpty(response.data.list)) {
              setState({ ...state, employer: 0 });
              setEmployers([]);
              const key = enqueueSnackbar(t('EMAIL_INVALID'), {
                variant: 'error'
                // persist: true,
              });
              giveErrors('officeEmail', key);
            }
          } else {
            response.data.messages.map(message =>
              enqueueSnackbar(t(message), {
                variant: 'error'
              })
            );
          }
        } else if (emailResp.data.status === 'OK' && emailResp.data.inUse) {
          setState({ ...state, employer: 0 });
          setEmployers([]);
          const key = enqueueSnackbar(t('emailInUse'), {
            variant: 'error'
            // persist: true,
          });
          giveErrors('officeEmail', key);
        }
      } catch (e) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
    }
  };

  const handleBlur = (e, rules) => {
    if (e.target.name === 'officeEmail' && !pressedEnter) checkUserEmail();
    setErrors(e, rules);
  };

  const handleEnter = (event, rules) => {
    const { charCode } = event;

    if (charCode === 13) {
      checkUserEmail();
      setPressedEnter(true);
      setErrors(event, rules);
    }
  };

  const handleFocus = e => {
    removeErrors(e);
    setPressedEnter(false);
  };

  const handleOnChange = event => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
    if (event.target.name === 'officeEmail') {
      setState({ ...state, [name]: value, employer: 0 });
      setEmployers([]);
    }
    setPressedEnter(false);
    removeErrors(event);
  };

  const handleSnackbar = snackbarKey => {
    closeSnackbar(snackbarKey);
    setEmailSent(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      // TODO
      const { key, kartya_fajta } = employers.find(o => o.id === state.employer);
      const response = await contractService.start({
        type: state.type,
        employer: state.employer,
        email: state.officeEmail,
        // TODO
        key,
        kartya_fajta
      });

      if (response.data.status === 'OK') {
        setEmailSent(true);
        const action = snackbarKey => (
          <>
            <Button onClick={() => handleSnackbar(snackbarKey)}>OK</Button>
          </>
        );

        enqueueSnackbar(t('privateEmailSent'), {
          variant: 'success',
          // persist: true,
          action
        });
        setState({
          ...state,
          officeEmail: '',
          employer: 0
        });
      } else {
        response.data.messages.map(message =>
          enqueueSnackbar(t(message), {
            variant: 'error'
          })
        );
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error'
      });
    }
  };

  const basic = () => {
    const filtered = employers.filter(employer => employer.key === 0);
    return filtered.map(employer => (
      <FormControlLabel
        key={employer.id}
        value={employer.id}
        control={<Radio color="primary" icon={<CheckboxOutlineBlank />} checkedIcon={<Checkbox />} />}
        label={employer.name}
        style={{ marginLeft: '0' }}
      />
    ));
  };

  const intra = () => {
    const filtered = employers.filter(employer => employer.key === 1);
    return filtered.map(employer => (
      <FormControlLabel
        key={employer.id}
        value={employer.id}
        control={<Radio color="primary" icon={<CheckboxOutlineBlank />} checkedIcon={<Checkbox />} />}
        label={employer.name}
        style={{ marginLeft: '0' }}
      />
    ));
  };

  return (
    <Typography component="section" style={{ padding: '16px 32px 0px 32px' }}>
      {loading ? (
        <Grid container justify="flex-start">
          <Grid item xs={12}>
            <h2 style={{ fontSize: '14px', marginBottom: '0px' }}>{t('upgradingHeading')}</h2>
          </Grid>
          <LoaderIcon />
        </Grid>
      ) : (
        <>
          <ErrorDialog
            showError={dialog.open}
            icon={dialog.icon}
            disabled={dialog.disabled}
            content={dialog.content}
            handleClose={() => {
              setDialog({ open: false, content: '', title: '' });
            }}
          />
          <form onSubmit={handleSubmit} onError={errors => console.error(errors)}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <h2 style={{ fontSize: '14px', marginBottom: '0px' }}>{t('upgradingHeadingMulti')}</h2>
              </Grid>
              <Grid item xs={12}>
                <Box display={state.roleError ? 'block' : 'none'}>
                  {errorMessages.map(message => (
                    <Grid item xs={12} key={message}>
                      <Grid container className={classes.warningField} alignItems="center" justify="flex-start">
                        <Grid item xs={12} sm className="warningText">
                          <p>{t(message)}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display={state.roleError ? 'none' : 'block'}>
                  <Grid container className={classes.warningField}>
                    <Grid item xs={12} sm>
                      {isPackageXXL ? <p>{t('noBiggerPackageMulti')}</p> : <p>{t('contracting.subtitle3.upgrade')}</p>}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box style={{ marginTop: '-12px' }} width="100%" display={state.roleError || isPackageXXL ? 'none' : 'block'}>
                  <InputWrapper inputStyle={hasErrors('officeEmail') ? 'Error' : 'Multi'} label={t('officeEmailMulti')}>
                    <TextField
                      fullWidth
                      id="officeEmail"
                      name="officeEmail"
                      variant="outlined"
                      margin="dense"
                      disabled={emailSent}
                      value={state.officeEmail}
                      onChange={handleOnChange}
                      onBlur={e => handleBlur(e, ['required', 'isEmail'])}
                      onKeyPress={e => handleEnter(e, ['required', 'isEmail'])}
                      onFocus={handleFocus}
                    />
                  </InputWrapper>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  mx="auto"
                  width="100%"
                  display={state.roleError || (state.employer === 0 && !state.officeEmail) || isPackageXXL ? 'none' : 'block'}>
                  <FormControl fullWidth component="fieldset" style={{ marginTop: '20px' }}>
                    <FormLabel
                      component="legend"
                      style={{
                        fontWeight: '700',
                        fontSize: '14px',
                        color: 'black',
                        marginBottom: '16px'
                      }}>
                      {employers.length ? t('employer') : ''}
                    </FormLabel>
                    <RadioGroup aria-label={t('paymentType')} id="employer" name="employer" onChange={handleOnChange} value={state.employer}>
                      {basic().length ? <p style={{ margin: '6px 0' }}>{t('basicOfferHeading')}</p> : null}
                      {basic()}
                      {intra().length ? <p style={{ margin: '6px 0' }}>{t('companyOfferHeading')}</p> : null}
                      {intra()}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Box display={state.roleError || isPackageXXL ? 'none' : 'block'} mb={2} mt={2}>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={!state.employer || state.employer === 0}
                    style={{ width: 'auto', textTransform: 'none' }}>
                    {t('button.contractingUpgrade')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Typography>
  );
};

ContractingInitUpgradeMultiPage.propTypes = {
  classes: PropTypes.shape(),
  isPackageXXL: PropTypes.bool.isRequired
};

ContractingInitUpgradeMultiPage.defaultProps = {
  classes: {}
};

export default withStyles(styles)(ContractingInitUpgradeMultiPage);
