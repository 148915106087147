import React, { useState, useEffect } from 'react';
import {
  Grid, Button, withStyles, Box, Typography, Link,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { paymentService } from '../services/payment';
import { styles } from '../theme/styles';
import ErrorDialog from '../components/ui/ErrorDialog';
import LoaderIcon from '../components/ui/LoaderIcon';
import { API_URL } from '../utils/Consts';

const InvoiceFromEmail = (props) => {
  const [params, setParams] = useState({});
  const [errors, setErrors] = useState({ open: false, dialogContent: '' });
  const [hasInvoice, setHasInvoice] = useState(false);
  const [docData, setDocData] = useState({ state: false, link: '' });

  const { invoice, token, lang } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    setParams({
      invoice,
      token,
    });
  }, [invoice, token]);

  useEffect(() => {
    if (invoice !== undefined && token !== undefined) {
      const checkData = async () => {
        try {
          const response = await paymentService.checkDownloadInvoice({
            invoice,
            token,
          });

          if (response.data.status === 'OK') {
            setHasInvoice(true);
          } else {
            setErrors({
              open: true,
              dialogContent: 'Technikai hiba',
            });
          }
        } catch (error) {
          setErrors({
            open: true,
            dialogContent: `Technikai hiba ${error}`,
          });
          setDocData({ state: true });
        }
      };

      checkData();
    }
  }, [invoice, params, token]);

  useEffect(() => {
    if (hasInvoice && docData.state === false) {
      const fetchData = async () => {
        try {
          const response = await paymentService.getDownloadInvoice({ invoice });
          if (response.data.status === 'OK') {
            setDocData({ state: true, link: response.data.docId });
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [docData.state, hasInvoice, invoice]);

  const closeErrorDialog = () => {
    setErrors({
      ...errors,
      open: false,
    });
  };

  const toggleDialog = () => {
    setErrors({
      open: true,
      dialogContent: PrintDialogContent(),
    });
  };

  const PrintDialogContent = () => (
    <>
      <Grid container>
        <Grid item xs={12} sm>
          <p style={{ textAlign: 'left', fontSize: '13px', fontWeight: '700' }}>
            {t('printWarning')}
          </p>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: 'right', marginBottom: '24px' }}>
              <Link href={`${API_URL}/common-services/download?fileId=${docData.link}`} underline="none" onClick={toggleDialog}>
                <Button variant="outlined" color="primary">{t('button.print')}</Button>
              </Link>
              <Grid item xs={12}>
                <Box className={props.classes.popupBottom} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const { classes, history } = props;

  return (
    <>
      <ErrorDialog
        showError={errors.open}
        content={errors.dialogContent}
        handleClose={closeErrorDialog}
        title=""
        icon="warning"
      />
      <Grid
        container
        justify="center"
        alignItems="stretch"
        style={{ height: '85%' }}
      >
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <p className={classes.loginHeading}>{t('printInvoice')}</p>
        </Grid>
        <Box mx="auto" style={{ textAlign: 'center' }} display={docData.state ? 'none' : 'block'}>
          <Typography variant="subtitle1" style={{ fontWeight: '700', fontSize: '14px' }}>{t('docsLoading')}</Typography>
          <LoaderIcon style={{ textAlign: 'center' }} />
        </Box>
        <Grid container justify="center" alignItems="flex-end">
          <Grid item xs={5} style={{ textAlign: 'right', marginRight: '15px' }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => history.push('/')}
            >
              {t('button.cancel')}
            </Button>
          </Grid>
          <Grid item xs={5} style={{ marginLeft: '15px' }}>
            <Button variant="outlined" color="primary" onClick={toggleDialog} disabled={!docData.state}>
              {t('button.print')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(InvoiceFromEmail);
