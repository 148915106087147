import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import InputWrapper from './InputWrapper';
import useValidator from '../../utils/useValidator';
import { authService } from '../../services/authentication';

const ChangePhone = ({ currentPhone }) => {
  const [phone, setPhone] = useState('');

  const {
    setErrors, removeErrors, hasErrors, didFillEverything, setFields, resetErrors, giveErrors,
  } = useValidator();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFields(['phone']);

    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPhone) { setPhone(currentPhone); }
  }, [currentPhone]);

  const handleFocus = (e) => {
    removeErrors(e);
  };

  const handleBlur = (e, rules) => {
    setErrors(e, rules);
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setPhone(value);
  };

  const handlePhoneSubmit = async () => {
    setFields(['phone']);
    const toCheck = { phone };
    if (didFillEverything(phone).length === 0) {
      try {
        const response = await authService.changePhone({ phoneNumber: phone });
        if (response.data.status === 'OK') {
          enqueueSnackbar(t('profileChangeSuccess'), {
            variant: 'success',
          });
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    } else {
      const errors = didFillEverything(toCheck);
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error',
      });
      errors.map((field) => giveErrors(field, key));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <InputWrapper
          inputStyle={hasErrors('tel') ? 'Error' : 'Main'}
          label={t('mobilePhone')}
        >
          <InputMask
            mask="+36/99/9999999"
            maskChar={null}
            value={phone}
            onChange={handlePhoneChange}
            onBlur={(e) => handleBlur(e, ['min14', 'required'])}
            onFocus={handleFocus}
            name="phone"
          >
            {() => (
              <TextField
                fullWidth
                id="phone"
                name="phone"
                variant="outlined"
                margin="dense"
                type="text"
              />
            )}
          </InputMask>
        </InputWrapper>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button
          variant="outlined"
          color="primary"
          type="button"
          onClick={handlePhoneSubmit}
          disabled={!phone.length}
          style={{ marginBottom: '10px' }}
        >
          {t('saveButton')}
        </Button>
      </Grid>
    </Grid>
  );
};

ChangePhone.propTypes = {
  currentPhone: PropTypes.string,
};

ChangePhone.defaultProps = {
  currentPhone: '',
};

export default ChangePhone;
