import React from 'react';
import { Grid, Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { styles } from '../../theme/styles';

const FinalCheck = props => {
  const { handleCheckboxes, checkedItems, name, label, classes } = props;
  
  return (
    <Grid container justify='center' className={classes.finalCheck}>
      <Grid item xs={12}>
        <FormControlLabel
          style={{fontSize: '13px', cursor: 'default'}}
          control={
            <>
              <Checkbox
                id={name}
                name={name}
                color='primary'
                checked={checkedItems.get(`${name}`)}
                onChange={handleCheckboxes}
              />
              <label style={{height: '100%', zIndex: '3'}}>{label(name)}</label>
            </>
          }
        />
      </Grid>
    </Grid>
  );
};

export default withTranslation()(withStyles(styles)(FinalCheck));
