import React from 'react';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import EventIcon from '@material-ui/icons/Event';
import { styles } from '../../theme/styles';

const InputWrapper = (props) => {
  const {
    classes, inputStyle, label, labelStyle, icon,
  } = props;
  return (
    <Grid
      container
      className={`${classes.inputWrapper} ${classes[`inputWrapper${inputStyle}`]}`}
      style={props.children?.props?.disabled && props.children.props.name !== 'employer' ? { border: 'none' } : {}}
    >
      {label ? (
        <Grid item xs={12} md={props.children ? 5 : 11} style={{ textAlign: 'left' }}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs>
              {props.children?.type?.muiName === 'Select' ? (
                <label
                  htmlFor={props.children?.props?.name}
                  className={`${classes.inputLabel} ${labelStyle}`}
                  onClick={() => document.getElementById(props.children?.props?.name).click()}
                >
                  {label}
                </label>
              ) : (
                <label htmlFor={props.children?.props?.name} className={`${classes.inputLabel} ${labelStyle}`}>
                  {label}
                </label>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {props.children ? (
        <Grid item xs={11} md zeroMinWidth>
          {props.children}
        </Grid>
      ) : null}
      <Grid item xs={1} md="auto" style={{ paddingRight: '16px' }}>
        {icon ? (
          <label htmlFor={props.children?.props?.name} style={{ display: 'inline', borderRadius: '5px' }}>
            <EventIcon />
          </label>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(InputWrapper);
