import { Button, Checkbox, Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Warning from '../assets/images/figyelmezteto_tabla_zold.png';
import InputWrapper from '../components/ui/InputWrapper';
import ReCaptchaComponent from '../components/ui/ReCaptchaComponent';
import { authService } from '../services/authentication';
import { styles } from '../theme/styles';
import useValidator from '../utils/useValidator';

const RegisterForm = props => {
  const initState = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    repeatPassword: '',
    showError: false,
    errorMessages: [],
    captchaNotValid: true,
    agreement: false,
    newsLetter: false
  };
  const [state, setState] = useState(initState);
  const [disabled, setDisabled] = useState(false);

  const { setErrors, removeErrors, resetErrors, hasErrors, setFields, didFillEverything, giveErrors } = useValidator();

  const history = useHistory();

  const { t } = useTranslation();

  const { classes } = props;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFields(['email', 'firstName', 'lastName', 'password', 'repeatPassword']);
    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => resetErrors(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnChange = event => {
    setState({
      ...state,
      [[event.target.name]]: event.target.value
    });
  };

  const handleOnCheckChange = e => {
    const { name } = e.target;
    setState({
      ...state,
      [name]: !state[name]
    });
  };

  const handleSubmit = event => {
    setDisabled(true);
    event.preventDefault();
    // TODO Át kell írni async-await-re
    if (didFillEverything(state).length === 0) {
      authService
        .register(state)
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 'OK') {
              enqueueSnackbar(t('emailSent'), {
                variant: 'success'
              });
              setState(initState);
            } else {
              response.data.messages.map(message => {
                const key = enqueueSnackbar(t(message), {
                  variant: 'error'
                });

                if (message === 'ACC_MAIL_ALREADY_EXISTS') {
                  return giveErrors('email', key);
                }

                return key;
              });
            }
          }

          setDisabled(false);
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const handleBlur = (e, rules, password) => {
    setErrors(e, rules, password);
  };

  const handleFocus = e => {
    removeErrors(e);
  };

  const captchaValidate = captchaToken => {
    if (captchaToken) {
      setState({
        ...state,
        captchaNotValid: false
      });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <p className={classes.loginHeading} style={{ textAlign: 'center' }}>
            {t('registerTitle')}
          </p>
        </Grid>
        <Grid item xs={12} className={classes.registerPageContainer}>
          <Grid container style={{ maxWidth: '550px', margin: '0 auto' }}>
            <Grid item xs={12}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputWrapper inputStyle={hasErrors('firstName') ? 'Error' : 'Main'} label={t('firstName')}>
                      <TextField
                        fullWidth
                        id="firstName"
                        name="firstName"
                        variant="outlined"
                        margin="dense"
                        value={state.firstName}
                        onChange={handleOnChange}
                        onBlur={e => handleBlur(e, ['required'])}
                        onFocus={handleFocus}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper inputStyle={hasErrors('lastName') ? 'Error' : 'Main'} label={t('lastName')}>
                      <TextField
                        fullWidth
                        id="lastName"
                        name="lastName"
                        variant="outlined"
                        margin="dense"
                        value={state.lastName}
                        onChange={handleOnChange}
                        onBlur={e => handleBlur(e, ['required'])}
                        onFocus={handleFocus}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper inputStyle={hasErrors('email') ? 'Error' : 'Main'} label={t('privateEmail')}>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        variant="outlined"
                        margin="dense"
                        required
                        value={state.email}
                        onChange={handleOnChange}
                        onBlur={e => handleBlur(e, ['required', 'isEmail'])}
                        onFocus={handleFocus}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      className={classes.warningField}
                      alignItems="center"
                      justify="center"
                      style={{
                        maxWidth: '550px',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                      }}>
                      <Grid item xs={12} sm="auto" className="warningSign">
                        <img src={Warning} alt="" />
                      </Grid>
                      <Grid item xs={12} sm className="warningText">
                        <p>
                          <span
                            style={{
                              textTransform: 'uppercase',
                              fontWeight: '700'
                            }}>
                            {t('privateEmailWarningHeading')}
                          </span>{' '}
                          {t('privateEmailWarning')}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper inputStyle={hasErrors('password') ? 'Error' : 'Main'} label={t('password')}>
                      <TextField
                        fullWidth
                        id="password"
                        name="password"
                        type="password"
                        variant="outlined"
                        margin="dense"
                        value={state.password}
                        onChange={handleOnChange}
                        onBlur={e => handleBlur(e, ['required', 'min5', 'isPasswordsMatch'], state.repeatPassword)}
                        onFocus={handleFocus}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper inputStyle={hasErrors('repeatPassword') ? 'Error' : 'Main'} label={t('passwordAgain')}>
                      <TextField
                        fullWidth
                        id="repeatPassword"
                        name="repeatPassword"
                        type="password"
                        variant="outlined"
                        margin="dense"
                        value={state.repeatPassword}
                        onChange={handleOnChange}
                        onBlur={e => handleBlur(e, ['required', 'isPasswordsMatch'], state.password)}
                        onFocus={handleFocus}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <ReCaptchaComponent captchaValidate={captchaValidate} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify="center"
                      alignItems="flex-start"
                      style={{
                        maxWidth: '550px',
                        margin: '16px auto'
                      }}>
                      <Grid container>
                        <Grid item xs={1}>
                          <Checkbox
                            name="agreement"
                            color="primary"
                            checked={state.agreement}
                            inputProps={{
                              'aria-label': 'primary checkbox'
                            }}
                            onClick={handleOnCheckChange}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <p
                            style={{
                              margin: '0',
                              fontSize: '14px'
                            }}>
                            {t('privacyPolicyAccept1')}
                            <Link
                              target="_blank"
                              rel="noreferrer noopener"
                              href="https://allyoucanmove.hu/content_files/adatkezelesi_tajekoztato.pdf"
                              style={{ color: 'black', fontWeight: '700' }}>
                              {t('privacyPolicyAccept2')}
                            </Link>
                            {t('privacyPolicyAccept3')}
                          </p>
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: '15px' }}>
                        <Grid item xs={1}>
                          <Checkbox
                            name="newsLetter"
                            color="primary"
                            checked={state.newsLetter}
                            inputProps={{
                              'aria-label': 'primary checkbox'
                            }}
                            onClick={handleOnCheckChange}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <p style={{ margin: '0', fontSize: '14px' }}>
                            {t('newsletter1')}
                            <strong>{t('newsletter2')}</strong>
                            {t('newsletter3')}
                            <Link
                              target="_blank"
                              rel="noreferrer noopener"
                              href="https://allyoucanmove.hu/content_files/adatkezelesi_tajekoztato.pdf"
                              style={{ color: 'black', fontWeight: '700' }}>
                              {t('newsletter4')}
                            </Link>
                            {t('newsletter5')}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Grid container justify="center">
                      <Grid item xs={12} sm={5} className={classes.leftButton}>
                        <Button variant="outlined" color="secondary" type="button" onClick={() => history.push('/')}>
                          {t('back')}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={5} className={classes.rightButton}>
                        <Button
                          disabled={state.agreement === false || state.captchaNotValid === true || disabled}
                          variant="outlined"
                          color="primary"
                          type="submit">
                          {t('registerSubmit')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

RegisterForm.propTypes = {
  classes: PropTypes.shape()
};

RegisterForm.defaultProps = {
  classes: {}
};

export default withStyles(styles)(RegisterForm);
