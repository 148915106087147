import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { styles } from '../../theme/styles';
import cherrisken from '../../assets/images/cherrisk/cherrisk_popup_en.png';
import cherriskhu from '../../assets/images/cherrisk/cherrisk_popup_hu.png';
// import DialogActions from '@material-ui/core/DialogActions';

const CherriskPopup = (props) => {
  const { classes, handleClose, showError } = props;

  const { i18n } = useTranslation();

  const getBackgroundImage = () => {
    switch (i18n.language) {
      case 'hu':
        return cherriskhu;
      case 'en':
        return cherrisken;
      default:
        return cherriskhu;
    }
  };

  const handleClick = () => {
    const url =
      'https://www.cherrisk.com/hu-hu';
    window.open(url, '_blank');
    handleClose();
  };

  const backgroundImage = getBackgroundImage();
  return (
    <Dialog onClose={handleClose} open={showError}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        style={{
          position: 'absolute',
          zIndex: '100',
          right: '8px',
          top: '8px',
          color: 'white',
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent
        className={classes.cherriskPopup}
        style={{
          background: `url(${backgroundImage})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}>
        <div
          className={classes.cherriskPopupButton}
          onClick={handleClick}
          onKeyPress={handleClick}
          role="button"
          tabIndex={0}
          aria-label="Open Link"
        />
      </DialogContent>
    </Dialog>
  );
};

CherriskPopup.propTypes = {
  classes: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired,
  showError: PropTypes.bool.isRequired,
};
export default withStyles(styles)(CherriskPopup);
