import React, {useState, useEffect} from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { styles } from '../../theme/styles';
import { withTranslation } from 'react-i18next';
// 2021-11-29 pjung TNRUKAYCM-1278 Login logó csere
//import Logo from '../../assets/images/AYCM21.png';
//import Multisport from '../../assets/images/multi_logo1_szurke.png';
import Logo from '../../assets/images/AYCM22.png';

const LoggedOutHeader = props => {
  const [, setLanguage] = useState(localStorage.getItem('i18nextLng'))

  const i18nextLng = localStorage.getItem('i18nextLng')

  useEffect(() => {
    setLanguage(i18nextLng)
  }, [i18nextLng])

  const { classes } = props;
  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={6} className={classes.headerLogoHasNoUser}>
        <img src={Logo} alt='' />
          {/* 2021-11-29 pjung TNRUKAYCM-1278 Login logó csere
          <br /><img src={Multisport} alt="" />*/}
      </Grid>
    </Grid>
  );
};

export default withTranslation()(withStyles(styles)(LoggedOutHeader));
