import axios from 'axios';
// import { initReactI18next } from 'react-i18next';

const baseUrl = `${
  process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:8080'
}/cportal-contracting-services/api/contracting/v1`;
const publicBaseUrl = `${
  process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:8080'
}/cportal-contracting-services/api/public/contracting/v1`;

const config = {
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
};

const configPub = {
  baseURL: publicBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const contractService = {
  init,
  initUpgrade,
  checkEmail,
  listEmployersByDomain,
  start,
  get,
  submit,
  getDates,
  listCitiesByZip,
  listStreetTypes,
  getPackages,
  getBankNameByAccount,
  findByTax,
  intraStatus,
  resetIntra,
  resetContract,
};

export const contractPublicService = {
  confirm,
};

function addJWT() {
  config.withCredentials = true;
  config.headers.Authorization = `Bearer ${sessionStorage.token}`;
}

async function init() {
  addJWT();
  return axios.post('/init', {}, config);
}

async function initUpgrade() {
  addJWT();
  return axios.post('/init-upgrade', {}, config);
}

async function checkEmail(email) {
  addJWT();
  return axios.post(
    '/check-mail',
    {
      email,
    },
    config,
  );
}

async function listEmployersByDomain(request) {
  addJWT();
  return axios.post('/list-employers-by-domain', request, config);
}

async function start(request) {
  addJWT();
  return axios.post('/start', request, config);
}

async function get(step, contractKey) {
  addJWT();
  return axios.post(
    `/get-${step}`,
    {
      key: contractKey,
    },
    config,
  );
}

async function resetIntra() {
  addJWT();
  return axios.get('/reset-intranet', config);
}

async function resetContract(request) {
  addJWT();
  return axios.post('/reset-contract', request, config);
}

async function submit(step, request) {
  addJWT();
  return axios.post(`/submit-${step}`, request, config);
}

async function getDates(contractKey) {
  addJWT();
  return axios.post(
    '/get-dates',
    {
      key: contractKey,
    },
    config,
  );
}

async function confirm(token) {
  return axios.post(
    '/confirm',
    {
      token,
    },
    configPub,
  );
}

async function listCitiesByZip(zip) {
  addJWT();
  return axios.post(
    '/list-cities-by-zip',
    {
      zip,
    },
    config,
  );
}

async function listStreetTypes() {
  addJWT();
  return axios.post('/list-street-types', {}, config);
}

async function getPackages(contractKey, startDate) {
  addJWT();
  return axios.post(
    '/get-packages',
    {
      key: contractKey,
      datum: startDate,
    },
    config,
  );
}

async function getBankNameByAccount(accountNumber) {
  addJWT();
  return axios.post(
    '/get-bank-from-account',
    {
      account: accountNumber,
    },
    config,
  );
}

async function findByTax(request) {
  addJWT();
  return axios.post('/find-by-tax', request, config);
}

async function intraStatus(request) {
  return axios.post('/intra-status', request, configPub);
}
