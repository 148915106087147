import { Box, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/icons/CheckBox';
import CheckboxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { contractService } from '../services/contracting';
import { isArrayNotEmpty } from '../utils/Utils';
import useValidator from '../utils/useValidator';

import ErrorDialog from '../components/ui/ErrorDialog';
import InputWrapper from '../components/ui/InputWrapper';
import LoaderIcon from '../components/ui/LoaderIcon';
import { intraService } from '../services/intranet';
import { styles } from '../theme/styles';

const ContractingInitUpgradeTarsPage = props => {
  const { kr, officeEmail } = useParams();
  const [state, setState] = useState({
    roleError: false,
    employer: '',
    showError: false
  });

  const [dialog, setDialog] = useState({
    open: false,
    content: null,
    icon: '',
    disabled: false
  });

  const [, setEmailSent] = useState(false);

  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState([]);
  const [employers, setEmployers] = useState(null);
  const [pressedEnter, setPressedEnter] = useState(false);

  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { classes, isPackageXXL } = props;
  const history = useHistory();

  const { giveErrors, hasErrors, setErrors, removeErrors } = useValidator();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await contractService.initUpgrade();
        if (response?.data?.status === 'OK') {
          // Kötőjelet ellenőrző regexp
          const hyphenRegexp = /^[a-zA-Z0-9]*(?:-[a-zA-Z0-9]*)+$/;
          // 2020-10-19 szbiro TNRUKAYCM-838 intranet folyamatban lévő státuszok kezelése
          if (response.data.token && !response.data.token.match(hyphenRegexp)) {
            sessionStorage.setItem('type', response.data.type === 'U' ? 1 : 0);
            history.push(`/contracting/options/${response.data.token}`);
          }
          if (isArrayNotEmpty(response.data.messages)) {
            setErrorMessages(response.data.messages);
          } else if (response.data.type?.length > 1) {
            const intraMessage = response.data.type.toLowerCase();
            if (intraMessage !== 'elutasitott') {
              setErrorMessages([`intra.status.${intraMessage}`]);
            }
          } else if (response.data.key && response.data.step) {
            sessionStorage.setItem('step', response.data.step);
            sessionStorage.setItem('contractKey', response.data.key);
            sessionStorage.setItem('type', response.data.type);
            history.push('/contracting');
          } else {
            setErrorMessages([]);
          }
        } else {
          setErrorMessages(response.data.messages);
        }
      } catch (e) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
    };

    fetchData();
  }, [enqueueSnackbar, history, t]);

  useEffect(() => {
    const checkUserEmail = async () => {
      if (employers === null)
        try {
          const response = await contractService.listEmployersByDomain({
            type: 'U',
            email: officeEmail
          });
          if (response?.data?.status === 'OK') {
            if (isArrayNotEmpty(response.data.list)) {
              setEmployers(response.data.list);
            } else {
              setEmployers([]);
              enqueueSnackbar(t('EMAIL_INVALID'), {
                variant: 'error'
              });
            }
          }
          if (response.data.messages) {
            response.data.messages.map(message =>
              enqueueSnackbar(t(message), {
                variant: 'error'
              })
            );
          }
        } catch (e) {
          enqueueSnackbar(t('TECHNICAL_ERROR'), {
            variant: 'error'
          });
        }
    };

    checkUserEmail();
  }, [enqueueSnackbar, t, employers, officeEmail]);

  useEffect(() => {
    const checkIfNeedsPopup = async () => {
      const response = await intraService.hasMessage();

      if (response.data.status === 'OK') {
        if (response.data.id !== 0) {
          const closeDialogContent = async () => {
            const resp = await intraService.closeMessage(response.data.id);

            if (resp.data.status === 'OK') {
              setDialog({
                open: false,
                content: null,
                icon: ''
              });
            }
          };

          const dialogContent = (
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  component="h6"
                  style={{
                    color: 'black',
                    fontWeight: '700',
                    fontSize: '14px',
                    marginTop: '24px'
                  }}>
                  {t('intra.contractDenied')}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'right', marginBottom: '24px' }}>
                <Button variant="outlined" color="primary" onClick={closeDialogContent}>
                  {t('accept')}
                </Button>
                <Grid item xs={12}>
                  <Box className={props.classes.popupBottom} />
                </Grid>
              </Grid>
            </Grid>
          );

          setDialog({
            open: true,
            content: dialogContent,
            icon: 'warning',
            disabled: true
          });
        }
      }
    };

    checkIfNeedsPopup();
  }, [props.classes.popupBottom, t]);

  useEffect(() => {
    if (errorMessages.length) {
      setState({ ...state, roleError: true });
    } else {
      setState({ ...state, roleError: false });
    }
    setTimeout(() => setLoading(false), 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessages]);

  const handleOnChange = event => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
    setPressedEnter(false);
    removeErrors(event);
  };

  const handleSnackbar = snackbarKey => {
    closeSnackbar(snackbarKey);
    setEmailSent(false);
  };

  const handleSubmit = async event => {
    if (event && event.preventDefault) event.preventDefault();
    try {
      const response = await contractService.start({ type: 'U', employer: state.employer, email: officeEmail, key: 1, kr });

      if (response.data.status === 'OK') {
        setEmailSent(true);

        enqueueSnackbar(t('tarsEmailSent'), {
          variant: 'success',
          action: snackbarKey => <Button onClick={() => handleSnackbar(snackbarKey)}>OK</Button>
        });
        setState({ ...state, employer: 0 });
      } else {
        response.data.messages.map(message => enqueueSnackbar(t(message), { variant: 'error' }));
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const filtered = employers?.filter(employer => employer.key === 1);
  const list = filtered?.map(employer => (
    <FormControlLabel
      key={employer.id}
      value={employer.id}
      control={<Radio color="primary" icon={<CheckboxOutlineBlank />} checkedIcon={<Checkbox />} />}
      label={employer.name}
      style={{ marginLeft: '0' }}
    />
  ));

  return (
    <Typography component="section" style={{ padding: '16px 32px 0px 32px' }}>
      {loading ? (
        <Grid container justify="flex-start">
          <Grid item xs={12}>
            <h2 style={{ fontSize: '14px', marginBottom: '0px' }}>{t('upgradingHeading')}</h2>
          </Grid>
          <LoaderIcon />
        </Grid>
      ) : (
        <>
          <ErrorDialog
            showError={dialog.open}
            icon={dialog.icon}
            disabled={dialog.disabled}
            content={dialog.content}
            handleClose={() => {
              setDialog({ open: false, content: '', title: '' });
            }}
          />
          <form onSubmit={handleSubmit} onError={errors => console.error(errors)}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <h2 style={{ fontSize: '14px', marginBottom: '0px' }}>{t('upgradingHeadingTars')}</h2>
              </Grid>
              <Grid item xs={12}>
                <Box display={state.roleError ? 'block' : 'none'}>
                  {errorMessages.map(message => (
                    <Grid item xs={12} key={message}>
                      <Grid container className={classes.warningField} alignItems="center" justify="flex-start">
                        <Grid item xs={12} sm className="warningText">
                          <p>{t(message)}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display={state.roleError ? 'none' : 'block'}>
                  <Grid container className={classes.warningField}>
                    <Grid item xs={12} sm>
                      {isPackageXXL ? <p>{t('noBiggerPackageMulti')}</p> : ''}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box style={{ marginTop: '-12px' }} width="100%" display={state.roleError || isPackageXXL ? 'none' : 'block'}>
                  <InputWrapper inputStyle={hasErrors('officeEmail') ? 'Error' : 'Multi'} label={t('officeEmailTars')}>
                    <TextField fullWidth id="officeEmail" name="officeEmail" variant="outlined" margin="dense" disabled value={officeEmail} />
                  </InputWrapper>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  mx="auto"
                  width="100%"
                  display={state.roleError || (state.employer === 0 && !state.officeEmail) || isPackageXXL ? 'none' : 'block'}>
                  <FormControl fullWidth component="fieldset" style={{ marginTop: '20px' }}>
                    <FormLabel
                      component="legend"
                      style={{
                        fontWeight: '700',
                        fontSize: '14px',
                        color: 'black',
                        marginBottom: '16px'
                      }}>
                      {employers?.length ? t('employer') : ''}
                    </FormLabel>
                    <RadioGroup aria-label={t('paymentType')} id="employer" name="employer" onChange={handleOnChange} value={state.employer}>
                      {list?.length ? <p style={{ margin: '6px 0' }}>{t('companyOfferHeading')}</p> : null}
                      {list}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Box display={state.roleError || isPackageXXL ? 'none' : 'block'} mb={2} mt={2}>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={!state.employer || state.employer === 0}
                    style={{ width: 'auto', textTransform: 'none' }}>
                    {t('button.contractingUpgrade')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Typography>
  );
};

ContractingInitUpgradeTarsPage.propTypes = {
  classes: PropTypes.shape(),
  isPackageXXL: PropTypes.bool.isRequired
};

ContractingInitUpgradeTarsPage.defaultProps = {
  classes: {}
};

export default withStyles(styles)(ContractingInitUpgradeTarsPage);
