import React, { useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { styles } from '../theme/styles';
import Warning from '../assets/images/figyelmezteto_tabla_zold.png';
import SuccessSign from '../assets/images/haromszog_pipa_zold.png';

const Subsciprtion = (props) => {
  const { lang, status } = useParams();
  const { classes, t, i18n } = props;

  useEffect(() => {
    i18n.changeLanguage(lang);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const success = (
    <Grid item xs={12}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.warningField}
        style={{ height: '100%', maxWidth: '480px', margin: '0 auto' }}
      >
        <Grid item xs={12} sm="auto" style={{ textAlign: 'center' }}>
          <img
            src={SuccessSign}
            style={{ width: '75px ', height: 'auto' }}
            alt=""
          />
        </Grid>
        <Grid item xs={12} sm className="warningText">
          <p style={{ marginTop: '0', marginBottom: '8px', textAlign: 'center' }}>
            <strong>{t('newsLetter.subscribeSuccess')}</strong>
          </p>
        </Grid>
      </Grid>
    </Grid>
  );

  const fail = (
    <Grid item xs={12}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.warningField}
        style={{ height: '100%', maxWidth: '550px', margin: '0 auto' }}
      >
        <Grid item xs={12} sm="auto" style={{ textAlign: 'center' }}>
          <img
            src={Warning}
            style={{ width: '75px ', height: 'auto' }}
            alt=""
          />
        </Grid>
        <Grid item xs={12} sm className="warningText">
          <p style={{ marginTop: '0', marginBottom: '8px', textAlign: 'center' }}>
            <strong>{t('newsLetter.subscribeFail')}</strong>
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <Grid container style={{ height: '50%' }}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <p className={classes.loginHeading}>{t('subscribe')}</p>
      </Grid>
      {status === '0' ? fail : success }
    </Grid>
  );
};

export default withTranslation()(withStyles(styles)(Subsciprtion));
