import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';

const publicBaseUrl = `${
  process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:8080'
}/cportal-user-account-services/api/accounts/v1`;
const baseUrl = `${
  process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:8080'
}/cportal-user-account-services/api/protected/accounts/v1`;

const config = {
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
};

function addJWT() {
  config.withCredentials = true;
  config.headers.Authorization = `Bearer ${sessionStorage.token}`;
}

function isTokenExpired() {
  if (sessionStorage.token) {
    const decoded = jsonwebtoken.decode(sessionStorage.token);
    const now = Date.now().valueOf() / 1000;
    if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
      return true;
    }
    return false;
  }
  return true;
}
function register(data) {
  return axios.post(`${publicBaseUrl}/registration`, {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    password: data.password,
    newsLetter: data.newsLetter
  });
}

function activate(code) {
  return axios.post(`${publicBaseUrl}/confirm`, {
    activator: code
  });
}

async function login(username, password) {
  return axios.post(`${publicBaseUrl}/login`, {
    login: username,
    password
  });
}

async function loginAs(code) {
  return axios.post(`${publicBaseUrl}/loginAs`, {
    activator: code
  });
}

function isUserLoggedIn() {
  if (sessionStorage.token) {
    return true;
  }
  return false;
}

function logout() {
  // remove user from local storage to log user out
  clearInterval(this.timer);
  const langSetup = localStorage.getItem('i18nextLng');
  localStorage.clear();
  localStorage.setItem('i18nextLng', langSetup);
  sessionStorage.clear();
}

function currentUser() {
  return JSON.parse(sessionStorage.getItem('currentUser'));
}

function currentUserName() {
  if (sessionStorage.currentUser) {
    const user = currentUser();
    return `${user.firstName} ${user.lastName}`;
  }
  return '';
}

function currentUserKR() {
  if (sessionStorage.currentUser) {
    const user = currentUser();
    return user.kr;
  }
  return '';
}

function forgotPassword(email) {
  return axios.post(`${publicBaseUrl}/forgot`, {
    email
  });
}

function resetPassword(activator, password) {
  return axios.post(`${publicBaseUrl}/reset`, {
    activator,
    password
  });
}

function changePassword(oldPassword, newPassword) {
  addJWT();
  const user = currentUser();
  return axios.post(
    `${baseUrl}/change-password`,
    {
      email: user.email,
      oldPassword,
      newPassword
    },
    config
  );
}

function getHeadData() {
  addJWT();
  return axios.post('/getHeadData', {}, config);
}

function getProfile() {
  addJWT();
  return axios.post('/getProfile', {}, config);
}

function getInvoiceData() {
  addJWT();
  return axios.post('/get-invoice-data', {}, config);
}

function changeInvoiceData(request) {
  addJWT();
  return axios.post('/change-invoice-data', request, config);
}

function validateEmail(request) {
  return axios.post(`${publicBaseUrl}/validate-email`, request);
}

function changeEmail(request) {
  addJWT();
  return axios.post('/change-email', request, config);
}

function changePhone(request) {
  addJWT();
  return axios.post('/change-phone', request, config);
}

function changeAddress(request) {
  addJWT();
  return axios.post('/change-address', request, config);
}

// eslint-disable-next-line import/prefer-default-export
export const authService = {
  isTokenExpired,
  register,
  activate,
  login,
  loginAs,
  logout,
  currentUser,
  currentUserName,
  currentUserKR,
  isUserLoggedIn,
  forgotPassword,
  changePassword,
  getHeadData,
  getProfile,
  resetPassword,
  getInvoiceData,
  changeInvoiceData,
  validateEmail,
  changeEmail,
  changePhone,
  changeAddress
};
