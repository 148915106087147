import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { styles } from '../theme/styles';
import { intraService } from '../services/intranet';
import { contractService } from '../services/contracting';
//import OffersBox from '../components/ui/OffersBox';
import { intraBaseUrl } from '../utils/Consts';
import Pipa from '../assets/images/3szog_pipa_zold.png';

const ContractOptions = ({ classes }) => {
  const [list, setList] = useState([]);
  const [nextList, setNextList] = useState([]);
  const [uuid, setUuid] = useState('');
  const [type, setType] = useState('');
  const [contractType, setContractType] = useState('');

  const { t } = useTranslation();
  const { token } = useParams();
  const history = useHistory();

  // Kötőjelet ellenőrző regexp
  const hyphenRegexp = /^([a-zA-Z0-9]*-[a-zA-Z0-9]*)+$/;

  useEffect(() => {
    setContractType(sessionStorage.getItem('type'));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      console.log('token:', token);
      if (token.match(hyphenRegexp)) {
        // Ha kötőjeles, akkor intrás szerviztől kérjük a csomagokat
        const response = await intraService.getIntraPackages({
          token,
          type: contractType,
        });
        if (response.data?.status === 'OK') {
          setUuid(response.data.subscriptionUUID);
          setList(response.data.list);
          setType('intra');
        }
      } else {
        // Ha nem kötőjeles, akkor a TNR-től...
        const actualDate = new Date().toISOString().slice(0, 10);
        const nextYear = () => {
          const year = new Date().getFullYear() + 1;
          return `${year}-01-01`;
        };
        const response = await contractService.getPackages(-1, actualDate);
        if (response.data?.status === 'OK') {
          setList(response.data.list);
          setType('tnr');
        }
        // 2020-11-19 pjung TNRUKAYCM-923 Mivel levettük az évszámot, és a '21-es árak megegyeznek a '20-a árakkal, felesleges lekérdezni
        // (és megjeleníteni) a '21-es árakat is...
        if (false && new Date().getMonth() === 11) {
          const nextYearResponse = await contractService.getPackages(-1, nextYear());
          if (nextYearResponse.data?.status === 'OK') {
            setNextList(response.data.list);
          }
        }
      }
    };

    if (contractType) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractType]);

  const createUrl = () => {
    if (!token.match(hyphenRegexp)) {
      return '/contracting';
    }

    if (sessionStorage.getItem('type') === '0') {
      window.location.assign(`${intraBaseUrl}/intranet/subscription/${uuid}`);
    }
    if (sessionStorage.getItem('type') === '1') {
      window.location.assign(`${intraBaseUrl}/intranet/upgrade/${uuid}`);
    }
  };

  const handleCancel = async () => {
    const isIntra = token.match(hyphenRegexp);
    let upgradeOrNot = false;
    if (isIntra) {
      const response = await contractService.resetIntra();
      if (response?.data?.status === 'OK') {
        // 2020-08-26 pjung TNRUKAYCM-837 sessionStorage.type === 1 helyett:
        upgradeOrNot = sessionStorage.getItem('type') === '1';
      }
    } else {
      const response = await contractService.resetContract({
        activator: token,
      });

      if (response?.data?.status === 'OK') {
        const type = sessionStorage.getItem('type');

        sessionStorage.removeItem('type');
        sessionStorage.removeItem('step');
        sessionStorage.removeItem('contractKey');

        upgradeOrNot = type === 'U';
      }
    }
    // 2020-08-26 pjung TNRUKAYCM-837 Upgrade esetén is init volt...?
    console.log('Upgrade', upgradeOrNot, 'Intra', isIntra);
    history.push(upgradeOrNot ? '/contracting/upgrade' : '/contracting/init');
  };

  const handleClick = () => {
    const redirectUrl = createUrl();

    history.push(redirectUrl);
  };

  return (
    <Typography component="section" style={{ padding: '16px', height: '100%', position: 'relative' }}>
      <Grid container justify="center" alignItems="center" style={{ marginTop: '20%' }}>
        <Grid item xs={12} sm={2}>
          <img src={Pipa} alt="pipa" />
        </Grid>
        <Grid item xs={12} sm={10}>
          {/* 2021-11-29 pjung TNRUKAYCM-1279 Jogosultság ellenőrző oldal: design és szöveg csere */}
          <h2
            style={{
              fontSize: '18px',
              marginBottom: '0px',
              color: 'rgba(49, 139, 129, 1)',
            }}>
            {t('offersHeading')}
          </h2>
          <p style={{ margin: '4px 0', fontSize: '24px', fontWeight: '700' }}>{t('offersSubHeading')}</p>
        </Grid>
        <Grid item xs={12} className={classes.offerButton} justify="flex-end" container>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              textAlign: 'right',
              marginBottom: '6px',
              paddingRight: '24px',
            }}>
            <Button
              onClick={handleCancel}
              variant="outlined"
              color="secondary"
              style={{ width: '140px', padding: '5px 15px', textTransform: 'none', marginRight: '10px' }}>
              {t('button.cancelContracting')}
            </Button>
            <Button onClick={handleClick} variant="outlined" color="primary" style={{ width: '140px', padding: '5px 15px', textTransform: 'none' }}>
              {t('button.contracting')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Typography>
  );
};

ContractOptions.propTypes = {
  classes: PropTypes.shape(),
};

ContractOptions.defaultProps = {
  classes: {},
};

export default withStyles(styles)(ContractOptions);
