import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import LoaderIcon from '../components/ui/LoaderIcon';
import { authService } from '../services/authentication';
import { setCurrentUser } from '../redux/user/user.actions';
import { changeObjectNullValuesToEmptyString } from '../utils/Utils';
import { styles } from '../theme/styles';
import Warning from '../assets/images/figyelmezteto_tabla_zold.png';

const ValidateEmailPage = props => {
  const [error, setError] = useState(false);
  const { lang, token } = useParams();
  const { i18n, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { classes } = props;

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authService.validateEmail({ activator: token });
        if (response.data.status === 'OK') {
          sessionStorage.setItem(
            'currentUser',
            JSON.stringify(response.data.user)
          );
          sessionStorage.setItem('token', response.data.token);

          const res = await authService.getHeadData();
          if (res.data.status === 'OK') {
            const user = changeObjectNullValuesToEmptyString(res.data);
            props.setCurrentUser(user);
          }
          history.push('/profile/data/success');
        } else {
          setError(true);
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error'
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const WarningField = () => (
    <Grid
      item
      xs={3}
      container
      className={classes.warningField}
      alignItems="center"
      justify="flex-start"
    >
      <Grid item xs={12} sm="auto" className="warningSign">
        <img src={Warning} alt="" />
      </Grid>
      <Grid item xs={12} sm className="warningText">
        <p style={{ fontSize: '16px', fontWeight: '700' }}>
          Érvénytelen token!
        </p>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      style={{ height: '100%' }}
    >
      {error ? <WarningField /> : <LoaderIcon />}
    </Grid>
  );
};

ValidateEmailPage.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired
};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser
});

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ValidateEmailPage));
