import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import Success from '../assets/images/haromszog_pipa_zold.png';
import ContractSumDetails from '../components/ui/ContractSumDetails';
import SocialIcons from '../components/ui/SocialIcons';
import { paymentService } from '../services/payment';
import { contractService } from '../services/contracting';
import { authService } from '../services/authentication';
import { styles } from '../theme/styles';
import { API_URL } from '../utils/Consts';
import LoaderIcon from '../components/ui/LoaderIcon';
import { priceMask, changeObjectNullValuesToEmptyString } from '../utils/Utils';
import { setCurrentUser } from '../redux/user/user.actions';
import CherriskPopup from '../components/ui/CherriskPopup';

class ContractingSumPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      errorMessages: '',
      openDialog: false,
      messages: [],
      type: 'R',
      paid: '',
      payState: 0, // 1 fizetés sikeres ->fizetési adatok megjelenítése
      regState: 0, // 1 TNR szerződés sikeres->TNR üzenet, KR azonosító megjelenítése
      docState: false, // 1 dokumentumgenerálás sikeres-> dokumentumok linkjének megjelenítése.
      payment: {},
      kr: 0,
      docContract: 0,
      docReg: 0,
      docCollection: 0,
      docTransfer: 0,
      docAszf: 0,
      cherriskPopup: true
    };
  }

  getStatus = async () => {
    try {
      const response = await contractService.get(8, sessionStorage.contractKey);

      const {
        status,
        messages,
        type,
        paid,
        kr,
        docContract,
        docReg,
        docCollection,
        docTransfer,
        payment,
        payState,
        regState,
        docState,
        docAszf
      } = response.data;

      this.setState({
        messages: messages || [],
        type: type || '',
        paid: paid || '',
        kr: kr || 0,
        docContract: docContract || 0,
        docReg: docReg || 0,
        docCollection: docCollection || 0,
        docTransfer: docTransfer || 0,
        docAszf: docAszf || 0,
        payment: payment
          ? { ...payment, amount: priceMask(payment.amount) }
          : {},
        payState: payState || 0,
        regState: regState || 0,
        docState: docState || false,
        status: status || ''
      });

      if (response.data) {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        currentUser.kr = kr;
        sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
        const headerData = await authService.getHeadData();
        if (headerData.data.status === 'OK') {
          const user = changeObjectNullValuesToEmptyString(headerData.data);
          this.props.setCurrentUser(user);
        }
      }

      const checkMessagesForDebt = messages.includes('HAS_DEBT');

      if (regState === 1 && checkMessagesForDebt) {
        this.props.enqueueSnackbar(this.props.t('HAS_DEBT'), {
          variant: 'error'
        });
      }

      switch (status) {
        case 'TNR':
        case 'DOC':
          this.setState({ openDialog: true });
          break;
        case 'ERROR':
          messages.map(message =>
            this.props.enqueueSnackbar(this.props.t(message), {
              variant: 'error'
            })
          );
          clearInterval(this.timer);
          break;
        case 'DONE':
          clearInterval(this.timer);
          break;
        default:
          // OK
          if (docState) {
            clearInterval(this.timer);
          }
      }
      return payment ? payment.transaction : null;
    } catch (e) {
      this.props.enqueueSnackbar(this.props.t(e.message), {
        variant: 'error'
      });
    }
  };

  recallEndTransaction = async transaction => {
    try {
      const response = await paymentService.endTransaction(transaction);
      if (response.data.status === 'OK') {
      } else {
        response.data.messages.map(message =>
          this.props.enqueueSnackbar(this.props.t(message), {
            variant: 'error'
          })
        );
      }
    } catch (e) {
      this.props.enqueueSnackbar(this.props.t(e.message), {
        variant: 'error'
      });
    }
  };

  async componentDidMount() {
    sessionStorage.removeItem('type');
    const { uuid, status } = this.props.match.params;
    if (uuid && status) {
      return this.props.history.push(`/contracting/intra/${uuid}/${status}`);
    }
    if (sessionStorage.otpres) {
      const otpres = JSON.parse(sessionStorage.otpres);
      if (otpres.transaction) {
        this.getStatus();
        this.timer = setInterval(() => this.getStatus(), 5000);
        sessionStorage.removeItem('otpres');
      } else {
        this.props.enqueueSnackbar(this.props.t('otpTransactionError'), {
          variant: 'error'
        });
      }
    } else {
      const transaction = await this.getStatus();
      if (transaction && sessionStorage.endTransaction) {
        this.recallEndTransaction(transaction);
        this.timer = setInterval(() => this.getStatus(), 5000);
      }
    }
  }

  getContractType = () => {
    const { t } = this.props;
    switch (this.state.type) {
      case 'R':
        return t('szerz.fo.');
      case 'U':
        return t('upgrade.fo.');
      case 'h':
        return t('szerz.tars.');
      default:
        return t('szerz.fo.');
    }
  };

  handleClose = () => {
    this.setState({
      cherriskPopup: false
    });
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { classes, t } = this.props;

    return (
      <>
        <CherriskPopup
          handleClose={this.handleClose}
          showError={this.state.cherriskPopup}
        />
        <Grid
          container
          justify="center"
          alignItems="stretch"
          style={{ padding: '16px 30px', height: '100%' }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: `${this.state.payState === 1 ? 'block' : 'none'}`
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                className={classes.warningField}
                alignItems="center"
                justify="space-between"
                style={{
                  margin: '0 auto'
                }}
              >
                <Grid item xs={12} sm="auto" className="warningSign">
                  <img src={Success} alt="" />
                </Grid>
                <Grid item xs={12} sm className="warningText">
                  <p
                    style={{
                      color: 'rgba(49, 139, 129, 1)',
                      textTransform: 'uppercase',
                      fontSize: '14px',
                      fontWeight: '700',
                      marginBottom: '4px'
                    }}
                  >
                    {t('label.online.payment.success')}
                  </p>
                  <p
                    style={{
                      marginTop: '4px',
                      fontSize: '12px',
                      fontWeight: '700'
                    }}
                  >
                    {t('amount2')}: {this.state.payment.amount} Ft -{' '}
                    {t('label.bankAcceptCode')}: {this.state.payment.auth} -{' '}
                    {t('label.transactionID')}: {this.state.payment.transaction}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            {this.state.docState ? (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: this.state.docContract > 0 ? 'block' : 'none'
                  }}
                >
                  <Box
                    className={classes.download2Wrapper}
                    style={{ margin: 0 }}
                  >
                    <Grid container justify="space-between">
                      <Grid item xs={12} sm style={{ textAlign: 'center' }}>
                        <p
                          className={classes.downloadWrapperText}
                          style={{ color: 'black', fontWeight: '700' }}
                        >
                          {this.getContractType()}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                        <Link
                          href={`${API_URL}/common-services/document?id=${this.state.docContract}&kr=${this.state.kr}`}
                          underline="none"
                        >
                          <Button>{t('download')}</Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: this.state.docAszf > 0 ? 'block' : 'none' }}
                >
                  <Box
                    className={classes.download2Wrapper}
                    style={{ margin: 0 }}
                  >
                    <Grid container justify="space-between">
                      <Grid item xs={12} sm style={{ textAlign: 'center' }}>
                        <p
                          className={classes.downloadWrapperText}
                          style={{ color: 'black', fontWeight: '700' }}
                        >
                          {t('termsAndConditions')}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                        <Link
                          underline="none"
                          href={`${API_URL}/common-services/document?id=${this.state.docAszf}`}
                        >
                          <Button>{t('download')}</Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: this.state.docReg > 0 ? 'block' : 'none' }}
                >
                  <Box
                    className={classes.download2Wrapper}
                    display={this.state.docReg > 0 ? 'block' : 'none'}
                    style={{ margin: 0 }}
                  >
                    <Grid container justify="space-between">
                      <Grid item xs={12} sm style={{ textAlign: 'center' }}>
                        <p
                          className={classes.downloadWrapperText}
                          style={{ color: 'black', fontWeight: '700' }}
                        >
                          {t('reg.lap.')}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                        <Link
                          href={`${API_URL}/common-services/document?id=${this.state.docReg}&kr=${this.state.kr}`}
                          underline="none"
                        >
                          <Button>{t('download')}</Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: this.state.docTransfer > 0 ? 'block' : 'none'
                  }}
                >
                  <Box
                    className={classes.download2Wrapper}
                    style={{ margin: 0 }}
                  >
                    <Grid container justify="space-between">
                      <Grid item xs={12} sm style={{ textAlign: 'center' }}>
                        <p
                          className={classes.downloadWrapperText}
                          style={{ color: 'black', fontWeight: '700' }}
                        >
                          {t('felhat.')}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                        <Link
                          href={`${API_URL}/common-services/document?id=${this.state.docTransfer}&kr=${this.state.kr}`}
                          underline="none"
                        >
                          <Button>{t('download')}</Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: this.state.docCollection > 0 ? 'block' : 'none'
                  }}
                >
                  <Box
                    className={classes.download2Wrapper}
                    style={{ margin: 0 }}
                  >
                    <Grid container justify="space-between">
                      <Grid item xs={12} sm style={{ textAlign: 'center' }}>
                        <p
                          className={classes.downloadWrapperText}
                          style={{ color: 'black', fontWeight: '700' }}
                        >
                          {t('csop.megb.')}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                        <Link
                          href={`${API_URL}/common-services/document?id=${this.state.docCollection}&kr=${this.state.kr}`}
                          underline="none"
                        >
                          <Button>{t('download')}</Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </>
            ) : (
              <Box display={this.state.status === 'ERROR' ? 'none' : 'block'}>
                <Grid
                  container
                  className={classes.warningField}
                  alignItems="center"
                  justify="space-between"
                  style={{
                    margin: '0 auto'
                  }}
                >
                  <Grid item xs={12} sm="auto" className="warningSign">
                    <LoaderIcon />
                  </Grid>
                  <Grid item xs={12} sm className="warningText">
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: '700', fontSize: '14px' }}
                    >
                      {t('docsLoading')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
          <ContractSumDetails />
          <Grid item xs={12}>
            <Grid container justify="flex-end" style={{ height: '0' }}>
              <Grid item lg="6">
                <SocialIcons />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser
});

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user))
});

export default withTranslation()(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withSnackbar(ContractingSumPage))
  )
);
