import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Address from '../components/ui/Address';
import InputWrapper from '../components/ui/InputWrapper';
import InvoiceDir from '../components/ui/InvoiceDir';
import { contractService } from '../services/contracting';
import { authService } from '../services/authentication';
import { checkTaxNumber, isBlank } from '../utils/Utils';
import useValidator from '../utils/useValidator';
import LoaderIcon from '../components/ui/LoaderIcon';
import { styles } from '../theme/styles';
import Warning from '../assets/images/figyelmezteto_tabla_zold.png';

const InvoicingData = ({ currentUser, classes }) => {
  const initialAddress = {
    zip: '',
    city: '',
    street: '',
    streetType: '',
    house: '',
    staircase: '',
    floor: '',
    door: '',
  };

  const [loading, setLoading] = useState(true);
  const [cityList, setCityList] = useState([]);
  const [direction, setDirection] = useState(0);
  const [userData, setUserData] = useState({
    name: '',
    tax: '',
  });
  const [employerData, setEmployerData] = useState({
    name: '',
    tax: '',
    // 2022-04-27 pjung TNRUKAYCM-1389 Céges számla tiltása
    nocszla: false,
  });
  const [data, setData] = useState({
    name: '',
    tax: '',
  });
  const [userAddress, setUserAddress] = useState(initialAddress);
  const [employerAddress, setEmployerAddress] = useState(initialAddress);
  const [address, setAddress] = useState(initialAddress);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    setErrors,
    removeErrors,
    resetErrors,
    setFields,
    didFillEverything,
    giveErrors,
    hasErrors,
    errorList,
  } = useValidator();

  useEffect(() => {
    if (direction === 1) {
      setFields([
        'name',
        'tax',
        'zip',
        'city',
        'street',
        'streetType',
        'house',
      ]);
    } else if (direction === 2) {
      setFields(['name', 'zip', 'city', 'street', 'streetType', 'house']);
    } else {
      setFields([]);
    }

    return () => resetErrors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await authService.getInvoiceData();
        if (response?.data?.status === 'OK') {
          setData({
            name: response.data.current.name,
            tax: response.data.current.tax,
          });
          if (!response.data.current.address) {
            setAddress(response.data.partner.address);
            setCityList([response.data.partner.address.city]);
            setDirection(0);
            setData(userData);
          } else {
            setAddress(response.data.current.address);
            setCityList([response.data.current.address.city]);
            if (response.data.current.tax && response.data.current.name) {
              setDirection(1);
            } else if (!response.data.current.tax && response.data.current.name) {
              setDirection(2);
            } else {
              setDirection(0);
            }
          }
          if (response.data.employer) {
            setEmployerData({
              name: response.data.employer.name,
              tax: response.data.employer.tax,
              // 2022-04-27 pjung TNRUKAYCM-1389 Céges számla tiltása
              nocszla: response.data.employer.nocszla,
            });
            setEmployerAddress(response.data.employer.address);
          }
          setUserData({
            name: response.data.partner.name,
            tax: response.data.partner.tax,
          });
          setUserAddress(response.data.partner.address);
          setLoading(false);
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    };
    if (currentUser.kr) {
      fetchData();
    } else {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.kr]);

  const isObject = (objValue) => objValue && typeof objValue === 'object' && objValue.constructor === Object;
  const checkIfObjectIsEmpty = (obj) => isObject(obj) && Object.values(obj).every((item) => item === null || item === '');

  useEffect(() => {
    console.log(employerAddress);
    if (!checkIfObjectIsEmpty(employerAddress) && JSON.stringify(employerAddress) === JSON.stringify(address)) {
      setDirection(3);
      setData(employerData);
    } else if (!checkIfObjectIsEmpty(userAddress) && JSON.stringify(userAddress) === JSON.stringify(address)) {
      setDirection(0);
      setData(userData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerAddress, address]);

  useEffect(() => {
    if (address.city) {
      const request = {
        target: {
          name: 'city',
          value: address.city,
        },
      };
      removeErrors(request);
    } else if (address.zip) {
      giveErrors('city');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.city]);

  const listCitiesByZip = async (zip) => {
    let result = '';
    try {
      const response = await contractService.listCitiesByZip(zip);
      if (response.data.status === 'OK') {
        result = response.data.list.map((city) => city.name);
      } else {
        response.data.messages.map((message) => enqueueSnackbar(t(message), {
          variant: 'error',
        }));
      }
    } catch (e) {
      enqueueSnackbar(t('TECHNICAL_ERROR'), {
        variant: 'error',
      });
    }
    return result;
  };

  const handleOnAddressChange = async (event, addressType) => {
    const { name, value } = event.target;
    const addressCopy = JSON.parse(JSON.stringify(address));
    addressCopy[name] = value;
    if (name === 'zip' && value.length === 4) {
      const cityListCopy = await listCitiesByZip(value);
      addressCopy.city = addressCopy.city
        ? addressCopy.city
        : cityListCopy[0];
      setCityList(cityListCopy);
    } else if (name === 'zip' && value.length < 4) {
      addressCopy.city = '';
      setCityList([]);
    }

    setAddress(addressCopy);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let toCheck = {};
    if (direction === 1) {
      toCheck = {
        ...data,
        ...address,
      };
    } else if (direction === 2) {
      toCheck = {
        name: data.name,
        ...address,
      };
    }
    if (didFillEverything(toCheck).length === 0) {
      try {
        const response = await authService.changeInvoiceData({
          own: direction === 0,
          direction, // 1=cég, szervezet, 2=magánszemély, 3=munkáltatóm, 0=saját
          // partner: { ...data },
          name: data.name,
          taxNumber: data.tax,
          address: { ...address },
        });

        if (response.data.status === 'OK') {
          enqueueSnackbar(t('invoiceChanged'), {
            variant: 'success',
          });
        } else {
          response.data.messages.map((message) => enqueueSnackbar(t(message), {
            variant: 'error',
          }));
        }
      } catch (e) {
        enqueueSnackbar(t('TECHNICAL_ERROR'), {
          variant: 'error',
        });
      }
    } else {
      const errors = didFillEverything(toCheck);
      const key = enqueueSnackbar(t('validate_required'), {
        variant: 'error',
      });
      errors.map((field) => giveErrors(field, key));
    }
  };

  const handleCheckbox = (event) => {
    if (event.target.checked) {
      setDirection(0);
      setData(userData);
      setAddress(userAddress);
    } else if (checkIfObjectIsEmpty(employerAddress)) {
      setDirection(3);
      setData(employerData);
      setAddress(employerAddress);
    } else {
      setDirection(1);
      setData({ name: '', tax: '' });
      setAddress(initialAddress);
    }
  };

  const handleOnInvoiceChange = (event) => {
    const { name, value } = event.target;
    // A számlázási irány módosításakor a cím adatok üresre állítódnak.
    if (name === 'direction') {
      setDirection(value);
      if (value === 3) {
        setData(employerData);
        setCityList([employerAddress.city]);
        setAddress(employerAddress);
      } else {
        setData({
          name: '',
          tax: '',
        });
        setAddress(initialAddress);
      }
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleBlur = (e, rules) => {
    if (e.target.name === 'tax') {
      const result = checkTaxNumber(e.target.value);
      if (!result) {
        giveErrors('tax');
      }
    }

    setErrors(e, rules);
  };

  const handleFocus = (e) => {
    removeErrors(e);
  };

  return loading ? (
    <LoaderIcon />
  ) : (
    <>
      {currentUser.kr
        ? (
          <form onSubmit={handleSubmit} onError={(errors) => console.error(errors)}>
            <Typography component="section">
              <Grid
                container
                component="article"
                style={{ padding: '20px 32px 0px 32px' }}
              >
                <Grid item xs={12} md={5}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={direction === 0}
                        onChange={handleCheckbox}
                    // value={ownAddress}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                )}
                    label={t('invoiceCB')}
                    style={{ marginBottom: '16px' }}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box ms="auto" display={direction !== 0 ? 'block' : 'none'}>
                    <InvoiceDir
                      employer={!isBlank(employerAddress?.zip)}
                      company={!employerData?.nocszla}
                      fullWidth
                      direction={direction}
                      handleOnInvoiceChange={handleOnInvoiceChange}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box ms="auto" display={direction > 0 ? 'block' : 'none'}>
                    <InputWrapper
                      inputStyle={hasErrors('name') ? 'Error' : 'Main'}
                      label={t('invoiceName')}
                    >
                      <TextField
                        fullWidth
                        disabled={direction === 3}
                        id="name"
                        name="name"
                        variant="outlined"
                        margin="dense"
                        value={data.name}
                        onChange={handleOnInvoiceChange}
                        onBlur={(e) => handleBlur(e, ['required'])}
                        onFocus={handleFocus}
                      />
                    </InputWrapper>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    ms="auto"
                    display={direction === 1 || direction === 3 ? 'block' : 'none'}
                  >
                    <InputWrapper
                      inputStyle={hasErrors('tax') ? 'Error' : 'Main'}
                      label={t('taxNumber')}
                    >
                      <InputMask
                        mask="99999999-9-99"
                        disabled={direction === 3}
                        maskChar={null}
                        value={data.tax}
                        onChange={handleOnInvoiceChange}
                        name="tax"
                      >
                        {() => (
                          <TextField
                            fullWidth
                            disabled={direction === 3}
                            id="tax"
                            variant="outlined"
                            margin="dense"
                            name="tax"
                            type="text"
                            validators={
                          direction === 1 || direction === 3
                            ? ['required', 'isTaxNumValid']
                            : []
                        }
                          />
                        )}
                      </InputMask>
                    </InputWrapper>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box ms="auto" display={direction > 0 ? 'block' : 'none'}>
                    <Address
                      disabled={direction === 3}
                      required={direction === 1 || direction === 2}
                      addressType="invoice"
                      address={address}
                      handleOnAddressChange={handleOnAddressChange}
                      namePrefix=""
                      cityList={cityList}
                      errorList={errorList}
                      handleBlur={handleBlur}
                      handleFocus={handleFocus}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Typography>
            <Grid
              container
              justify="flex-end"
              style={{ marginTop: '16px', paddingRight: '32px' }}
            >
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                style={{ marginLeft: '10px' }}
                disabled={direction === 3 && checkIfObjectIsEmpty(employerAddress)}
              >
                {t('saveButton')}
              </Button>
            </Grid>
          </form>
        )
        : (
          <Grid container justify="flex-start" alignItems="center" className={classes.warningField} style={{ padding: '20px 32px 0px 32px' }}>
            <Grid item xs={12} sm="auto" style={{ textAlign: 'center' }}>
              <img src={Warning} style={{ width: '75px ', height: 'auto' }} alt="" />
            </Grid>
            <Grid item xs={12} sm className="warningText">
              <p style={{ marginTop: '0', marginBottom: '8px' }}><strong>{t('NO_KR')}</strong></p>
            </Grid>
          </Grid>
        )}
    </>
  );
};

InvoicingData.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  classes: PropTypes.shape(),
};

InvoicingData.defaultProps = {
  classes: {},
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});


export default withStyles(styles)(connect(mapStateToProps)(InvoicingData));
