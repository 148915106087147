import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Grid } from '@material-ui/core';

const ReCaptchaComponent = ({ captchaValidate }) => {

  const lang = localStorage.getItem('i18nextLng');
  return (
    <Grid container justify='center'>
      <Grid>
        <ReCAPTCHA
          sitekey='6LewvroUAAAAAM1WOtXISQH7Er0nflwOFySHch9E'
          onChange={captchaValidate}
          style={{margin: '0 auto'}}
          hl={lang ? lang : 'hu'}
        />
      </Grid>
    </Grid>
  );
}
 
export default ReCaptchaComponent;