import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { MenuItem, Link, Box } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import { styles } from '../theme/styles';
import InputWrapper from '../components/ui/InputWrapper';
import { formatDateHu, priceMask } from '../utils/Utils';
import { paymentService } from '../services/payment';
import { authService } from '../services/authentication';
import { API_URL } from '../utils/Consts';
import ErrorDialog from '../components/ui/ErrorDialog';
import LoaderIcon from '../components/ui/LoaderIcon';
import Warning from '../assets/images/figyelmezteto_tabla_zold.png'


class InvoicesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kr: authService.currentUserKR(),
      currentList: [],
      loadingCurrent: true,
      previousList: [],
      loadingPrevious: true,
      currentYear: new Date().getFullYear(),
      year: new Date().getFullYear() - 1,
      dialogOpen: false,
      dialogContent: '',
      docId: '',
      icon: 'loading',
      popupDisabled: true
    };
  }

  getList = async (year) => {
    this.setState({loadingPrevious: true})
    try {
      const response = await paymentService.listInvoices({kr: this.state.kr, from: `${year}-01-01`, to: `${year}-12-31` })

      if(response.data.status === 'OK'){
        const { list } = response.data;
        list.reverse()
        this.setState({
          previousList: list,
          loadingPrevious: false
        })
      }
    } catch (error) {
      this.setState({loadingPrevious: false})
    }
  }

  async componentDidMount() {
    const currentYear = this.state.currentYear.toString();
    const pastYear = this.state.currentYear.toString() - 1;
    this.setState({loadingCurrent: true})
    try {
      const response = await paymentService.listInvoices({kr: this.state.kr, from: `${currentYear}-01-01`, to: `${currentYear}-12-31` })

      if(response.data.status === 'OK'){
        const { list } = response.data;
        list.reverse()
        this.setState({
          currentList: list,
          loadingCurrent: false
        })
      }
    } catch (error) {
      this.setState({loadingCurrent: false})
    }

    this.getList(pastYear);
  }
  handleOnChange = event => {
    if((this.state.currentYear - event.target.value) < 4){
      this.getList(event.target.value)
    }
    this.setState({[event.target.name]: event.target.value})
  };

  closeErrorDialog = () => {
    this.setState({
      dialogOpen: false,
      dialogContent: ''
    });
  };

  toggleDialog = (docNum) => {
    if(this.state.dialogOpen) {
      this.setState({
        dialogOpen: false
      })
    }
    const fetchData = async () => {
      try {
        const response = await paymentService.getDownloadInvoice({invoice: docNum})
        if(response.data.status === 'OK') {
          this.setState({
            dialogContent: this.printDownloadDialogContent(response.data.docId)
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    this.setState({
      dialogOpen: true,
      dialogContent: this.printLoadingDialogContent()
    })

    if(!this.state.docId) {
      fetchData();
    }
  }

  printDownloadDialogContent = (docId) => {
    this.setState({
      icon: 'success',
      popupDisabled: false
    })
    const { t } = this.props;
    return (
      <Grid container style={{height: '100%'}}>
        <Grid item xs={12}>
          <p style={{ textAlign: 'left', fontSize: '14px', fontWeight: '700' }}>{t('docsReady')}</p>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right', marginTop: '16px' }}>
          <Link href={`${API_URL}/common-services/download?fileId=${docId}`} underline='none'>
            <Button variant='outlined' color='primary' onClick={() => this.setState({dialogOpen: false})}>{t('download')}</Button>
          </Link>
          <Grid item xs={12}>
              <Box className={this.props.classes.popupBottom}></Box>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  printLoadingDialogContent = () => {
    this.setState({
      icon: 'loading',
      popupDisabled: true
    })
    const { t } = this.props;
    return (
      <Grid container style={{height: '100%'}}>
        <Grid item xs={12}>
          <p style={{ textAlign: 'left', fontSize: '14px', fontWeight: '700' }}>{t('docsLoading')}</p>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right', marginTop: '16px' }}>
          <Grid item xs={12}>
              <Box className={this.props.classes.popupBottom}></Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  selectItems = () => {
    const current  = new Date().getFullYear()

    const items = []
    for(let i = current - 1; i >= 2011; i--) {
      items.push(i);
    }

    return items.map(item => <MenuItem value={item} key={item} style={{width: '140px'}}>{item}</MenuItem>)
  }

  render() {
    const { t, classes } = this.props;
    return (
      <>
        <ErrorDialog
          showError={this.state.dialogOpen}
          content={this.state.dialogContent}
          handleClose={this.closeErrorDialog}
          icon={this.state.icon}
          disabled={this.state.popupDisabled}
          title=''
        />
        <Typography component='section'>
          <Grid
            container
            justify='center'
            style={{ padding: '16px 32px 16px 32px' }}
          >
            <Grid item xs={12}>
              <h2 style={{ fontSize: '14px' }}>
                {t('label.invoicesCurrentYear')}
              </h2>
            </Grid>

            {this.state.loadingCurrent ? <LoaderIcon /> : this.state.currentList.map(row => (
              <Grid item xs={12} key={row.order}>
                <Box className={classes.downloadWrapper} style={{border: '2px solid rgba(49, 139, 129, 1)'}}>
                  <Grid container justify='space-between'>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                      <p className={classes.downloadWrapperText}>
                        {formatDateHu(row.date)}
                      </p>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                      <p className={classes.downloadWrapperText} style={{color: 'black'}}>{row.ordinal} - {priceMask(row.fullAmount)} {t('msg.forint')}</p>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
                      <Button style={{ color: 'rgba(49, 139, 129, 1)' }} onClick={() => this.toggleDialog(row.ordinal)}>
                        {t('download')}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid container style={{ marginTop: '24px', padding: '16px 32px' }} justify='center'>
            <Grid item xs={8} style={{ paddingTop: '20px' }}>
              <h2 style={{ fontSize: '14px' }}>{t('label.pastInvoices')}</h2>
            </Grid>
            <Grid item xs={4} style={{ paddingTop: '20px' }}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <p
                    style={{
                      margin: '12px 0',
                      fontSize: '14px',
                      color: 'rgba(161,161,161,1)',
                      fontWeight: '700'
                    }}
                  >
                    {t('chooseYear')}
                  </p>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputWrapper inputStyle='SelectYear'>
                    <Select
                      id='year'
                      name='year'
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: '200px'
                          },
                        }
                      }}
                      variant={'outlined'}
                      margin='dense'
                      value={this.state.year}
                      onChange={this.handleOnChange}
                      IconComponent='none'
                      style={{textAlign: 'center'}}
                      SelectDisplayProps={{
                        style: {
                          width: '140px',
                          paddingRight: '0'
                        }
                      }}
                    >
                      {this.selectItems()}
                    </Select>
                  </InputWrapper>
                </Grid>
              </Grid>
            </Grid>
            {this.state.loadingPrevious
              ? <LoaderIcon />
              : ((this.state.currentYear - this.state.year) < 4)
                ? this.state.previousList.map(row => (
                    <Grid item xs={12} key={row.order}>
                      <Box className={classes.downloadWrapper} style={{border: '2px solid rgba(49, 139, 129, 1)'}}>
                        <Grid container justify='space-between'>
                          <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <p className={classes.downloadWrapperText}>
                              {formatDateHu(row.date)}
                            </p>
                          </Grid>
                          <Grid item xs={8} sm={4}>
                            <p className={classes.downloadWrapperText} style={{color: 'black'}}>{row.ordinal} - {priceMask(row.fullAmount)} {t('msg.forint')}</p>
                          </Grid>
                          <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
                            <Button style={{ color: 'rgba(49, 139, 129, 1)' }} onClick={() => this.toggleDialog(row.ordinal)}>
                              {t('download')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>))
                : <Grid item xs={12}>
                    <Grid container justify='flex-start' alignItems='center' className={classes.warningField}>
                      <Grid item xs={12} sm='auto' style={{ textAlign: 'center' }}>
                        <img src={Warning} style={{ width: '75px ', height: 'auto' }} alt="" />
                      </Grid>
                      <Grid item xs={12} sm={true} className='warningText'>
                        <p style={{ marginTop: '0', marginBottom: '8px' }}><strong>{t('oldInvoice')}</strong></p>
                      </Grid>
                    </Grid>
                  </Grid>
            }
          </Grid>
        </Typography>
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(InvoicesPage));
