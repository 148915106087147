import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import getMonth from 'date-fns/getMonth';

import { MONTHS } from '../../utils/Consts';
import InputWrapper from './InputWrapper';

/**
 * Két hónap a kötelezően fizetendő, decemberben 1 hónap.
 * FIXME Ez nem jó, ha értéket kap
 */
const PaymentMonths = props => {
  const { t } = useTranslation();

  function getItems(validFrom) {
    const actualMonth = getMonth(new Date(validFrom));
    // 2020-12-15 pjung TNRUKAYCM-952 11=12 eltávolítása...
    // const maxNum = 12 - actualMonth > 11 ? 11 : 12 - actualMonth;
    const maxNum = 12 - actualMonth;

    let items = [];
    // 2020-12-15 pjung Eddig max. 11 lehetett, már 12 is...
    if (actualMonth >= 11) {
      items.push(
        <MenuItem value={1} key={1}>
          1 {t('month')}, {t('dec')}
        </MenuItem>
      );
    } else {
      var num;
      for (num = 2; num < maxNum + 1; num++) {
        var i;
        var item = `${num} ${t('month')}`;

        for (i = actualMonth; i < actualMonth + num; i++) {
          item += ', ';
          item += t(MONTHS[i]);
        }
        items.push(
          <MenuItem value={num} key={i}>
            {item}
          </MenuItem>
        );
      }
    }
    return items;
  }

  return (
    <InputWrapper inputStyle='Main' label={t('label.paymentMonths')}>
      <Select
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        variant='outlined'
        margin='dense'
      >
        {getItems(props.validFrom)}
      </Select>
    </InputWrapper>
  );
};
export default PaymentMonths;
